import axios from './BaseService'

const WITHDRAWALS_URL = `${process.env.REACT_APP_API_URL}/withdraws/`

export async function getWithdrawals(token, page, limit, search, paymentMethod, status, ordenation) {
  const endPoint = `${WITHDRAWALS_URL}list${page ? `?page=${page}` : ''}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { limit, search, paymentMethod, status, ordenation }, { headers })
  return response.data
}

export async function getWithdrawalsFiles(token, page, limit) {
  const endPoint = `${WITHDRAWALS_URL}files${page ? `?page=${page}` : ''}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { limit }, { headers })
  return response.data
}

export async function payWithdraw(token, withdrawals, coin) {
  const endPoint = `${WITHDRAWALS_URL}payWithdraw`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { withdrawals, coin }, { headers })
  return response.data
}

export async function generateFileWithdraw(token, withdrawals, coin) {
  const endPoint = `${WITHDRAWALS_URL}generatefilewithdraw`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { withdrawals, coin }, { headers })
  return response.data
}

export async function doChargeback(token, id) {
  const endPoint = `${WITHDRAWALS_URL}chargeback/${id}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.get(endPoint, { headers })
  return response.data
}
