/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { doChargeback, generateFileWithdraw, getWithdrawals, payWithdraw } from '../../services/WithdrawalsService'
import { i18n } from '../../utils/translates/i18n'
import WithdrawRow from '../Withdrawals/WithdrawRow'
import WithdrawModal from '../Withdrawals/WithdrawModal/WithdrawModal.js'
import ChargebackModal from './WithdrawModal/ChargebackModal'
import { FormateFiatCurrency } from '../../utils/CustomFormatations'
import LoaderPage from '../../utils/LoaderPage'
// import { toast } from 'react-toastify'

function Withdrawals() {
  const defaultLocation = useLocation()
  const history = useHistory()

  const [withdrawals, setWithdrawals] = useState([])
  const [editWithdraw, setEditWithdraw] = useState([])
  const [chargeBack, setChargeBack] = useState(null)
  const [totalSelected, setTotalSelected] = useState(0)
  const [show, setShow] = useState(false)

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('usdt')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)
  const [status, setStatus] = useState('')
  const [ordenation, setOrdenation] = useState('asc')

  const [alertMsg, setAlertMsg] = useState('')

  const doGetWithdrawals = async () => {
    const success = await getWithdrawals(token, page, lim, search, paymentMethod, status, ordenation)
    if (success.status) {
      setWithdrawals(success.list.rows)
      setCount(success.list.count)
      setEditWithdraw([])
      setTotalSelected(0)
    }
    setShow(true)
  }

  useEffect(() => {
    doGetWithdrawals()
  }, [token, page, lim, search, paymentMethod, status, ordenation])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const chooseWithdraw = (id, amount) => {
    if (!id) return
    if (editWithdraw.includes(id)) {
      setEditWithdraw(p => p.filter(f => f !== id))
      setTotalSelected(t => t - amount)
    } else {
      setEditWithdraw(p => [...p, id])
      setTotalSelected(t => t + amount)
    }
  }

  const confirmeChargeback = async () => {
    setShow(false)
    const success = await doChargeback(token, chargeBack)
    if (success.status) {
      setEditWithdraw([])
      setTotalSelected(0)
      doGetWithdrawals()
      // toast.success(i18n.t('blind_man'))
      setAlertMsg({ type: 'success', msg: i18n.t('blind_man') })
      setShow(true)
    } else {
      // toast.error(success.error)
      setAlertMsg({ type: 'danger', msg: i18n.t('success.error') })
      setShow(true)
    }
  }

  const generateFile = async () => {
    const success = await generateFileWithdraw(token, editWithdraw, paymentMethod)
    if (success.status) {
      window.open(`${process.env.REACT_APP_API_URL}/withdrawals_files/${success.fileName}`, '_blank')
      setEditWithdraw([])
      setTotalSelected(0)
      doGetWithdrawals()
      // toast.success(i18n.t('withdrawals_file_created'))
      setAlertMsg({ type: 'success', msg: i18n.t('withdrawals_file_created') })
      setShow(true)
    } else {
      // toast.error(success.error)
      setAlertMsg({ type: 'danger', msg: success.error })
      setShow(true)
    }
  }

  const doPayWithdraw = async () => {
    setShow(false)
    const success = await payWithdraw(token, editWithdraw, paymentMethod)
    if (success.status) {
      // toast.success(i18n.t('withdrawals_sended'))
      setAlertMsg({ type: 'success', msg: i18n.t('withdrawals_sended') })
      setEditWithdraw([])
      setTotalSelected(0)
      doGetWithdrawals()
      setShow(true)
    } else {
      // toast.error(success.error)
      setAlertMsg({ type: 'danger', msg: success.error })
      setShow(true)
    }
  }

  const t = sessionStorage.getItem('t') === 'true'
  return (
    !t && (
      <React.Fragment>
        <Menu />
        <main className="content">
          {!show ? (
            <LoaderPage />
          ) : (
            <>
              <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <h2 className="h4">{i18n.t('withdrawals')}</h2>
                  </div>
                </div>
                <Pagination count={count} pageSize={lim}>
                  <div className="align-items-center me-2">
                    <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                      <i className="fas fa-search"></i>
                    </div>
                  </div>
                  <div className="input-group me-2">
                    <input
                      className="form-control"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      placeholder={`${i18n.t('search')} ${i18n.t('by')} ${i18n.t('nick')}`}
                    />
                  </div>
                  <div className="input-group me-2">
                    <select
                      className="form-select"
                      value={paymentMethod}
                      onChange={e => setPaymentMethod(e.target.value)}>
                      {/* <option value="ltct">LTCT - Litecoin testnet</option> */}
                      {/* <option value="btc">BTC - Bitcoin</option> */}
                      <option value="usdt">USDT - Tether USD (Tron/TRC20)</option>
                    </select>
                  </div>
                  <div className="input-group me-2">
                    <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                      <option value="">{i18n.t('status')}</option>
                      <option value="1">{i18n.t('waiting')}</option>
                      <option value="2">{i18n.t('partial')}</option>
                      <option value="3">{i18n.t('paid')}</option>
                      <option value="4">{i18n.t('confirmed')}</option>
                      <option value="6">{i18n.t('canceled')}</option>
                      <option value="7">{i18n.t('blind_man')}</option>
                      {/* <option value="7">{i18n.t('blind_man')}</option>
									<option value="9">{i18n.t('off')}</option>
									<option value="11">{i18n.t('expired')}</option> */}
                    </select>
                  </div>
                  <select className="form-select me-2" value={ordenation} onChange={e => setOrdenation(e.target.value)}>
                    <option className="bg-light" value="asc">
                      {i18n.t('olds')}
                    </option>
                    <option className="bg-light" value="desc">
                      {i18n.t('recents')}
                    </option>
                  </select>
                  <a
                    href="/withdrawalsfiles"
                    className="btn btn-primary me-2 w-50 flex justify-content-center align-items-center">
                    <i className="fa fa-eye me-2"></i>
                    <span>{i18n.t('files')}</span>
                  </a>
                  <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
                    <option className="bg-light" value="5">
                      5 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="10">
                      10 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="25">
                      25 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="50">
                      50 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="100">
                      100 {i18n.t('registers')}
                    </option>
                  </select>
                </Pagination>
                {editWithdraw.length ? (
                  <>
                    <div className="alert alert-primary row mx-2 py-1 align-items-center">
                      <div className="col">
                        <strong>{i18n.t('quantity')}: </strong>
                        {editWithdraw.length}
                      </div>
                      <div className="col">
                        <strong>{i18n.t('total')}: </strong>
                        {FormateFiatCurrency(totalSelected)}
                      </div>
                      <div className="col">
                        <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalWithdraw">
                          <i className="fa fa-dollar-sign me-2"></i> {i18n.t('pay_selected')}
                        </button>
                      </div>
                      <div className="col">
                        <button className="btn btn-warning" onClick={generateFile}>
                          <i className="fa fa-file me-2"></i> {i18n.t('generate_file')}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {alertMsg && alertMsg.msg && <div className={`alert alert-${alertMsg.type} fs-3`}>{alertMsg.msg}</div>}
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                  {/* <pre>{JSON.stringify(editWithdraw)}</pre> */}
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="border-gray-200">{i18n.t('choose')}</th>
                        <th className="border-gray-200">{i18n.t('date')}</th>
                        <th className="border-gray-200">{i18n.t('user')}</th>
                        <th className="border-gray-200">{i18n.t('amount')}</th>
                        <th className="border-gray-200">{i18n.t('coin')}</th>
                        <th className="border-gray-200">{i18n.t('wallet')}</th>
                        <th className="border-gray-200">{i18n.t('extorno_td')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawals?.map((w, i) => (
                        <WithdrawRow
                          w={w}
                          key={i}
                          chooseWithdraw={chooseWithdraw}
                          editWithdraw={editWithdraw}
                          chargeback={setChargeBack}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </main>
        <WithdrawModal withdraw={editWithdraw} totalSelected={totalSelected} payWithdraw={doPayWithdraw} />
        <ChargebackModal
          chargeBack={chargeBack}
          confirmeChargeback={confirmeChargeback}
          cancelChargeback={() => setChargeBack(null)}
        />
      </React.Fragment>
    )
  )
}

export default Withdrawals
