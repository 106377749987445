const messages = {
  la: {
    translations: {
      a_crase: 'ال',
      about: 'معلومات عنا',
      above_the_same: 'بالضبط نفس الشيء أعلاه',
      action: 'فعل',
      actionTemplates: 'نماذج العمل',
      activate_date: 'تفعيل التسجيل',
      active_automations: 'الأتمتة النشطة',
      active_connections: 'اتصالات نشطة',
      active_monitors: 'الشاشات النشطة',
      active_users: 'الجهات المانحة النشطة',
      ad: 'إعلان',
      add: 'الانضمام',
      add_option: 'إضافة خيار',
      add_payment: 'إضافة الدفع',
      add_question: 'أضف سؤال',
      add_receipt: 'إضافة إيصال',
      add_wallet: 'أضف المحفظة',
      address: 'عنوان',
      admin: 'إدارة',
      advance: 'يتقدم',
      affected: 'وصل',
      after: 'بعد',
      air_transport: 'النقل الجوي',
      ajust_limite: 'تعديل الحد',
      ajust_saldo: 'تعديل الرصيد',
      alert: 'يُحذًِر',
      alerts: 'التنبيهات',
      all: 'الجميع',
      all_departments: 'جميع الإدارات',
      allies_of: 'حلفاء',
      allRightsReserved: 'كل الحقوق محفوظة.',
      almost_there: 'اوشكت على الوصول',
      already_offset: 'لقد عوضت بالفعل',
      already_registered: 'مسجل بالفعل؟ انقر هنا وأدخل',
      amount: 'قيمة',
      amount_above_balance: 'رصيد غير كاف',
      and: 'إنها',
      and_more: 'و اكثر',
      answer: 'إجابة',
      ao: 'إلى',
      approve: 'ليوافق',
      April: 'أبريل',
      asc: 'تصاعدي',
      at: 'في',
      August: 'أغسطس',
      automations: 'الأتمتة',
      awaiting_email_confirmation: 'في انتظار تأكيد البريد الإلكتروني!',
      b_order: 'طلب',
      b_orders: 'طلبات',
      back: 'للعودة',
      bairroInteresse: 'حيّ',
      balance: 'توازن',
      balance_available: 'رصيد التبرع',
      banned: 'محظور',
      before_contact: 'قبل الاتصال بنا',
      binary: 'الثنائية',
      birth: 'ولادة',
      blind_man: 'عكس',
      body_monthly:
        'لقد انتهت صلاحية الرسوم الشهرية وتم إلغاء تنشيط BOT TRADER الخاص بك، يرجى دفع أمر الدفع والاستمتاع بمزاياك مرة أخرى',
      bonus_saldo_trader: 'مكافأة رصيد التاجر',
      book_health: 'بوك هيلث',
      but_possible_register: 'لا يزال بإمكانك التسجيل، ولكن لن يتم ربطك بأي صديق محال.',
      buy_volume: 'حجم الشراء',
      buyer: 'مشتر',
      by: 'لكل',
      bye: 'أراك لاحقًا',
      call: 'مُسَمًّى',
      call_to: 'دعوة ل',
      calls: 'المكالمات',
      cancel: 'يلغي',
      canceled: 'ألغيت',
      career_plan: 'المسار المهني',
      casa_verde_amarela: 'البيت الأخضر الأصفر',
      cash_payment: 'دفع نقدا',
      cash_payment_return: 'عودة الدفع النقدي',
      cash_receipt: 'إيصال الدفع',
      change_indicant: 'تغيير المؤشر',
      change_photo: 'غير الصوره',
      change_plan: 'خطة التغيير',
      check_payment: 'الدفع بالشيك',
      check_payment_return: 'إعادة الدفع عن طريق الشيك',
      check_receipt: 'الاستلام بالشيك',
      choose: 'لإختيار',
      cidadeInteresse: 'مدينة',
      city: 'مدينة',
      claims: 'المطالبات',
      clean: 'لينظف',
      click_and_know: 'انقر واكتشف',
      click_to_copy_the_wallet: 'انقر لنسخ المحفظة',
      client: 'عميل',
      clients: 'عملاء',
      close: 'إنهاء',
      cnh: 'CNH (ترخيص)',
      cnhDate: 'تاريخ إصدار CNH',
      cnpj: 'CNPJ',
      cnpj_invalid: 'CNPJ غير صالح',
      coin: 'عملة',
      color: 'لون',
      commercial: 'تجاري',
      company: 'اسم الشركة',
      complement: 'إطراء',
      comporRenda: 'هل ستجني المال مع شخص ما؟',
      compRenda: 'نوع إثبات الدخل',
      confirm_approve: 'تأكيد الموافقة',
      confirm_delete: 'تأكيد الحذف',
      confirm_new_password: 'تأكيد كلمة المرور الجديدة',
      confirm_new_password_security: 'تأكيد كلمة المرور الأمنية الجديدة',
      confirm_password: 'تأكيد كلمة المرور',
      confirm_password_security: 'تأكيد كلمة المرور الأمنية',
      confirm_your_new_password: 'أدخل كلمة المرور الجديدة مرة أخرى',
      confirmed: 'مؤكد',
      confirmed_email: 'البريد الإلكتروني المؤكد',
      congratulations: 'تهانينا',
      contact: 'اتصال',
      contract: 'عقد',
      copied: 'نسخ',
      copy: 'ينسخ',
      copy_success: 'تم النسخ بنجاح!',
      correspondences: 'مراسلة',
      countries: 'بلدان',
      country: 'دولة',
      cpf: 'CPF',
      cpf_invalid: 'رقم CPF غير صالح',
      create_order_success: 'تم إنشاء التبرع بنجاح!',
      created: 'مسجل في',
      cred: 'ائتمان',
      cred_binario: 'الائتمان الثنائي',
      cred_deb: 'الاعتمادات/الخصومات',
      cred_game_donate: 'لعبة التبرع بالائتمان',
      cred_ind_direta: 'خطوة الائتمان',
      cred_ind_indireta: 'خطوة الائتمان',
      cred_pagar_com_saldo: 'التبرع الائتمان مع الرصيد',
      cred_pontos: 'نقاط الائتمان',
      cred_recb_trader: 'الائتمان تلقي التاجر',
      cred_renovacao: 'تجديد الائتمان',
      cred_saldo_disp: 'رصيد رصيد التبرع',
      cred_upgrade: 'ترقية الائتمان',
      credit_card_payment: 'الدفع عن طريق بطاقة الائتمان',
      credit_card_payment_return: 'إعادة الدفع عن طريق بطاقة الائتمان',
      credit_card_receipt: 'إيصال بطاقة الائتمان',
      current: 'حاضِر',
      current_password: 'كلمة السر الحالية',
      current_wallet: 'المحفظة الحالية',
      dashboard: 'لوحة التحكم',
      data_added: 'البيانات المضافة',
      data_saved: 'البيانات المحفوظة',
      datas: 'بيانات',
      date: 'تاريخ',
      date_first_installment: 'تاريخ الدفعة الأولى',
      ddi: 'DDI',
      deb_de_trader: 'ديون التاجر',
      deb_game_donate: 'لعبة التبرع بالخصم',
      deb_saldo: 'رصيد مدين',
      deb_saldo_disp: 'الخصم من رصيد التبرع',
      deb_saldo_pendent: 'الرصيد المدين المستحق',
      deb_val_indev: 'خصم المبلغ غير المستحق',
      debit_card_payment: 'الدفع عن طريق بطاقة الخصم',
      debit_card_payment_return: 'إعادة الدفع عن طريق بطاقة الخصم',
      debit_card_receipt: 'الاستلام عن طريق بطاقة الخصم',
      debt: 'دَين',
      debt_td: 'دَين',
      December: 'ديسمبر',
      decImpRenda: 'أعلن ضريبة الدخل',
      deleted_document: 'تم حذف المستند من السجل الخاص بك',
      department: 'قسم',
      dependents: 'المعالين',
      desc: 'إلى الأسفل',
      description: 'وصف',
      details: 'تفاصيل',
      dev_econom: 'النمو الإقتصادي',
      developer: 'مطور',
      directly_indicated: 'وأشار مباشرة',
      directly_indicateds: 'تمت الإشارة إليه مباشرة',
      district: 'الحي / المنطقة',
      divorced: 'مُطلّق',
      doa_men_receb: 'تم استلام التبرع الشهري للمنصة',
      doacao_td: 'هبة',
      doctorate_complete: 'دكتوراه - كاملة',
      doctorate_incomplete: 'دكتوراه - غير مكتملة',
      document: 'وثيقة',
      document_already_registered: 'الوثيقة مسجلة بالفعل',
      documents: 'وثائق',
      donate: 'يتبرع',
      donor: 'جهات مانحة',
      dont_know_zip: 'لا تعرف الرمز البريدي؟ انقر هنا',
      dou_fe: 'أؤكد أن المعلومات الواردة أعلاه صحيحة.',
      downloads: 'تحميل ملفات',
      drop_images: 'انقر أو اسحب الصور هنا',
      due_date: 'نضج',
      edit: 'للتعديل',
      edit_option: 'خيار التحرير',
      edit_question: 'تحرير السؤال',
      email: 'بريد إلكتروني',
      email_already_registered: 'البريد الإلكتروني مسجل مسبقا',
      email_and_or_password_wrong: 'البريد الإلكتروني و/أو كلمة المرور خاطئة!',
      email_for_login: 'سيكون هذا البريد الإلكتروني بمثابة تسجيل الدخول الخاص بك',
      email_or_password_wrong: 'بريد أو كلمة مرورغير صحيحة',
      employee: 'موظف',
      employees: 'موظفين',
      empty: 'فارغ',
      enter_your_new_password: 'أدخل كلمة المرور الجديدة',
      entrada: 'هل لها قيمة دخول؟ إذا كانت الإجابة بنعم، ما هي القيمة',
      entries_until_today: 'الإدخالات حتى الآن',
      error_confirm_password: 'يجب أن يكون التأكيد وكلمة المرور الجديدة متماثلين',
      error_email: 'تنسيق البريد الإلكتروني غير صالح',
      error_password: 'كلمة سر خاطئة',
      error_phone: 'رقم الهاتف غير صحيح',
      estadoInteresse: 'ولاية',
      estimate: 'مُقدَّر',
      exchange: 'إستبدال',
      expired: 'منتهي الصلاحية',
      extorno_td: 'انعكاس، ارتداد، انقلاب',
      extra: 'إضافي',
      extract: 'حركة',
      extracts: 'الحركات',
      fantasy: 'خيالي',
      fatherName: 'اسم الاب',
      February: 'شهر فبراير',
      fee: 'رسوم الانسحاب',
      feedback: 'يعود',
      feminine: 'المؤنث',
      fgts: 'قيمة FGTS الخاصة بك',
      field_required: 'الانتهاء الإلزامي',
      final: 'أخير',
      finance: 'مالي',
      financial: 'مالي',
      financial_payment: 'الدفع المالي',
      financial_payment_return: 'عودة الدفع المالي',
      financial_receipt: 'استلام التمويل',
      financing: 'التمويل',
      financings: 'التمويل',
      first_10_pages: 'أول 10 صفحات',
      fisica: 'شخص مادي',
      for_year: 'بالسنة',
      forget_password: 'لقد نسيت كلمة المرور',
      forgot_password: 'هل نسيت كلمة السر',
      form: 'استمارة',
      form_of_payment: 'طريقة الدفع',
      found: 'وجد',
      founds: 'وجد',
      free: 'حر',
      friend_indicated: 'أوصت به أصدقائي',
      friend_indicateds: 'أوصت به أصدقائي',
      fuel: 'وقود',
      full_name: 'الاسم الكامل',
      funcPublico: 'هو موظف عام',
      fundamental_complete: 'أساسي - كامل',
      fundamental_incomplete: 'الأساسية - غير مكتملة',
      ganh_diario: 'الأرباح اليومية',
      gender: 'جنس',

      grand_total: 'المجموع الإجمالي',
      group: 'مجموعة',
      has_withdraw_today: 'وقد تم بالفعل طلب الانسحاب اليوم',
      high: 'عالي',
      home: 'يبدأ',
      how_create_binance_apikey: 'كيفية إنشاء مفتاح API على Binance',
      how_it_works: 'كيف تعمل',
      how_to_call: 'ماذا تريد أن تسمى؟',
      id_td: 'خطوة الائتمان',
      images: 'الصور',
      in_construction: 'تحت التشيد',
      in_order: 'مرتب',
      in_stock: 'في الأوراق المالية',
      indicant: 'مؤشر',
      indicated_by: 'تتم إحالتك من قبل',
      indicated_not_found: 'لم يتم العثور على المرجع المشار إليه...',
      indicateds: 'أصدقاء',
      indicated: 'صديق',
      indirects: 'أصدقاء',
      info: 'النشرات الإخبارية',
      info_contact: 'معلومات الاتصال',
      initial: 'بيت',
      insert_new: 'أدخل جديد',
      installments: 'أقساط',
      intelligence: 'ذكاء',
      interesse: 'ما هي المنطقة ذات الاهتمام',
      invalid_contact: 'تنسيق جهة الاتصال غير صالح',
      invalid_images: 'صور غير صالحة',
      invalid_token: 'رمز غير صالح',
      invite: 'لدعوة',
      invite_friends: 'اعزم أصحابك',
      January: 'يناير',
      July: 'يوليو',
      June: 'يونيو',
      juridica: 'شخص قانوني',
      know_exact_result: 'هل تعرف القيمة الدقيقة؟',
      lead: 'معًا سوف نبني مستقبلًا جديدًا.',
      learn_more: 'تعرف أكثر',
      left: 'غادر',
      left_preferential: 'تفضيل الجانب الأيسر',
      legalNature: 'الطبيعة القانونية',
      level: 'مستوى',
      limite_80: 'لقد وصلت إلى {{perc}} من الحد المسموح به.\n قم بالتحسين وتجنب تعليق دخلك.',
      limite_excedido: 'تم تجاوز حد الخصم',
      linear: 'خطي',
      link_email: 'انقر على الرابط المرسل إلى البريد الإلكتروني المسجل!',
      link_invite_copy: 'تم نسخ رابط المشاركة بنجاح',
      liquid: 'سائل',
      list: 'قائمة',
      loading: 'تحميل',
      location: 'موقع',
      locked: 'محظور',
      login: 'للدخول',
      logout: 'للخروج',
      low: 'قليل',
      male: 'مذكر',
      manage_users: 'إدارة الجهات المانحة',
      manager: 'مدير',
      manufacturer: 'الصانع',
      March: 'يمشي',
      maritalStatus: 'الحالة الاجتماعية',
      market: 'سوق',
      married: 'متزوج',
      master_complete: 'درجة الماجستير - كاملة',
      master_incomplete: 'درجة الماجستير - غير مكتملة',
      max_payment: 'الحد الأقصى للدفع',
      May: 'يمكن',
      message: 'رسالة',
      messageNotSent: 'لم يكن من الممكن إرسال رسالتك',
      messageSent: 'تم ارسال رسالتك بنجاح',
      middle_complete: 'متوسطة - كاملة',
      middle_incomplete: 'متوسطة - غير مكتملة',
      min_amount_error: 'يجب أن تكون القيمة على الأقل:',
      min_withdraw: 'الحد الأدنى لمبلغ السحب',
      missing: 'مفتقد',
      mission: 'مهمة',
      mission_vision_values: 'الرسالة والرؤية والقيم',
      model: 'نموذج',
      monitors: 'الشاشات',
      monthly: 'الدفع الشهري',
      motherName: 'اسم الأم',
      movements: 'الحركات',
      msg_renovacao: 'لقد وصلت إلى حد الربح الخاص بك، قم بتجديد باقتك.',
      my_account: 'حسابي',
      my_wallet: 'محفظتى',
      mystock: 'مخزوني',
      name: 'اسم',
      nascMaisVelhoRenda2: 'ما هو تاريخ ميلاد أكبرهم؟',
      nationality: 'الجنسية (بلد الميلاد)',
      naturalness: 'مكان الميلاد (مدينة الميلاد)',
      nature: 'طبيعة',
      network: 'مجتمع',
      new: 'جديد',
      new_call: 'مكالمة جديدة',
      new_financing: 'التمويل الجديد',
      new_order: 'طلب جديد',
      new_password: 'كلمة المرور الجديدة',
      new_password_security: 'كلمة مرور أمنية جديدة',
      new_seller: 'بائع جديد',
      new_wallet: 'محفظة جديدة',
      news: 'أخبار',
      next: 'التالي',
      nick: 'الجهة المانحة (تسجيل الدخول)',
      nis: 'PIS/شيكل',
      nis_already_registered: 'PIS/NIS مسجل بالفعل',
      no: 'لا',
      no_approve: 'لم تتم الموافقة عليه بعد',
      no_delete: 'لا تحذف',
      no_literate: 'غير متعلم',
      no_repeat: 'لا يمكن تكرار البيانات',
      no_results_found: 'لم يتم العثور على نتائج',
      no_services_or_products_added: 'لم تتم إضافة أي خدمات أو منتجات',
      no_settings: 'انتقل إلى الإعدادات واملأ التفاصيل الخاصة بك.',
      none_friends_indicated: 'لم أقم بإحالة أي أصدقاء بعد',
      not_authorized: 'غير مخول',
      not_found: 'غير معثور عليه',
      not_login: 'غير قادر على تسجيل الدخول، يرجى التحقق من التفاصيل الخاصة بك وحاول مرة أخرى!',
      not_orders: 'لا توجد أوامر الدفع',
      not_permission: 'ليس لديك الصلاحية لدخول هذه الصفحة',
      not_registered: 'لم يتم تسجيلة بعد؟ انقر هنا وقم بالتسجيل',
      not_results: 'لا توجد سجلات',
      not_self_register: 'غير قادر على التسجيل',
      not_today_entry: 'مازلنا لم نحصل على التذاكر اليوم',
      notfound_ad: 'لم يتم العثور على إعلانات',
      November: 'شهر نوفمبر',
      number: 'رقم',
      obs: 'تعليقات',
      obs_pay:
        'إذا قام الوسيط الخاص بك بخصم رسوم من المبلغ المرسل، فيجب عليك إجراء التصحيح يدويًا عن طريق إضافة مبالغ هذه الرسوم.',
      October: 'اكتوبر',
      of: 'في',
      off: 'تم محوها',
      on_total: 'من المجموع الكلي',
      open: 'يفتح',
      opening: 'افتتاح',
      operation: 'أداء',
      ordenation: 'الطلب',
      order: 'هبة',
      order_book: 'سجل الطلبيات',
      order_not_alowed_to_pay: 'التبرع غير المصرح به للتبرع بالرصيد',
      order_not_found: 'لم يتم العثور على التبرع',
      order_payed: 'تم دفع التبرع رقم {{id}} بنجاح',
      orders: 'التبرعات',
      other: 'آخر',
      other_services_or_products: 'خدمات أو منتجات أخرى',
      paid: 'دفع',
      paid_date: 'يوم الدفع',
      partial: 'المدفوعة جزئيا',
      passport: 'جواز سفر',
      password: 'كلمة المرور',
      password_security: 'كلمة المرور الأمن',
      pay: 'يتبرع',
      pay_exact: 'ادفع المبلغ بعملة {{coin}}',
      pay_generate: 'توليد الدفع',
      pay_selected: 'التبرع المحدد',
      pay_value_in: 'دفع المبلغ في',
      pay_with_balance: 'التبرع بالرصيد',
      payable: 'يتبرع',
      payer: 'دافع',
      payment: 'قسط',
      ped_retirada: 'التبرع بالانسحاب',
      ped_retirada_gan_diario: 'التبرع بسحب الأرباح اليومية',
      pendent: 'قيد الانتظار',
      people: 'الناس',
      person: 'شخص',
      person_found_in_other_prefecture: 'شخص مسجل بالفعل في بلدية أخرى',
      personal: 'شباب',
      personal_addresses: 'العناوين',
      personal_contacts: 'جهات الاتصال',
      personal_data: 'بيانات شخصية',
      personal_infos: 'معلومة',
      personal_pcva: 'برنامج البيت الأخضر والأصفر',
      phone: 'هاتف',
      photo: 'تصوير',
      pix: 'بيكس',
      pix_payment: 'الدفع بالبيكس',
      pix_payment_return: 'إعادة الدفع في PIX',
      pix_receipt: 'الاستلام في PIX',
      pl_carreira: 'المسار المهني',
      plan: 'مستوي',
      plates: 'لوحات',
      please_wait: 'انتظر من فضلك',
      plus: 'زائد',
      point: 'نقطة',
      points: 'نقاط',
      portfolio: 'مَلَفّ',
      pos_complete: 'بالمعنى اللاتيني - كامل',
      pos_incomplete: 'بالمعنى اللاتيني - غير مكتمل',
      prefecture: 'بلدية',
      prefecture_addresses: 'العناوين',
      prefecture_contacts: 'جهات الاتصال',
      prefecture_data: 'بيانات قاعة المدينة',
      prefecture_infos: 'معلومة',
      prefectures: 'قاعات المدينة',
      prev: 'سابق',
      price: 'سعر',
      print: 'اطبع',
      privacy_policies: 'سياسات الخصوصية',
      private_works: 'أعمال خاصة',
      profession: 'مهنة',
      profile: 'حساب تعريفي',
      program: 'برنامج',
      programs: 'برمجة',
      project: 'مشروع',
      projects: 'المشاريع',
      public_works: 'الأشغال العامة',
      purchase: 'شراء',
      qualify_binary: 'المؤهل الثنائي',
      qualquer: 'أي',
      quantity: 'كمية',
      quotation: 'سعر',
      read: 'ليقرأ',
      receivable: 'لاستقبال',
      recipient: 'المتلقي',
      recommendations: 'التوصيات',
      redefine_password: 'إعادة تعريف كلمة المرور',
      register: 'سِجِلّ',
      register_people: 'تسجيل الأشخاص',
      register_wallet: 'قم بتسجيل المحفظة لإجراء عمليات السحب',
      registers: 'السجلات',
      registers_users: 'تسجيلات المانحين',
      reject: 'يرفض',
      remaining: 'متبقي',
      remove: 'لازالة',
      rendaIndividual: 'الدخل الإجمالي الفردي',
      renew: 'تجديد',
      reply: 'ليجيب',
      reports: 'التقارير',
      required_data_not_sent: 'لم يتم إرسال البيانات المطلوبة',
      resend_email: 'إعادة إرسال البريد الإلكتروني',
      residential: 'سكني',
      responded: 'أجاب',
      restricao: 'هل لديك قيود على اسمك؟ (توافق آراء ساو باولو/سيراسا)',
      result: 'نتيجة',
      results: 'نتائج',
      resume_data: 'ملخص البيانات',
      revoked: 'ألغيت',
      rg: 'التسجيل العام (الهوية)',
      rgAgency: 'مصدر RG',
      rgDate: 'تاريخ الإصدار RG',
      rgUf: 'دولة آر جي',
      right: 'يمين',
      right_preferential: 'تفضيل الجانب الأيمن',
      roi: 'الأرباح اليومية',
      sale: 'أُوكَازيُون',
      saq_duplicado: 'سحب مكرر',
      save: 'للحفظ',
      schooling: 'تعليم',
      search: 'يبحث',
      secretary: 'سكرتير',

      security_password: 'كلمة المرور الأمن',
      see: 'لترى',
      see_advents: 'شاهد الإعلانات',
      see_call: 'عرض المكالمة',
      see_details: 'انظر التفاصيل',
      see_details_wallet: 'عرض تفاصيل المحفظة',
      see_financing: 'عرض التمويل',
      see_order: 'عرض التبرع',
      see_user: 'عرض الجهات المانحة',
      select_vehicle: 'اختر مركبة',
      self_register: 'يسجل',
      sell_volume: 'حجم المبيعات',
      seller: 'تاجر',
      send_call: 'إرسال مكالمة',
      send_to: 'ارسل إلى',
      September: 'سبتمبر',
      settings: 'إعدادات',
      share_your_link: 'مشاركة الرابط الخاص بك',
      show: 'عرض حتى',
      show_from: 'العرض من',
      side: 'جانب',
      sign_in_to_start_your_session: 'قم بتسجيل الدخول لبدء جلستك',
      sign_up: 'يسجل',
      single: 'أعزب',
      sold: 'مُباع',
      state: 'ولاية',
      status: 'حالة',
      strategies: 'الاستراتيجيات',
      street: 'طريق',
      subject: 'موضوع',
      submit: 'لترسل',
      submit_placeholder: 'يرجى ملء البريد الإلكتروني الخاص بك',
      subscribe: 'قم بالتسجيل لتلقي أخبارنا',
      subtitle: 'التسمية التوضيحية',
      success_update_user: 'تم تحديث الجهة المانحة بنجاح!',
      success_wallet: 'تم تحديث المحفظة بنجاح!',
      suggestions: 'اقتراحات',
      superior_complete: 'تخرج',
      superior_incomplete: 'غير مكتمل أعلى',
      supervisor: 'مشرف',
      support: 'يدعم',
      support_materials: 'مواد الدعم',
      suspended: 'معلق',
      symbol: 'رمز',
      symbols: 'حرف او رمز',
      system: 'نظام',
      target: 'هدف',
      telegram_chat_id: 'معرف دردشة برقية',
      tempoCarteira: 'منذ متى وأنت مسجل في محفظتك؟',
      terms: 'أقبل شروط الاستخدام',
      text_hint_binance:
        'عند إنشاء مفتاح API على Binance، أدخل عنوان IP في قيود الوصول:\n172.105.78.139\nثم حدد الخيارات:\n• تمكين الخيارات الأوروبية\n• تمكين التداول الفوري والهامش',
      this_value_in: 'هذه القيمة في',
      ticker_health: 'تيكر هيلث',
      title: 'عنوان',
      to: 'حتى',
      to_pay: 'للتبرع',
      to_receive: 'مستحق',
      today_entry: 'دخول اليوم',
      token_not_found_or_already_used: 'لم يتم العثور على الرمز المميز أو تم استخدامه بالفعل',
      total: 'المجموع',
      total_entry: 'إجمالي الدخول',
      traderbot: 'تاجر بوت',
      transf_credito: 'تحويل الرصيد',
      transf_debito: 'تحويل الديون',
      transfer_payment: 'الدفع عن طريق التحويل',
      transfer_payment_return: 'إعادة الدفع عن طريق التحويل',
      transfer_receipt: 'الاستلام في التحويل',
      transparency: 'الشفافية',
      tx_adm_cred: 'إدارة الضرائب',
      tx_adm_deb: 'إدارة الضرائب',
      tx_retirada: 'رسوم الانسحاب',
      tx_retirada_gan_diario: 'رسوم سحب الأرباح اليومية',
      type: 'يكتب',
      update: 'للتحديث',
      update_wallet: 'تحديث المحفظة',
      upgrade: 'تحسين',
      used: 'مستخدم',
      user: 'جهات مانحة',
      user_active: 'متبرع نشط',
      user_already_exists: 'المانح موجود بالفعل!',
      user_and_or_password_wrong: 'الجهة المانحة و/أو كلمة المرور خاطئة!',
      user_inactive: 'متبرع غير نشط',
      user_not_found: 'لم يتم العثور على المتبرع',
      user_not_logged: 'المتبرع لم يسجل الدخول',
      user_or_password_wrong: 'الجهة المانحة أو كلمة المرور غير صحيحة',
      users: 'الجهات المانحة',
      users_list: 'قائمة المانحين',
      valid_images: 'صور صالحة',
      valorRenda2: 'ما هي قيمة الدخل الثاني',
      value: 'قيمة',
      value_donate_on: 'قيمة التبرع في',
      value_financed: 'المبلغ الممول',
      value_installment: 'قيمة القسط',
      values: 'قيم',
      vehicle: 'عربة',
      vehicles: 'مركبات',
      view_order: 'مشاهدة الطلب',
      vision: 'رؤية',
      visitor: 'زائر',
      voucher: 'فاتورة',
      wait_confirm: 'في انتظار التأكيد',
      waiting: 'منتظر',
      waiting_email: 'تم التسجيل بنجاح، الآن كل ما عليك فعله هو تأكيد بريدك الإلكتروني.',
      register_ok: 'تم التسجيل بنجاح.',
      waiting_payment: 'في انتظار التبرع',
      wallet: 'مَلَفّ',
      wallet_generate: 'إنشاء المحفظة',
      wallets: 'محافظ',
      want_donate: 'أريد التبرع',
      want_now_more_about: 'أريد أن أعرف المزيد عنها',
      warning: 'انتباه',
      we_are: 'نكون',
      webhooks: 'خطافات الويب',
      welcome: 'مرحباً',
      who_we_are: 'من نحن',
      who_we_are_subtitle: 'وصف موجز لل',
      widower: 'أرمل',
      will_expire_in: 'سوف تنتهي في',
      with: 'مع',
      withdraw: 'انسحاب',
      withdraw_sended_email: 'تم طلب السحب، تحقق من بريدك الإلكتروني وانقر على الرابط للتفويض!',
      withdrawal: 'عمليات السحب',
      withdrawals: 'عمليات السحب',
      year: 'سنة',
      years: 'سنين',
      yes: 'نعم',
      you_have: 'لديك',
      your_country: 'بلدك',
      your_dashboard: 'هذه هي لوحة التحكم الخاصة بك.',
      your_link: 'وصلتك',
      zipcode: 'الرمز البريدي',
      withdrawal_sended: 'تم إرسال السحب',
      withdrawal_pending: 'تم طلب السحب بالفعل! \nيرجى انتظار الدفع لإجراء عملية سحب أخرى.',
      limite_80_top: 'لقد وصلت إلى {{perc}} الحد المسموح به.\n توقع التجديد وتجنب تعليق دخلك.',
      game: 'لعبة',
      games: 'ألعاب',
      winning_numbers: 'الأرقام المرسومة',
      your_numbers: 'أرقامك',
      add_numbers: 'إضافة أرقام',
      next_draw: 'السحب القادم',
      can_bet_until: 'يمكنك المراهنة على ما يصل إلى',
      your_bets: 'الرهانات الخاصة بك',
      add_bet: 'أضف الرهان',
      bet: 'رهان',
      bets: 'الرهانات',
      numbers: 'أعداد',
      special_numbers: 'أرقام مميزة',
      resume: 'ملخص',
      choosed_numbers: 'أرقام مختارة',
      choosed_special_numbers: 'تم اختيار أرقام مميزة',
      bets_finished: 'الرهانات المستقرة',
      prize_draw: 'جائزة السحب',
      balance_network: 'توازن المجتمع',
      telegram: 'برقية',
      logs: 'السجلات',
      release_withdrawals: 'الافراج عن الانسحابات',
      bot_active: 'الافراج عن بوت',
      available: 'متاح',
      sunday: 'الأحد',
      monday: 'ثانية',
      tuesday: 'ثالث',
      wednesday: 'الرابع',
      thursday: 'الخامس',
      friday: 'جمعة',
      saturday: 'السبت',
      title_binary: 'تعيين النسب الثنائية لكل يوم',
      ok_perc_change: 'تم تغيير النسب بنجاح',
      cred_deb_title: 'الرصيد الائتماني أو المدين',
      send: 'لترسل',
      voucher_pendent: 'قسيمة معلقة',
      leader_pendent: 'الزعيم المنتظر',
      voucher_approved: 'تمت الموافقة على القسيمة',
      leader_approved: 'القائد المعتمد',
      no_binary_at_today: 'لا يوجد ثنائي مسجل في ذلك اليوم',
      generate_file: 'إنشاء ملف',
      withdrawals_file_created: 'تم إنشاء ملف السحب',
      withdrawals_sended: 'تم إرسال طلبات السحب',
      see_generated_files: 'عرض الملفات التي تم إنشاؤها',
      files: 'ملفات',
      file: 'ملف',
      verify: 'للتأكد',
      rescue: 'ينقذ',
      awarded: 'منحت',
      rescued: 'تم إنقاذه',
      repeat: 'يكرر',
      draw_date: 'تاريخ السحب',
      bet_date: 'تاريخ الرهان',
      drawn_numbers: 'الأرقام المرسومة',
      awardeds_numbers: 'أرقام الجائزة',
      no_award: 'لا جائزة',
      you_hit_number: 'لقد حصلت على رقم {{qty}} بشكل صحيح',
      you_hit_numbers: 'لقد حصلت على {{qty}} أرقام صحيحة',
      lottery: 'اليانصيب',
      lotteries: 'اليانصيب',
      balls: 'كرات',
      special_balls: 'العروض الخاصة',
      levy: 'مجموعة',
      major_awarded: 'المزيد من الكرات',
      hits: 'يضرب',
      cred_mandala: 'رصيد ستار ماتريكس',
      renew_mandala: 'تجديد ستار ماتريكس',
      mandala: 'مصفوفة النجوم',
      mandala2: 'مصفوفة النجوم 2.0',
      mandalas: 'مصفوفة النجوم',
      mandala_in: 'المشاركة في ستار ماتريكس',
      rifas: 'مصفوفة النجوم',
      rifa: 'مصفوفة النجوم',
      lascado: 'انها متكسرة',
      estribado: 'لقد تم تحريكه',
      none: 'لا أحد',
      directs: 'أصدقاء',
      matuto: 'ماتوتو',
      matutos: 'ماتوتوس',
      cupom: 'رقم القسيمة في ستار ماتريكس',
      your_position: 'موقعك في مصفوفة النجوم',
      closed: 'مغلق',
      gang: 'مصفوفة النجوم',
      gangs: 'مصفوفة النجوم',
      influencer_required: 'تحتاج إلى اختيار المؤثر',
      pendent_order: 'لديك تبرعات معلقة',
      influencer: 'المؤثر',
      change_influencer: 'مبادلة المؤثر',
      no_influencer: 'لا يوجد مؤثر',
      msg_no_influencer: 'لم تقم باختيار أي مؤثرين بعد',
      you_are_influencer: 'هل أنت مؤثر؟ انقر هنا وقم بالتسجيل',
      register_influencer: 'تسجيل المؤثرين',
      add_your_photo: 'أضف صورتك',

      hello: 'مرحبًا',
      msg_add_order:
        'شكرًا لك على التسجيل في {{name}}.\nيسعدنا أن تكون جزءًا من مجتمعنا.\n\nلمشاهدة البث والاستمتاع بجميع المزايا، يتعين عليك دفع رسوم العضوية.\nالعضوية هي- رسوم الوقت التي تتيح لك الوصول إلى المحتوى الحصري والدعم الفني والتحديثات وغير ذلك الكثير.\n\nللدفع مقابل عضويتك، ما عليك سوى الدفع عبر PIX باستخدام رمز الاستجابة السريعة أدناهأو عن طريق نسخ العنوان الموجود أسفل رمز الاستجابة السريعة ولصقه في تطبيق البنك الذي تتعامل معه.\n\nيتم التأكيد تلقائيًا وفوري (عادةً خلال 10 ثوانٍ).\n\nوهذا كل ما في الأمر! بعد ذلك ستتمكن من الاستمتاع بـ {{name}}.\n\nإذا كانت لديك أي أسئلة أو مشاكل، فيرجى الاتصال بنا عبر البريد الإلكتروني على {{email}}.\n\nأطيب التحيات',
      msg_mandala_order:
        'أنت على بعد خطوة واحدة من المشاركة في مساعدتنا المتبادلة، Star Matrix.\n\nThe Star Matrix هي طريقة ممتعة وآمنة لكسب أموال جيدة.\n\nللمشاركة، ما عليك سوى التبرع عن طريق الوصول إلى الوسيط الخاص بك ومن خلال قراءة رمز الاستجابة السريعة الموجود أدناه \n\nيتم التأكيد تلقائيًا ولكن قد يستغرق بضع دقائق.\n\nبعد ذلك ستتمكن من الاستمتاع بـ {{name}}.\n\nإذا كانت لديك أية أسئلة أو مشاكل، يرجى الاتصال بالمرشح الخاص بك.\n\nمع خالص التقدير',
      msg_cota_solidaria_order:
        'مع Solidarity Quotas، فإنك تساهم في عملنا الاجتماعي من خلال مساعدة العائلات المحتاجة وحتى التنافس على سيارة.\n\nللدفع والحصول على Solidarity Quota، ما عليك سوى إجراء الدفع عبر PIX باستخدام رمز الاستجابة السريعة أدناهأو عن طريق نسخ العنوان أدناه رمز الاستجابة السريعة ولصقه في تطبيق البنك الذي تتعامل معه.\n\nيتم التأكيد تلقائيًا وفوري (عادةً خلال 10 ثوانٍ).\n\nوهذا كل ما في الأمر! بعد ذلك، ستشارك بالفعل في حصة التضامن.\n\nإذا كانت لديك أية أسئلة أو مشاكل، فيرجى الاتصال بنا عبر تذكرة.\n\nمع خالص التقدير',
      followers: 'متابعون',
      required_fields: 'الحقول المطلوبة',
      your_influencer: 'المؤثر الخاص بك',
      cota_solidaria: 'حصة التضامن',
      cotas_solidarias: 'حصص التضامن',
      add_cota_solidaria: 'إضافة حصة التضامن',
      add_cotas_solidarias: 'إضافة حصص التضامن',
      coupon: 'قسيمة',
      error_mandala_open: 'يوجد بالفعل تبرع مفتوح لـ Star Matrix',
      error_cotaSolidaria_open: 'لديك بالفعل تبرع بحصة تضامنية مفتوحة',
      cotaSolidaria_not_created: 'لم يتم إنشاء حصة التضامن',
      success_mandala: 'تم إنشاء تبرع Star Matrix بنجاح',
      success_cotaSolidaria: 'تم إنشاء حصة التضامن بنجاح',
      cotas_solidaria_desc: 'أكد رغبتك في شراء {{qty}} حصص التضامن بسعر {{price}} بإجمالي {{total}}',
      cota_solidaria_desc: 'أكد رغبتك في شراء {{qty}} حصة التضامن بسعر {{price}} بإجمالي {{total}}',
      builder: 'البناء',
			top: 'قمة',
			day: 'يوم',
      days: 'أيام',
      hour: 'ساعة',
      hours: 'ساعات',
      minute: 'دقائق',
      minutes: 'دقائق',
      second: 'ثانية',
      seconds: 'ثوان',
      comming: 'مفتقد',
      comming_soon: 'إطلاق في جميع أنحاء العالم',
			net: 'شبكة',
			raffels: 'السحوبات',
			new_raffle: 'سحبة جديدة',
    },
  },
}
export { messages }
