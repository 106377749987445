import React, { useRef } from 'react'
import { i18n } from '../../../utils/translates/i18n'
import { FormateDate, FormateFiatCurrency } from '../../../utils/CustomFormatations'

export default function OrderPaymentsModal({ order }) {
  const btnClose = useRef('')

  return (
    <div
      className="modal fade"
      id="modalPayments"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div className="modal-content ">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {`${i18n.t('payment')} ${i18n.t('order')} # ${order?.id}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="border-gray-200">{i18n.t('form_of_payment')}</th>
                    <th className="border-gray-200">{i18n.t('status')}</th>
                    <th className="border-gray-200">{i18n.t('amount')}</th>
                    <th className="border-gray-200">{i18n.t('paid')}</th>
                    <th className="border-gray-200">{i18n.t('paid_date')}</th>
                    <th className="border-gray-200">{i18n.t('coin')}</th>
                    <th className="border-gray-200">{i18n.t('wallet')}</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.payments?.map((op, pi) => (
                    <tr key={pi}>
                      <td>
                        {i18n.t(op.method)}
                        {op.method === 'balance' ? (
                          ` (${
                            order?.moviments?.filter(
                              f =>
                                new Date(f.createdAt).toISOString().split('.')[0] ===
                                new Date(op.payDate).toISOString().split('.')[0]
                            )[0]?.user?.nick
                          })`
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        <span className={`btn btn-sm btn-${op.statuses?.bg}`}>{i18n.t(op.statuses.status)}</span>
                      </td>
                      <td>{FormateFiatCurrency(op.amount)}</td>
                      <td>{FormateFiatCurrency(op.paid)}</td>
                      <td>{FormateDate(op.payDate, true, true)}</td>
                      <td>{op.cryptoSymbol}</td>
                      <td>{op.cryptoWallet}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
