import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu/Menu'
import { i18n } from '../../utils/translates/i18n'
import { sendProlabore } from '../../services/CredDebtService'
import LoaderPage from '../../utils/LoaderPage'
import { toast } from 'react-toastify'
// import { decrypt } from '../../utils/crypto'

export default function Prolabore() {
  // const token = sessionStorage.getItem('token')
  // const rule = decrypt(sessionStorage.getItem('rule'))
  // const role = decrypt(sessionStorage.getItem('role'))
  const [dataPost, setDataPost] = useState({ wallet: '', coin: 'usdt', amount: 0 })
  const [show, setShow] = useState(false)

  const onInputChange = e => setDataPost(p => ({ ...p, [e.target.id]: e.target.value }))

  const doSave = async () => {
    setShow(false)
    const success = await sendProlabore(dataPost)
    if (success.status) {
      toast.success(`prolabore enviado com sucesso para ${dataPost.wallet}`)
      setDataPost({ wallet: '', coin: 'usdt', amount: 0 })
      setShow(true)
    } else {
      setShow(true)
      toast.error(i18n.t(success.error))
    }
  }

  // useEffect(() => {
  //   if (rule && role && ['developer'].includes(rule) && [3, 11].includes(parseInt(role))) setShow(true)
	// }, [rule, role])
	
	useEffect(() => {
		setShow(true)
	}, []);

  return (
    <>
      <Menu />
      <main className="content">
        {!show ? (
          <LoaderPage />
        ) : (
          <>
            <div className="container-fluid">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <div className="h3">{i18n.t('prolabore')}</div>
                </div>
              </div>
              <div className="card card-body border-0 shadow mb-5">
                <div className="h5">{i18n.t('prolabore')}</div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group">
                      <span className="btn btn-primary">{i18n.t('wallet')}</span>
                      <input
                        id="wallet"
                        value={dataPost.wallet}
                        onChange={onInputChange}
                        className="form-control"
                        placeholder={`${i18n.t('wallet')} ${i18n.t('polygon')}`}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="input-group">
                      <span className="btn btn-primary">{i18n.t('coin')}</span>
                      <select id="coin" value={dataPost.coin} onChange={onInputChange} className="form-select">
                        <option value="usdt" className="text-success fw-bold">
                          {'USDT'}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group">
                      <span className="btn btn-primary">{i18n.t('amount')}</span>
                      <input
                        id="amount"
                        value={dataPost.amount}
                        type="number"
                        onChange={onInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-success text-white"
                      onClick={doSave}
                      disabled={!dataPost.wallet || !dataPost.coin || !dataPost.amount || dataPost.amount <= 0}>
                      {/* <i className="fa fa-check"></i> */}
                      <span className="ms-2">{i18n.t('send')}</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* <pre>{JSON.stringify(dataPost, null, 2)}</pre> */}
            </div>
          </>
        )}
      </main>

      {/* <Footer /> */}
    </>
  )
}
