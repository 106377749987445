const messages = {
  de: {
    translations: {
      a_crase: 'Der',
      about: 'Über uns',
      above_the_same: 'Genau das Gleiche oben',
      action: 'Aktion',
      actionTemplates: 'Aktionsmodelle',
      activate_date: 'Aktivierungs-Datum',
      active_automations: 'Aktive Automatisierungen',
      active_connections: 'Aktive Verbindungen',
      active_monitors: 'Aktive Monitore',
      active_users: 'Aktive Spender',
      ad: 'Bekanntmachung',
      add: 'Beitritt',
      add_option: 'Option hinzufügen',
      add_payment: 'Zahlung hinzufügen',
      add_question: 'Frage hinzufügen',
      add_receipt: 'Quittung hinzufügen',
      add_wallet: 'Geldbörse hinzufügen',
      address: 'Adresse',
      admin: 'Verwaltung',
      advance: 'Vorauszahlung',
      affected: 'Erreicht',
      after: 'Nach',
      air_transport: 'Lufttransport',
      ajust_limite: 'Grenzwertanpassung',
      ajust_saldo: 'Balance-Anpassung',
      alert: 'Alarm',
      alerts: 'Warnungen',
      all: 'Alle',
      all_departments: 'Alle Abteilungen',
      allies_of: 'Verbündete von',
      allRightsReserved: 'Alle Rechte vorbehalten.',
      almost_there: 'Fast dort',
      already_offset: 'Ich habe bereits entschädigt',
      already_registered: 'Bereits registriert? Klicken Sie hier und geben Sie ein',
      amount: 'Wert',
      amount_above_balance: 'Unzureichende Mittel',
      and: 'Es ist',
      and_more: 'und mehr',
      answer: 'Antwort',
      ao: 'zum',
      approve: 'Zu genehmigen',
      April: 'April',
      asc: 'Aufsteigend',
      at: 'In',
      August: 'August',
      automations: 'Automatisierungen',
      awaiting_email_confirmation: 'Warten auf E-Mail-Bestätigung!',
      b_order: 'Befehl',
      b_orders: 'Aufträge',
      back: 'Zurück zu gehen',
      bairroInteresse: 'Nachbarschaft',
      balance: 'Gleichgewicht',
      balance_available: 'Spendenguthaben',
      banned: 'Verboten',
      before_contact: 'Bevor Sie uns kontaktieren',
      binary: 'Binär',
      birth: 'Geburt',
      blind_man: 'Umgedreht',
      body_monthly:
        'Ihre monatliche Gebühr ist abgelaufen und Ihr BOT TRADER wurde inaktiviert. Bitte bezahlen Sie den Zahlungsauftrag und genießen Sie Ihre Vorteile erneut',
      bonus_saldo_trader: 'Bonus für Händlerguthaben',
      book_health: 'BookHealth',
      but_possible_register:
        'Es ist weiterhin möglich, sich zu registrieren, aber Sie werden nicht mit einem empfehlenden Freund verknüpft.',
      buy_volume: 'Kaufvolumen',
      buyer: 'Käufer',
      by: 'pro',
      bye: 'Bis später',
      call: 'Angerufen',
      call_to: 'Ruf an',
      calls: 'Berufungen',
      cancel: 'Stornieren',
      canceled: 'Abgesagt',
      career_plan: 'Karriereweg',
      casa_verde_amarela: 'Gelbgrünes Haus',
      cash_payment: 'Barzahlung',
      cash_payment_return: 'Rückerstattung der Barzahlung',
      cash_receipt: 'Kassenbeleg',
      change_indicant: 'Änderungsindikator',
      change_photo: 'Foto ändern',
      change_plan: 'Plan ändern',
      check_payment: 'Zahlung per Scheck',
      check_payment_return: 'Rückerstattung der Zahlung per Scheck',
      check_receipt: 'Erhalt per Scheck',
      choose: 'Wählen',
      cidadeInteresse: 'Stadt',
      city: 'Stadt',
      claims: 'Ansprüche',
      clean: 'Reinigen',
      click_and_know: 'Klicken und entdecken',
      click_to_copy_the_wallet: 'Klicken Sie auf „Wallet kopieren“.',
      client: 'Klient',
      clients: 'Kunden',
      close: 'Schließung',
      cnh: 'CNH (Lizenz)',
      cnhDate: 'CNH-Ausgabedatum',
      cnpj: 'CNPJ',
      cnpj_invalid: 'Ungültiges CNPJ',
      coin: 'Münze',
      color: 'Farbe',
      commercial: 'Kommerziell',
      company: 'Name der Firma',
      complement: 'Ergänzen',
      comporRenda: 'Wirst du mit jemandem Geld verdienen?',
      compRenda: 'Art des Einkommensnachweises',
      confirm_approve: 'Bestätigen Sie die Genehmigung',
      confirm_delete: 'Löschung bestätigen',
      confirm_new_password: 'Bestätige neues Passwort',
      confirm_new_password_security: 'Bestätigen Sie das neue Sicherheitspasswort',
      confirm_password: 'Bestätigen Sie das Passwort',
      confirm_password_security: 'Bestätigen Sie das Sicherheitspasswort',
      confirm_your_new_password: 'Geben Sie Ihr neues Passwort erneut ein',
      confirmed: 'Bestätigt',
      confirmed_email: 'Bestätigte E-Mail',
      congratulations: 'Glückwunsch',
      contact: 'Kontakt',
      contract: 'Vertrag',
      copied: 'Kopiert',
      copy: 'Kopieren',
      copy_success: 'Erfolgreich kopiert!',
      correspondences: 'Korrespondenz',
      countries: 'Länder',
      country: 'Land',
      cpf: 'CPF',
      cpf_invalid: 'Ungültiger CPF',
      create_order_success: 'Spende erfolgreich erstellt!',
      created: 'Registriert in',
      cred: 'Kredit',
      cred_binario: 'Binärer Kredit',
      cred_deb: 'Gutschriften/Belastungen',
      cred_game_donate: 'Spielguthaben spenden',
      cred_ind_direta: 'Schrittkredit',
      cred_ind_indireta: 'Schrittkredit',
      cred_pagar_com_saldo: 'Guthaben mit Guthaben spenden',
      cred_pontos: 'Kreditpunkte',
      cred_recb_trader: 'Kreditempfangshändler',
      cred_renovacao: 'Verlängerungsguthaben',
      cred_saldo_disp: 'Gutschrift des Spendenguthabens',
      cred_upgrade: 'UpGrade-Guthaben',
      credit_card_payment: 'Zahlung per Kreditkarte',
      credit_card_payment_return: 'Rückerstattung der Zahlung per Kreditkarte',
      credit_card_receipt: 'Kreditkartenbeleg',
      current: 'Aktuell',
      current_password: 'Aktuelles Passwort',
      current_wallet: 'Aktuelles Portfolio',
      dashboard: 'Bedienfeld',
      data_added: 'Daten hinzugefügt',
      data_saved: 'Gespeicherte Daten',
      datas: 'Daten',
      date: 'Datum',
      date_first_installment: 'Datum der ersten Zahlung',
      ddi: 'DDI',
      deb_de_trader: 'Händlerschulden',
      deb_game_donate: 'Spielspendenlastschrift',
      deb_saldo: 'Sollsaldo',
      deb_saldo_disp: 'Abbuchung des Spendensaldos',
      deb_saldo_pendent: 'Ausstehender Lastschriftsaldo',
      deb_val_indev: 'Zu Unrecht belasteter Betrag',
      debit_card_payment: 'Zahlung per Debitkarte',
      debit_card_payment_return: 'Rückerstattung der Zahlung per Debitkarte',
      debit_card_receipt: 'Erhalt per Debitkarte',
      debt: 'Schulden',
      debt_td: 'Lastschrift',
      December: 'Dezember',
      decImpRenda: 'Einkommensteuer erklären',
      deleted_document: 'Das Dokument wurde aus Ihrem Datensatz gelöscht',
      department: 'Abteilung',
      dependents: 'Angehörige',
      desc: 'Nach unten',
      description: 'Beschreibung',
      details: 'Einzelheiten',
      dev_econom: 'Wirtschaftliche Entwicklung',
      developer: 'Entwickler',
      directly_indicated: 'Direkt angegeben',
      directly_indicateds: 'Direkt weitergeleitet',
      district: 'Nachbarschaft/Bezirk',
      divorced: 'Geschieden',
      doa_men_receb: 'Monatliche Plattformspende erhalten',
      doacao_td: 'Spende',
      doctorate_complete: 'Promotion – abgeschlossen',
      doctorate_incomplete: 'Promotion - Unvollständig',
      document: 'Dokumentieren',
      document_already_registered: 'Dokument bereits registriert',
      documents: 'Unterlagen',
      donate: 'Spenden',
      donor: 'Spender',
      dont_know_zip: 'Sie kennen die Postleitzahl nicht? klicken Sie hier',
      dou_fe: 'Ich bestätige, dass die oben genannten Informationen korrekt sind.',
      downloads: 'Dateien herunterladen',
      drop_images: 'Klicken oder ziehen Sie die Bilder hierher',
      due_date: 'Reife',
      edit: 'Bearbeiten',
      edit_option: 'Option bearbeiten',
      edit_question: 'Frage bearbeiten',
      email: 'Email',
      email_already_registered: 'Email schon registriert',
      email_and_or_password_wrong: 'Falsche E-Mail und/oder Passwort!',
      email_for_login: 'Diese E-Mail ist Ihr Login',
      email_or_password_wrong: 'falsche Email oder Passwort',
      employee: 'Mitarbeiter',
      employees: 'Mitarbeiter',
      empty: 'Leer',
      enter_your_new_password: 'Gib dein neues Passwort ein',
      entrada: 'Hat es einen Einstiegswert? Wenn JA, welcher Wert',
      entries_until_today: 'Bisherige Einträge',
      error_confirm_password: 'Bestätigung und neues Passwort müssen identisch sein',
      error_email: 'Ungültiges Email-Format',
      error_password: 'Falsches Passwort',
      error_phone: 'Ungültige Telefonnummer',
      estadoInteresse: 'Zustand',
      estimate: 'Geschätzt',
      exchange: 'Ersatz',
      expired: 'Abgelaufen',
      extorno_td: 'Umkehrung',
      extra: 'Extra',
      extract: 'Bewegung',
      extracts: 'Bewegungen',
      fantasy: 'Fantasie',
      fatherName: 'Der Name des Vaters',
      February: 'Februar',
      fee: 'Abhebegebühr',
      feedback: 'Zurückkehren',
      feminine: 'Feminin',
      fgts: 'Wert Ihres FGTS',
      field_required: 'Obligatorischer Abschluss',
      final: 'Finale',
      finance: 'Finanziell',
      financial: 'Finanziell',
      financial_payment: 'Finanzielle Zahlung',
      financial_payment_return: 'Rückerstattung der finanziellen Zahlung',
      financial_receipt: 'Erhalt der Finanzierung',
      financing: 'Finanzierung',
      financings: 'Finanzierung',
      first_10_pages: 'Erste 10 Seiten',
      fisica: 'Physische Person',
      for_year: 'Pro Jahr',
      forget_password: 'Ich habe das Passwort vergessen',
      forgot_password: 'Passwort vergessen',
      form: 'Bilden',
      form_of_payment: 'Zahlungsart',
      found: 'Gefunden',
      founds: 'Gefunden',
      free: 'Frei',
      friend_indicated: 'Von meinen Freunden empfohlen',
      friend_indicateds: 'Von meinen Freunden empfohlen',
      fuel: 'Kraftstoff',
      full_name: 'Vollständiger Name',
      funcPublico: 'Ist ein Beamter',
      fundamental_complete: 'Grundlegend – vollständig',
      fundamental_incomplete: 'Grundlegend – Unvollständig',
      ganh_diario: 'Tägliches Einkommen',
      gender: 'Geschlecht',

      grand_total: 'Gesamtsumme',
      group: 'Gruppe',
      has_withdraw_today: 'Eine Auszahlung wurde bereits heute beantragt',
      high: 'Hoch',
      home: 'Start',
      how_create_binance_apikey: 'So erstellen Sie einen API-Schlüssel auf Binance',
      how_it_works: 'Wie es funktioniert',
      how_to_call: 'Wie möchtest du heißen?',
      id_td: 'Schrittkredit',
      images: 'Bilder',
      in_construction: 'Bauarbeiten im Gange',
      in_order: 'In Ordnung',
      in_stock: 'Auf Lager',
      indicant: 'Indikator',
      indicated_by: 'Sie werden von uns empfohlen',
      indicated_not_found: 'Der angegebene Referrer wurde nicht gefunden...',
      indicateds: 'Freunde',
      indicated: 'Freund',
      indirects: 'Freunde',
      info: 'Newsletter',
      info_contact: 'Kontaktinformationen',
      initial: 'Heim',
      insert_new: 'Geben Sie Neu ein',
      installments: 'Ratenzahlungen',
      intelligence: 'Intelligenz',
      interesse: 'Was ist die interessierende Region?',
      invalid_contact: 'Ungültiges Kontaktformat',
      invalid_images: 'Ungültige Bilder',
      invalid_token: 'Ungültiges Token',
      invite: 'Einladen',
      invite_friends: 'Lade deine Freunde ein',
      January: 'Januar',
      July: 'Juli',
      June: 'Juni',
      juridica: 'Juristische Person',
      know_exact_result: 'Kennen Sie den genauen Wert?',
      lead: 'Gemeinsam werden wir eine neue Zukunft aufbauen.',
      learn_more: 'Mehr wissen',
      left: 'Links',
      left_preferential: 'Bevorzuge die linke Seite',
      legalNature: 'Rechtsnatur',
      level: 'Ebene',
      limite_80:
        'Sie haben {{perc}} Ihres Limits erreicht.\n Nehmen Sie eine Verbesserung vor und vermeiden Sie eine Aussetzung Ihres Einkommens.',
      limite_excedido: 'Belastungslimit überschritten',
      linear: 'Linear',
      link_email: 'Klicken Sie auf den Link „An registrierte E-Mail gesendet“!',
      link_invite_copy: 'Teilen-Link erfolgreich kopiert',
      liquid: 'Flüssig',
      list: 'Aufführen',
      loading: 'Wird geladen',
      location: 'Standort',
      locked: 'verstopft',
      login: 'Betreten',
      logout: 'Ausgehen',
      low: 'Niedrig',
      male: 'Männlich',
      manage_users: 'Spender verwalten',
      manager: 'Manager',
      manufacturer: 'Hersteller',
      March: 'Marsch',
      maritalStatus: 'Familienstand',
      market: 'Markt',
      married: 'Verheiratet',
      master_complete: 'Masterabschluss – abgeschlossen',
      master_incomplete: 'Masterabschluss – Unvollständig',
      max_payment: 'Maximale Auszahlung',
      May: 'Mai',
      message: 'Nachricht',
      messageNotSent: 'Es war nicht möglich, Ihre Nachricht zu senden',
      messageSent: 'Ihre Nachricht wurde erfolgreich gesendet',
      middle_complete: 'Mittel – Voll',
      middle_incomplete: 'Mittel – Unvollständig',
      min_amount_error: 'Der Wert muss mindestens sein:',
      min_withdraw: 'Mindestauszahlungsbetrag',
      missing: 'Fehlen',
      mission: 'Mission',
      mission_vision_values: 'Mission, Vision und Werte',
      model: 'Modell',
      monitors: 'Monitore',
      monthly: 'Monatliche Bezahlung',
      motherName: 'Name der Mutter',
      movements: 'Bewegungen',
      msg_renovacao: 'Sie haben Ihr Verdienstlimit erreicht. Erneuern Sie Ihr Paket.',
      my_account: 'Mein Konto',
      my_wallet: 'Mein Geldbeutel',
      mystock: 'Mein Bestand',
      name: 'Name',
      nascMaisVelhoRenda2: 'Was ist das Geburtsdatum des Ältesten?',
      nationality: 'Nationalität (Geburtsland)',
      naturalness: 'Geburtsort (Geburtsort)',
      nature: 'Natur',
      network: 'Gemeinschaft',
      new: 'Neu',
      new_call: 'Neue Berufung',
      new_financing: 'Neue Finanzierung',
      new_order: 'Neue Bestellung',
      new_password: 'Neues Kennwort',
      new_password_security: 'Neues Sicherheitspasswort',
      new_seller: 'Neuer Verkäufer',
      new_wallet: 'Neue Geldbörse',
      news: 'Nachricht',
      next: 'Nächste',
      nick: 'Spender (Login)',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS bereits registriert',
      no: 'NEIN',
      no_approve: 'Noch nicht genehmigt',
      no_delete: 'Nicht löschen',
      no_literate: 'Nicht gebildet',
      no_repeat: 'Daten können nicht wiederholt werden',
      no_results_found: 'keine Ergebnisse gefunden',
      no_services_or_products_added: 'Keine Dienstleistungen oder Produkte hinzugefügt',
      no_settings: 'Gehen Sie zu Einstellungen und geben Sie Ihre Daten ein.',
      none_friends_indicated: 'Ich habe noch keine Freunde empfohlen',
      not_authorized: 'Nicht berechtigt',
      not_found: 'Nicht gefunden',
      not_login: 'Anmeldung nicht möglich, bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut!',
      not_orders: 'Keine Zahlungsanweisungen',
      not_permission: 'Sie haben keine Berechtigung, auf diese Seite zuzugreifen',
      not_registered: 'Noch nicht registriert? Klicken Sie hier und registrieren Sie sich',
      not_results: 'Keine Aufzeichnungen gefunden',
      not_self_register: 'Registrierung nicht möglich',
      not_today_entry: 'Wir haben heute noch keine Karten',
      notfound_ad: 'Keine Anzeigen gefunden',
      November: 'November',
      number: 'Nummer',
      obs: 'Kommentare',
      obs_pay:
        'Wenn Ihr Broker Gebühren vom gesendeten Betrag abbucht, müssen Sie die Korrektur manuell vornehmen, indem Sie die Beträge dieser Gebühren hinzufügen.',
      October: 'Oktober',
      of: 'In',
      off: 'Ausgelöscht',
      on_total: 'Von allen',
      open: 'Offen',
      opening: 'Öffnung',
      operation: 'Leistung',
      ordenation: 'Bestellung',
      order: 'Spende',
      order_book: 'Auftragsbuch',
      order_not_alowed_to_pay: 'Unautorisierte Spende an „Mit Restbetrag spenden“.',
      order_not_found: 'Spende nicht gefunden',
      order_payed: 'Spende #{{id}} erfolgreich bezahlt',
      orders: 'Spenden',
      other: 'Andere',
      other_services_or_products: 'Andere Dienstleistungen oder Produkte',
      paid: 'Ausbezahlt',
      paid_date: 'Zahltag',
      partial: 'Teilweise bezahlt',
      passport: 'Reisepass',
      password: 'Passwort',
      password_security: 'Sicherheitspasswort',
      pay: 'Spenden',
      pay_exact: 'Zahlen Sie den Betrag in {{coin}}',
      pay_generate: 'Zahlung generieren',
      pay_selected: 'Ausgewählte spenden',
      pay_value_in: 'Zahlen Sie den Betrag ein',
      pay_with_balance: 'Spenden Sie mit Balance',
      payable: 'Spenden',
      payer: 'Zahler',
      payment: 'Zahlung',
      ped_retirada: 'Abhebungsspende',
      ped_retirada_gan_diario: 'Tägliche Verdienstabhebungsspende',
      pendent: 'Ausstehend',
      people: 'Menschen',
      person: 'Person',
      person_found_in_other_prefecture: 'Person, die bereits in einer anderen Gemeinde gemeldet ist',
      personal: 'Jungs',
      personal_addresses: 'Adressen',
      personal_contacts: 'Kontakte',
      personal_data: 'persönliche Daten',
      personal_infos: 'Information',
      personal_pcva: 'Programm des Grünen und Gelben Hauses',
      phone: 'Telefon',
      photo: 'Foto',
      pix: 'PIX',
      pix_payment: 'Zahlung in PIX',
      pix_payment_return: 'Rückerstattung der Zahlung in PIX',
      pix_receipt: 'Empfangen in PIX',
      pl_carreira: 'Karriereweg',
      plan: 'Wohnung',
      plates: 'Platten',
      please_wait: 'Bitte warten',
      plus: 'Plus',
      point: 'Punkt',
      points: 'Punkte',
      portfolio: 'Portfolio',
      pos_complete: 'Lato sensu – Vollständig',
      pos_incomplete: 'Lato sensu – Unvollständig',
      prefecture: 'Rathaus',
      prefecture_addresses: 'Adressen',
      prefecture_contacts: 'Kontakte',
      prefecture_data: 'Rathausdaten',
      prefecture_infos: 'Information',
      prefectures: 'Rathäuser',
      prev: 'Vorherige',
      price: 'Preis',
      print: 'Ausdrucken',
      privacy_policies: 'Datenschutzrichtlinien',
      private_works: 'Private Werke',
      profession: 'Beruf',
      profile: 'Profil',
      program: 'Programm',
      programs: 'Software',
      project: 'Projekt',
      projects: 'Projekte',
      public_works: 'Öffentliche Bauarbeiten',
      purchase: 'Kaufen',
      qualify_binary: 'Binäre Qualifikation',
      qualquer: 'Beliebig',
      quantity: 'Menge',
      quotation: 'Preis',
      read: 'Lesen',
      receivable: 'Bekommen',
      recipient: 'Empfänger',
      recommendations: 'Empfehlungen',
      redefine_password: 'Passwort neu definieren',
      register: 'Aufzeichnen',
      register_people: 'Registrierung von Personen',
      register_wallet: 'Registrieren Sie ein Wallet, um Abhebungen vorzunehmen',
      registers: 'Aufzeichnungen',
      registers_users: 'Spenderregistrierungen',
      reject: 'Ablehnen',
      remaining: 'Übrig',
      remove: 'Zu entfernen',
      rendaIndividual: 'Individuelles Bruttoeinkommen',
      renew: 'Erneuern',
      reply: 'Antworten',
      reports: 'Berichte',
      required_data_not_sent: 'Erforderliche Daten wurden nicht gesendet',
      resend_email: 'Sende die E-Mail erneut',
      residential: 'Wohnen',
      responded: 'Antwortete',
      restricao: 'Gibt es Einschränkungen bezüglich Ihres Namens? (SPC/Serasa)',
      result: 'Ergebnis',
      results: 'Ergebnisse',
      resume_data: 'Datenzusammenfassung',
      revoked: 'Aufgehoben',
      rg: 'Allgemeine Registrierung (Identität)',
      rgAgency: 'RG-Emittent',
      rgDate: 'RG-Ausstellungsdatum',
      rgUf: 'Bundesstaat RG',
      right: 'Rechts',
      right_preferential: 'Bevorzuge die rechte Seite',
      roi: 'Tägliches Einkommen',
      sale: 'Verkauf',
      saq_duplicado: 'Doppelte Auszahlung',
      save: 'Speichern',
      schooling: 'Ausbildung',
      search: 'Suchen',
      secretary: 'Sekretär',

      security_password: 'Sicherheitspasswort',
      see: 'Um zu sehen',
      see_advents: 'Sehen Sie sich die Anzeigen an',
      see_call: 'Anruf ansehen',
      see_details: 'siehe Einzelheiten',
      see_details_wallet: 'Wallet-Details anzeigen',
      see_financing: 'Finanzierung ansehen',
      see_order: 'Spende ansehen',
      see_user: 'Spender anzeigen',
      select_vehicle: 'Fahrzeug auswählen',
      self_register: 'Registrieren',
      sell_volume: 'Verkaufsvolumen',
      seller: 'Verkäufer',
      send_call: 'Anruf senden',
      send_to: 'Senden an',
      September: 'September',
      settings: 'Einstellungen',
      share_your_link: 'Teilen Sie Ihren Link',
      show: 'Anzeigen bis',
      show_from: 'Anzeige ab',
      side: 'Seite',
      sign_in_to_start_your_session: 'Melden Sie sich an, um Ihre Sitzung zu starten',
      sign_up: 'Registrieren',
      single: 'Einzel',
      sold: 'Verkauft',
      state: 'Zustand',
      status: 'Status',
      strategies: 'Strategien',
      street: 'Straße',
      subject: 'Thema',
      submit: 'Senden',
      submit_placeholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
      subscribe: 'Melden Sie sich an, um unsere Neuigkeiten zu erhalten',
      subtitle: 'Untertitel',
      success_update_user: 'Spender erfolgreich aktualisiert!',
      success_wallet: 'Portfolio erfolgreich aktualisiert!',
      suggestions: 'Vorschläge',
      superior_complete: 'Absolvent',
      superior_incomplete: 'Unvollständig höher',
      supervisor: 'Aufsicht',
      support: 'Unterstützung',
      support_materials: 'Unterstützende Materialien',
      suspended: 'Ausgesetzt',
      symbol: 'Symbol',
      symbols: 'Symbole',
      system: 'System',
      target: 'Ziel',
      telegram_chat_id: 'Telegram-Chat-ID',
      tempoCarteira: 'Seit wann sind Sie in Ihrem Portfolio eingetragen?',
      terms: 'Ich akzeptiere die Nutzungsbedingungen',
      text_hint_binance:
        'Geben Sie beim Erstellen des API-Schlüssels auf Binance die IP in den Zugriffsbeschränkungen ein:\n172.105.78.139\nWählen Sie dann die Optionen aus:\n• EUROPÄISCHE OPTIONEN AKTIVIEREN\n• SPOT- UND MARGIN-HANDEL AKTIVIEREN',
      this_value_in: 'Dieser Wert in',
      ticker_health: 'TickerHealth',
      title: 'Titel',
      to: 'Bis',
      to_pay: 'Spenden',
      to_receive: 'Forderungen',
      today_entry: 'Heutiger Eintrag',
      token_not_found_or_already_used: 'Token nicht gefunden oder bereits verwendet',
      total: 'Gesamt',
      total_entry: 'Gesamteintrag',
      traderbot: 'Bot-Händler',
      transf_credito: 'Überweisung',
      transf_debito: 'Schuldenübertragung',
      transfer_payment: 'Zahlung per Überweisung',
      transfer_payment_return: 'Rückerstattung der Zahlung per Überweisung',
      transfer_receipt: 'Quittung bei Überweisung',
      transparency: 'Transparenz',
      tx_adm_cred: 'Steuern verwalten',
      tx_adm_deb: 'Steuern verwalten',
      tx_retirada: 'Abhebegebühr',
      tx_retirada_gan_diario: 'Tägliche Auszahlungsgebühr',
      type: 'Typ',
      update: 'Aktualisieren',
      update_wallet: 'Wallet aktualisieren',
      upgrade: 'Verbesserung',
      used: 'Gebraucht',
      user: 'Spender',
      user_active: 'Aktiver Spender',
      user_already_exists: 'Spender existiert bereits!',
      user_and_or_password_wrong: 'Falscher Spender und/oder Passwort!',
      user_inactive: 'Inaktiver Spender',
      user_not_found: 'Spender nicht gefunden',
      user_not_logged: 'Spender nicht angemeldet',
      user_or_password_wrong: 'Falscher Spender oder Passwort',
      users: 'Spender',
      users_list: 'Spenderliste',
      valid_images: 'Gültige Bilder',
      valorRenda2: 'Wie hoch ist das Zweiteinkommen?',
      value: 'Wert',
      value_donate_on: 'Spendenwert in',
      value_financed: 'Finanzierter Betrag',
      value_installment: 'Ratenwert',
      values: 'Werte',
      vehicle: 'Fahrzeug',
      vehicles: 'Fahrzeuge',
      view_order: 'Bestellung anzeigen',
      vision: 'Vision',
      visitor: 'Besucher',
      voucher: 'Gutschein',
      wait_confirm: 'Warte auf Bestätigung',
      waiting: 'Warten',
      waiting_email: 'Die Registrierung war erfolgreich, jetzt müssen Sie nur noch Ihre E-Mail bestätigen.',
      register_ok: 'Die Registrierung wurde erfolgreich abgeschlossen.',
      waiting_payment: 'Ich warte auf eine Spende',
      wallet: 'Portfolio',
      wallet_generate: 'Portfolio erstellen',
      wallets: 'Geldbörsen',
      want_donate: 'Ich möchte spenden',
      want_now_more_about: 'Ich möchte mehr darüber erfahren',
      warning: 'AUFMERKSAMKEIT',
      we_are: 'Sind',
      webhooks: 'Webhooks',
      welcome: 'Willkommen',
      who_we_are: 'Wer wir sind',
      who_we_are_subtitle: 'Eine kurze Beschreibung des',
      widower: 'Witwer',
      will_expire_in: 'läuft ab',
      with: 'mit',
      withdraw: 'Rückzug',
      withdraw_sended_email:
        'Auszahlung beantragt, überprüfen Sie Ihre E-Mails und klicken Sie zur Autorisierung auf den Link!',
      withdrawal: 'Abhebungen',
      withdrawals: 'Abhebungen',
      year: 'Jahr',
      years: 'Jahre',
      yes: 'Ja',
      you_have: 'Du hast',
      your_country: 'Dein Land',
      your_dashboard: 'Dies ist Ihre Systemsteuerung.',
      your_link: 'Dein Link',
      zipcode: 'PLZ',
      withdrawal_sended: 'Auszahlung gesendet',
      withdrawal_pending:
        'Auszahlung bereits beantragt! \nBitte warten Sie auf die Zahlung, um eine weitere Auszahlung vorzunehmen.',
      limite_80_top:
        'Sie haben {{perc}} Ihr Limit erreicht.\n Erwarten Sie Ihre Verlängerung und vermeiden Sie eine Aussetzung Ihres Einkommens.',
      game: 'Spiel',
      games: 'Spiele',
      winning_numbers: 'Gezogene Zahlen',
      your_numbers: 'Ihre Zahlen',
      add_numbers: 'Zahlen hinzufügen',
      next_draw: 'Nächste Ziehung',
      can_bet_until: 'Sie können bis zu wetten',
      your_bets: 'Ihre Wetten',
      add_bet: 'Wette hinzufügen',
      bet: 'Wette',
      bets: 'Wetten',
      numbers: 'Zahlen',
      special_numbers: 'Sondernummern',
      resume: 'Zusammenfassung',
      choosed_numbers: 'Ausgewählte Zahlen',
      choosed_special_numbers: 'Spezielle Nummern ausgewählt',
      bets_finished: 'Abgerechnete Wetten',
      prize_draw: 'Gewinnspiel',
      balance_network: 'Gemeinschaftsgleichgewicht',
      telegram: 'Telegramm',
      logs: 'Protokolle',
      release_withdrawals: 'Auszahlungen freigeben',
      bot_active: 'Bot freigeben',
      available: 'Verfügbar',
      sunday: 'Sonntag',
      monday: 'Zweite',
      tuesday: 'Dritte',
      wednesday: 'Vierte',
      thursday: 'Fünfte',
      friday: 'Freitag',
      saturday: 'Samstag',
      title_binary: 'Legen Sie binäre Prozentsätze für jeden Tag fest',
      ok_perc_change: 'Prozentsätze wurden erfolgreich geändert',
      cred_deb_title: 'Kredit- oder Sollsaldo',
      send: 'Senden',
      voucher_pendent: 'Gutschein ausstehend',
      leader_pendent: 'Ausstehender Anführer',
      voucher_approved: 'Gutschein genehmigt',
      leader_approved: 'Anerkannter Anführer',
      no_binary_at_today: 'An diesem Tag ist keine Binärdatei registriert',
      generate_file: 'Datei generieren',
      withdrawals_file_created: 'Auszahlungsdatei erstellt',
      withdrawals_sended: 'Auszahlungsanfragen gesendet',
      see_generated_files: 'Generierte Dateien anzeigen',
      files: 'Dateien',
      file: 'Datei',
      verify: 'Überprüfen',
      rescue: 'Rettung',
      awarded: 'Ausgezeichnet',
      rescued: 'Gerettet',
      repeat: 'Wiederholen',
      draw_date: 'Ziehungsdatum',
      bet_date: 'Wettdatum',
      drawn_numbers: 'Gezogene Zahlen',
      awardeds_numbers: 'Preisnummern',
      no_award: 'Kein Preis',
      you_hit_number: 'Sie haben {{qty}} Nummer richtig angegeben',
      you_hit_numbers: 'Sie haben {{qty}} Zahlen richtig angegeben',
      lottery: 'Lotterie',
      lotteries: 'Lotterien',
      balls: 'Bälle',
      special_balls: 'Sonderangebote',
      levy: 'Sammlung',
      major_awarded: 'mehr Bälle',
      hits: 'Treffer',
      cred_mandala: 'Star Matrix-Gutschrift',
      renew_mandala: 'Erneuerung der Start Matrix',
      mandala: 'Start Matrix',
      mandala2: 'Start Matrix 2.0',
      mandalas: 'Start Matrix',
      mandala_in: 'Nehmen Sie an der Star Matrix teil',
      rifas: 'Start Matrix',
      rifa: 'Start Matrix',
      lascado: 'Es ist angeschlagen',
      estribado: 'Es ist gerührt',
      none: 'Keiner',
      directs: 'Freunde',
      matuto: 'Matuto',
      matutos: 'Matutos',
      cupom: 'Couponnummer in der Sternmatrix',
      your_position: 'Ihre Position in der Start Matrix',
      closed: 'Geschlossen',
      gang: 'Start Matrix',
      gangs: 'Start Matrix',
      influencer_required: 'Sie müssen einen Influencer auswählen',
      pendent_order: 'Sie haben ausstehende Spenden',
      influencer: 'Influencer',
      change_influencer: 'Influencer tauschen',
      no_influencer: 'Kein Influencer',
      msg_no_influencer: 'Sie haben noch keine Influencer ausgewählt',
      you_are_influencer: 'Sind Sie ein Influencer? Klicken Sie hier und registrieren Sie sich',
      register_influencer: 'Influencer-Registrierung',
      add_your_photo: 'Fügen Sie Ihr Foto hinzu',

      hello: 'Hallo',
      msg_add_order:
        "Vielen Dank, dass Sie sich bei {{name}} registriert haben.\nWir freuen uns, Sie als Teil unserer Community zu haben.\n\nUm den Stream anzusehen und alle Vorteile zu genießen, müssen Sie die MITGLIEDSCHAFT bezahlen.\nDie MITGLIEDSCHAFT ist einmalig. Zeitgebühr, die Ihnen Zugang zu unseren exklusiven Inhalten, technischem Support, Updates und vielem mehr gewährt.\n\nUm Ihre Mitgliedschaft zu bezahlen, zahlen Sie einfach über PIX mit dem untenstehenden QR-Codeoder indem Sie die Adresse direkt unter dem QR-Code kopieren und einfügen Geben Sie es in Ihre Bank-App ein.\n\nDie Bestätigung erfolgt automatisch und sofort (normalerweise innerhalb von 10 Sekunden).\n\nUnd das war's! Danach können Sie {{name}} genießen.\n\nWenn Sie Fragen oder Probleme haben, kontaktieren Sie uns bitte per E-Mail unter {{email}}.\n\nMit freundlichen Grüßen",
      msg_mandala_order:
        'Sie sind nur einen Schritt davon entfernt, an unserer gegenseitigen Hilfe, der Star Matrix, teilzunehmen.\n\nDie Star Matrix ist eine unterhaltsame und sichere Möglichkeit, gutes Geld zu verdienen.\n\nUm teilzunehmen, müssen Sie nur die Spende tätigen, indem Sie auf zugreifen Bitte kontaktieren Sie Ihren Broker und lesen Sie den folgenden QR-Code. \n\nDie Bestätigung erfolgt automatisch, kann jedoch einige Minuten dauern.\n\nDanach können Sie {{name}} genießen.\n\nWenn Sie Fragen oder Probleme haben, wenden Sie sich bitte an uns. Bitte wenden Sie sich an Ihren Nominator.\n\nMit freundlichen Grüßen',
      msg_cota_solidaria_order:
        "Mit Solidarity Quotas tragen Sie zu unserer Sozialarbeit bei, indem Sie bedürftigen Familien helfen und konkurrieren sogar um ein Auto.\n\nUm die Solidarity Quota zu bezahlen und zu erwerben, führen Sie einfach die Zahlung über PIX mit dem untenstehenden QR-Code durchoder indem Sie die Adresse unten kopieren des QR-Codes und fügen Sie ihn in Ihre Bank-App ein.\n\nDie Bestätigung erfolgt automatisch und sofort (in der Regel innerhalb von 10 Sekunden).\n\nUnd das war's! Danach nehmen Sie bereits an der Solidaritätsquote teil.\n\nBei Fragen oder Problemen kontaktieren Sie uns bitte über ein Ticket.\n\nMit freundlichen Grüßen",
      followers: 'Anhänger',
      required_fields: 'Benötigte Felder',
      your_influencer: 'Ihr Influencer',
      cota_solidaria: 'Solidaritätsquote',
      cotas_solidarias: 'Solidaritätsquoten',
      add_cota_solidaria: 'Solidaritätsquote hinzufügen',
      add_cotas_solidarias: 'Solidaritätsquoten hinzufügen',
      coupon: 'Coupon',
      error_mandala_open: 'Es gibt bereits eine offene Star Matrix-Spende',
      error_cotaSolidaria_open: 'Sie haben bereits eine offene Solidaritätsquotenspende',
      cotaSolidaria_not_created: 'Eine Solidaritätsquote wurde nicht geschaffen',
      success_mandala: 'Star Matrix-Spende erfolgreich erstellt',
      success_cotaSolidaria: 'Solidaritätsquote erfolgreich erstellt',
      cotas_solidaria_desc:
        'Bestätigen Sie, dass Sie {{qty}} Solidaritätsquoten zum Preis von {{price}} im Gesamtwert von {{total}} erwerben möchten.',
      cota_solidaria_desc:
        'Bestätigen Sie, dass Sie {{qty}} Solidarity Quota zum Preis von {{price}} im Gesamtwert von {{total}} erwerben möchten.',
      builder: 'Konstrukteur',
			top: 'Spitze',
			day: 'Tag',
      days: 'Tage',
      hour: 'Stunde',
      hours: 'Std.',
      minute: 'Protokoll',
      minutes: 'Protokoll',
      second: 'zweite',
      seconds: 'Sekunden',
      comming: 'Fehlen',
			comming_soon: 'Weltweiter Start in ',
			net: 'Nett',
			raffels: 'Gewinnspiele',
			new_raffle: 'Neue Gewinnspiel',
    },
  },
}
export { messages }
