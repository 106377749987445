import React, { useEffect, useRef, useState } from 'react'
import { i18n } from '../../../utils/translates/i18n'
import { FormatePhone, RemoveSpaceAccents } from '../../../utils/CustomFormatations'
import { editImg, saveInfluencer } from '../../../services/InfluencersService'

/**
 * props:
 * - id
 * - doSubmit
 */
function InfluencerModal({ influencer, onSubmit }) {
  const btnClose = useRef('')
  const btnSave = useRef('')
  const btnFile = useRef('')
  const token = sessionStorage.getItem('token')

  const DEFAULT_INFLUENCER = {
    name: '',
    whatsapp: '',
    instagram: '',
    facebook: '',
    tiktok: '',
    kwai: '',
    linkedin: '',
    twitter: '',
    telegram: '',
    youtube: '',
    site: '',
    img: '',
    followers: 0,
    status: 1,
  }

  const [newInfluencer, setNewInfluencer] = useState(DEFAULT_INFLUENCER)
  const [error, setError] = useState('')
  const [file, setFile] = useState(null)

  function onInputChange(event) {
    setNewInfluencer(prevState => ({ ...prevState, [event.target.id]: event.target.value }))
  }

  const doUpload = async () => {
    const formData = new FormData()
    let fileName = file.name.split('.')
    let extension = fileName[fileName.length - 1]
    formData.append(
      'file',
      file,
      `${new Date().getTime()}_${RemoveSpaceAccents(fileName.slice(0, -1).join('_'))}.${extension}`
    )

    const result = await editImg(token, newInfluencer.id, formData)
    if (result.status) {
      if (onSubmit) onSubmit(result)
    }
  }

  function doSubmit(event) {
    const id = newInfluencer ? newInfluencer.id : null
    saveInfluencer(token, id, newInfluencer)
      .then(result => {
        if (result.status) {
          btnClose.current.click()
          if (onSubmit) onSubmit(result)
        } else setError(result.error)
      })
      .catch(err => {
        console.error(err.response ? err.response.data : err.message)
        setError(err.response ? err.response.data : err.message)
      })
  }

  useEffect(() => {
    if (influencer) {
      setNewInfluencer({ ...DEFAULT_INFLUENCER, ...influencer })
      setFile(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influencer])

  useEffect(() => {
    if (file) doUpload()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <div
      className="modal fade"
      id="modalInfluencer"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {`${i18n.t(newInfluencer && newInfluencer.id ? 'edit' : 'new')} ${i18n.t('influencer')}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="row">
                <div className="col-md-3 mb-3 text-center">
                  {newInfluencer.id ? (
                    <>
                      <div
                        className={`text-center`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => btnFile.current.click()}>
                        <img
                          src={
                            file
                              ? URL.createObjectURL(file)
                              : !!newInfluencer.img
                              ? `${process.env.REACT_APP_API_URL}/images/${newInfluencer.img}`
                              : '/img/user.png'
                          }
                          crossOrigin="anonymous"
                          alt="user"
                          className={`img-fluid`}
                          style={{ width: 'auto', height: '120px' }}
                        />
                      </div>
                      <div className="text-center">
                        <input
                          type="file"
                          id="file"
                          ref={btnFile}
                          className="d-none"
                          onChange={e => setFile(e.target.files[0])}
                        />
                      </div>
                    </>
                  ) : (
                    <img
                      src={
                        !!newInfluencer.img
                          ? `${process.env.REACT_APP_API_URL}/images/${newInfluencer.img}`
                          : '/img/user.png'
                      }
                      crossOrigin="anonymous"
                      alt="user"
                      className={`img-fluid`}
                      style={{ width: 'auto', height: '120px' }}
                    />
                  )}
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="input-group">
                        <span className="btn btn-secondary ">{i18n.t('name')}:</span>
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          onChange={onInputChange}
                          value={newInfluencer.name}
                          placeholder={i18n.t('name')}
                        />
                      </div>
                    </div>
                    <div className="col-6 mb-3">
                      <div className="input-group">
                        <span className="btn btn-secondary ">
                          <i className="fab fa-whatsapp"></i>
                        </span>
                        <input
                          type="text"
                          id="whatsapp"
                          className="form-control"
                          onChange={onInputChange}
                          value={FormatePhone(newInfluencer.whatsapp)}
                          placeholder="whatsapp"
                        />
                      </div>
                    </div>
                    <div className="col-6 mb-3">
                      <div className="input-group">
                        <span className="btn btn-secondary ">
                          <i className="fab fa-instagram"></i>
                        </span>
                        <input
                          type="text"
                          id="instagram"
                          className="form-control"
                          onChange={onInputChange}
                          value={newInfluencer.instagram}
                          placeholder="instagram"
                        />
                      </div>
                    </div>
                    <div className="col-6 mb-3">
                      <div className="input-group">
                        <span className="btn btn-secondary ">
                          <i className="fab fa-facebook"></i>
                        </span>
                        <input
                          type="text"
                          id="facebook"
                          className="form-control"
                          onChange={onInputChange}
                          value={newInfluencer.facebook}
                          placeholder="facebook"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  <div className="input-group">
                    <span className="btn btn-secondary ">
                      <i className="fab fa-tiktok"></i>
                    </span>
                    <input
                      type="text"
                      id="tiktok"
                      className="form-control"
                      onChange={onInputChange}
                      value={newInfluencer.tiktok}
                      placeholder="tiktok"
                    />
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="input-group">
                    <span className="btn btn-secondary ">Kwai</span>
                    <input
                      type="text"
                      id="kwai"
                      className="form-control"
                      onChange={onInputChange}
                      value={newInfluencer.kwai}
                      placeholder="kwai"
                    />
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="input-group">
                    <span className="btn btn-secondary ">
                      <i className="fab fa-linkedin"></i>
                    </span>
                    <input
                      type="text"
                      id="linkedin"
                      className="form-control"
                      onChange={onInputChange}
                      value={newInfluencer.linkedin}
                      placeholder="linkedin"
                    />
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="input-group">
                    <span className="btn btn-secondary ">
                      <i className="fab fa-twitter"></i>
                    </span>
                    <input
                      type="text"
                      id="twitter"
                      className="form-control"
                      onChange={onInputChange}
                      value={newInfluencer.twitter}
                      placeholder="twitter"
                    />
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="input-group">
                    <span className="btn btn-secondary ">
                      <i className="fab fa-telegram"></i>
                    </span>
                    <input
                      type="text"
                      id="telegram"
                      className="form-control"
                      onChange={onInputChange}
                      value={newInfluencer.telegram}
                      placeholder="telegram"
                    />
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="input-group">
                    <span className="btn btn-secondary ">
                      <i className="fab fa-youtube"></i>
                    </span>
                    <input
                      type="text"
                      id="youtube"
                      className="form-control"
                      onChange={onInputChange}
                      value={newInfluencer.youtube}
                      placeholder="youtube"
                    />
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="input-group">
                    <span className="btn btn-secondary ">{i18n.t('site')}:</span>
                    <input
                      type="text"
                      id="site"
                      className="form-control"
                      onChange={onInputChange}
                      value={newInfluencer.site}
                    />
                  </div>
                </div>
                <div className="col-6 mb-3">
                  <div className="input-group">
                    <span className="btn btn-secondary ">{i18n.t('followers')}:</span>
                    <input
                      type="number"
                      min={0}
                      step={1}
                      id="followers"
                      className="form-control"
                      onChange={onInputChange}
                      value={newInfluencer.followers}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="input-group">
                    <span className="btn btn-secondary ">{i18n.t('status')}:</span>
                    <select id="status" className="form-select" value={newInfluencer.status} onChange={onInputChange}>
                      <option value="">{i18n.t('status')}</option>
                      <option value="1">{i18n.t('waiting')}</option>
                      <option value="4">{i18n.t('confirmed')}</option>
                      <option value="5">{i18n.t('suspended')}</option>
                      <option value="8">{i18n.t('revoked')}</option>
                      <option value="10">{i18n.t('banned')}</option>
                      <option value="18">{i18n.t('awarded')}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {error && <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>}
              <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={doSubmit}>
                {i18n.t('save')}
              </button>
            </div>
            {/* <pre>{JSON.stringify(newInfluencer, null, 2)}</pre> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfluencerModal
