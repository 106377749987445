import React, { useState, useEffect } from 'react'
import { doEditPool, getPoolFastMatrix } from '../../../services/HydraService'
import { i18n } from '../../../utils/translates/i18n'
import LoaderPage from '../../../utils/LoaderPage'
import { toast } from 'react-toastify'

export default function PoolFastMatrix() {
  const [pool, setPool] = useState({})
  const [show, setShow] = useState(false)
  const [check, setCheck] = useState(false)

  const doGetPoolFastMatrix = () => {
    getPoolFastMatrix()
      .then(result => {
        if (result.status) setPool(result.pool)
        else toast.error(result.error)
        setShow(true)
      })
      .catch(error => {
        console.error(error.response ? error.response.data : error)
        toast.error(error.response ? error.response.data : error)
        setShow(true)
      })
  }

  const editPool = async () => {
    setCheck(false)
    const success = await doEditPool(pool)
    if (success.status) {
      toast.success(i18n.t('success'))
      doGetPoolFastMatrix()
      setCheck(true)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setCheck(false)
    }, 3000)
    return () => clearTimeout()
  }, [check])

  useEffect(() => {
    doGetPoolFastMatrix()
  }, [])

  return (
    <div className="col-md-4 col-sm-6 mb-4">
      <div className="card border-0 shadow h-100">
        <div className="card-body">
          {!show ? (
            <LoaderPage />
          ) : (
            <div className="row d-block d-xl-flex align-items-center">
              <div className="col-12 d-flex">
                <div className="icon-shape icon-shape-info rounded me-4 me-sm-0">
                  <i className="fa fa-project-diagram"></i>
                </div>
                <div className="ms-3">
                  <h2 className="h5">{`${i18n.t('pool')}`}</h2>
                  <h5 className="fw-extrabold">
                    <small>{i18n.t('amount')}: </small>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control fs-5 text-black"
                        value={pool.amount}
                        onChange={e => setPool({ ...pool, amount: e.target.value })}
                      />
                      <button className="btn btn-primary" onClick={editPool}>
                        <i className="fa fa-edit me-2"></i>
                        {i18n.t('edit')}
                      </button>
                      {check && <i className="fa fa-check text-success ms-3 fs-3 p-0 my-0"></i>}
                    </div>
                  </h5>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
