/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
import { doVerifyNick, saveUser } from '../../../services/UsersService'
// import SwitchInput from '../../../components/SwitchInput/SwitchInput'
import { i18n } from '../../../utils/translates/i18n'

/**
 * props:
 * - data
 * - onSubmit
 */
function UserModal(props) {
  const DEFAULT_USER = {
    nick: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    limitId: 0,
    telegramChat: '',
    phone: '',
    isActive: false,
    photo: '',
    passwordSecurity: '',
    confirmPasswordSecurity: '',
    status: '',
    binaryQualify: false,
    activity: false,
    userGroupId: 1,
    releaseWithdrawals: false,
  }

  const [error, setError] = useState('')

  const [user, setUser] = useState(DEFAULT_USER)
  const [walletBtc, setWalletBtc] = useState('')
  const [walletUsdt, setWalletUsdt] = useState('')
  const [nick, setNick] = useState('')
  const [doAlert, setDoAlert] = useState({ show: false, bg: '', msg: '' })

  const btnClose = useRef('')
  const btnSave = useRef('')

  const checkPassword = (id, pass, confirm) => {
    if (confirm !== pass) {
      document.getElementById(id).classList.add('is-invalid')
      return false
    } else {
      document.getElementById(id).classList.remove('is-invalid')
      return true
    }
  }

  const checkPasswordSecurity = (id, pass, confirm) => {
    if (confirm !== pass) {
      return false
    } else {
      return true
    }
  }

  function onSubmit(event) {
    let validate = true
    if (user.password && !checkPassword('confirmPassword', user.password, user.confirmPassword)) {
      validate = false
    }
    if (
      user.passwordSecurity &&
      !checkPassword('confirmPasswordSecurity', user.passwordSecurity, user.confirmPasswordSecurity)
    ) {
      validate = false
    }
    if (validate) {
      const post = {
        ...user,
        wallets: { btc: walletBtc, usdt: walletUsdt },
      }
      saveUser(user.id, post)
        .then(result => {
          btnClose.current.click()
          if (props.onSubmit) props.onSubmit(result)
        })
        .catch(err => {
          console.error(err.response ? err.response.data : err.message)
          setError(err.response ? err.response.data : err.message)
        })
    }
  }

  const changeNick = async () => {
    const success = await doVerifyNick(nick)
    if (!success.status) {
      setNick(props.data.nick)
      setDoAlert({ show: true, bg: 'danger', msg: i18n.t(success.msg) })
    } else {
      setUser(p => ({ ...p, nick }))
      setDoAlert({ show: false, bg: '', msg: '' })
    }
  }

  function onInputChange(event) {
    setUser(prevState => ({ ...prevState, [event.target.id]: event.target.value }))
  }

  useEffect(() => {
    setUser({
      ...DEFAULT_USER,
      ...props.data,
      name: props.data.fullName,
      status: props.data.status?.id,
      binaryQualify: props.data.binaryQualify?.status,
      userGroupId: props.data.group?.id,
    })
    setNick(props.data.nick)
    if (!!props.data?.wallets?.length) {
      setWalletBtc(props.data.wallets.filter(f => f.cryptoSymbol === 'btc')[0]?.wallet)
      setWalletUsdt(props.data.wallets.filter(f => f.cryptoSymbol === 'usdt')[0]?.wallet)
    }
  }, [props.data.id])

  useEffect(() => {
    const modal = document.getElementById('modalUser')
    modal.addEventListener('hidden.bs.modal', event => {
      setUser({ ...DEFAULT_USER })
    })
  }, [])

  const t = sessionStorage.getItem('t') === 'true'

  return (
    <div
      className="modal fade"
      id="modalUser"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {i18n.t('edit')} {i18n.t('user')}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            {doAlert.show && <div className={`alert alert-${doAlert.bg}`}>{doAlert.msg}</div>}
            <div className="form-group">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <img
                      src={
                        user.photo && user.photo !== 'user.png'
                          ? `${process.env.REACT_APP_API_URL}/images/${user.photo}`
                          : '/img/user.png'
                      }
                      crossOrigin="anonymous"
                      alt="user"
                      className={`img-thumbnail img-fluid shadow`}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md mb-3">
                      <div className="form-group">
                        <label htmlFor="nick">{i18n.t('nick')}:</label>
                        <input
                          type="text"
                          id="nick"
                          className="form-control"
                          onChange={e => setNick(e.target.value)}
                          onBlur={changeNick}
                          value={nick}
                          placeholder="nick"
                        />
                      </div>
                    </div>
                    {!t && ['9', '10', '12'].includes(user.userGroupId?.toString()) && (
                      <div className="col-md mb-3">
                        <div className="form-group">
                          <label htmlFor="type">{i18n.t('type')}:</label>
                          <select
                            id="userGroupId"
                            className="form-select"
                            value={user.userGroupId}
                            onChange={onInputChange}>
                            <option value="9">{i18n.t('voucher_pendent')}</option>
                            <option value="10">{i18n.t('leader_pendent')}</option>
                            <option value="12">{i18n.t('leader_approved')}</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="form-group">
                        <label htmlFor="name">{i18n.t('name')}:</label>
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          onChange={onInputChange}
                          value={user.name || ''}
                          placeholder="User's full name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8 mb-3">
                      <div className="form-group">
                        <label htmlFor="email">{i18n.t('email')}:</label>
                        <input
                          type="text"
                          id="email"
                          className="form-control"
                          onChange={onInputChange}
                          value={user.email || ''}
                          placeholder="user@email.com"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label htmlFor="phone">{i18n.t('phone')}:</label>
                        <input
                          type="text"
                          id="phone"
                          className="form-control"
                          onChange={onInputChange}
                          value={user.phone || ''}
                          placeholder="+5551123456789"
                        />
                      </div>
                    </div>
                  </div>
								</div>
								<div className='col-md-12'>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div>
                        <label>{i18n.t('new_password')}</label>
                        <input
                          className="form-control"
                          id="password"
                          type="password"
                          placeholder={i18n.t('new_password')}
                          value={user.password || ''}
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>{i18n.t('confirm_new_password')}</label>
                        <input
                          className="form-control"
                          id="confirmPassword"
                          type="password"
                          placeholder={i18n.t('confirm_new_password')}
                          value={user.confirmPassword || ''}
                          onChange={onInputChange}
                          onBlur={e => checkPassword(e.target.id, user.password, e.target.value)}
                        />
                        <div className="invalid-feedback bg-danger p-1 mt-0">{i18n.t('error_confirm_password')}</div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div>
                        <label>{i18n.t('new_password_security')}</label>
                        <input
                          className="form-control"
                          id="passwordSecurity"
                          type="password"
                          placeholder={i18n.t('new_password_security')}
                          value={user.passwordSecurity || ''}
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label>{i18n.t('confirm_new_password_security')}</label>
                        <input
                          className="form-control"
                          id="confirmPasswordSecurity"
                          type="password"
                          placeholder={i18n.t('confirm_new_password_security')}
                          value={user.confirmPasswordSecurity || ''}
                          onChange={onInputChange}
                          onBlur={e => checkPasswordSecurity(e.target.id, user.passwordSecurity, e.target.value)}
                        />
                        <div className="invalid-feedback bg-danger p-1 mt-0">{i18n.t('error_confirm_password')}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!t && (
                <div className="card">
                  <div className="card-header bg-light py-2">{i18n.t('wallets').toUpperCase()}:</div>
                  <div className="card-body p-3 pb-0">
                    <div className="row">
                      {/*  <div className="col-md-6 mb-3">
                        <div className="input-group">
                          <span className="btn btn-warning">{i18n.t('btc').toUpperCase()}:</span>
                          <input
                            type="text"
														id='wBtc'
														className="form-control"
                            onChange={e => setWalletBtc(e.target.value)}
                            value={walletBtc}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12 mb-3">
                        <div className="input-group">
                          <span className="btn btn-warning">{i18n.t('usdt').toUpperCase()}:</span>
                          <input
                            type="text"
                            id="wUsdt"
                            className="form-control"
                            onChange={e => setWalletUsdt(e.target.value)}
                            value={walletUsdt}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* 
              <div className="row">
								<div className="col-md-3 mb-3">
                  <div className="form-group">
                    <SwitchInput
                      id="activity"
                      text={`${i18n.t('user_active')}?`}
                      onChange={onInputChange}
                      isChecked={user.activity || false}
                    />
                  </div>
                </div> */}
              {/* <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <SwitchInput
                      id="binaryQualify"
                      text={`${i18n.t('qualify_binary')}?`}
                      onChange={onInputChange}
                      isChecked={user.binaryQualify || false}
                    />
                  </div>
                </div> */}

              {/* <div className="col-md-3 mb-3">
                  <div className="form-group">
                    <SwitchInput
                      id="releaseWithdrawals"
                      text={`${i18n.t('release_withdrawals')}?`}
                      onChange={onInputChange}
                      isChecked={user.releaseWithdrawals || false}
                    />
                  </div>
                </div> 
              </div>
								*/}
            </div>
            {/* <pre>{JSON.stringify(user.group?.id, null, 2)}</pre> */}
          </div>
          <div className="modal-footer">
            {error && <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>}
            <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
              {i18n.t('save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserModal
