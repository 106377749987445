const messages = {
  es: {
    translations: {
      a_crase: 'El',
      about: 'Sobre nosotros',
      above_the_same: 'Exactamente lo mismo de arriba',
      action: 'Acción',
      actionTemplates: 'Modelos de acción',
      activate_date: 'Fecha de activacion',
      active_automations: 'Automatizaciones activas',
      active_connections: 'Conexiones activas',
      active_monitors: 'Monitores activos',
      active_users: 'Donantes activos',
      ad: 'Anuncio',
      add: 'Adhesión',
      add_option: 'Agregar opción',
      add_payment: 'Agregar pago',
      add_question: 'Agregar pregunta',
      add_receipt: 'Agregar recibo',
      add_wallet: 'Agregar billetera',
      address: 'DIRECCIÓN',
      admin: 'Administración',
      advance: 'Avance',
      affected: 'Alcanzó',
      after: 'Después',
      air_transport: 'Transporte aereo',
      ajust_limite: 'Ajuste de límite',
      ajust_saldo: 'Ajuste de equilibrio',
      alert: 'Alerta',
      alerts: 'Alertas',
      all: 'Todo',
      all_departments: 'Todos los departamentos',
      allies_of: 'Aliados de',
      allRightsReserved: 'Todos los derechos reservados.',
      almost_there: 'Casi allí',
      already_offset: 'ya compensé',
      already_registered: '¿Ya registrado? Haz clic aquí y entra',
      amount: 'Valor',
      amount_above_balance: 'Fondos insuficientes',
      and: 'Es',
      and_more: 'y más',
      answer: 'Respuesta',
      ao: 'hacia',
      approve: 'Aprobar',
      April: 'Abril',
      asc: 'Ascendente',
      at: 'en',
      August: 'Agosto',
      automations: 'Automatizaciones',
      awaiting_email_confirmation: '¡Esperando confirmación por correo electrónico!',
      b_order: 'Orden',
      b_orders: 'Pedidos',
      back: 'para volver',
      bairroInteresse: 'Vecindario',
      balance: 'Balance',
      balance_available: 'Saldo de donación',
      banned: 'Prohibido',
      before_contact: 'Antes de contactarnos',
      binary: 'Binario',
      birth: 'Nacimiento',
      blind_man: 'invertido',
      body_monthly:
        'Su Cuota Mensual ha vencido y su BOT TRADER ha sido desactivado, por favor pague la Orden de Pago y disfrute de sus beneficios nuevamente',
      bonus_saldo_trader: 'Bonificación de saldo del comerciante',
      book_health: 'LibroSalud',
      but_possible_register: 'Aún es posible registrarse, pero no se le vinculará con ningún amigo recomendado.',
      buy_volume: 'Volumen de compra',
      buyer: 'Comprador',
      by: 'por',
      bye: 'Hasta luego',
      call: 'Llamado',
      call_to: 'Llamar a',
      calls: 'Llamados',
      cancel: 'Cancelar',
      canceled: 'Cancelado',
      career_plan: 'Plano de carrera',
      casa_verde_amarela: 'Casa verde amarilla',
      cash_payment: 'Pago en dinero',
      cash_payment_return: 'Devolución del pago en efectivo',
      cash_receipt: 'Recibo de caja',
      change_indicant: 'Indicador de cambio',
      change_photo: 'Cambiar foto',
      change_plan: 'Cambio de plan',
      check_payment: 'Pago en cheque',
      check_payment_return: 'Devolución de pago mediante cheque',
      check_receipt: 'Recibo por cheque',
      choose: 'Elegir',
      cidadeInteresse: 'Ciudad',
      city: 'Ciudad',
      claims: 'Reclamos',
      clean: 'Limpiar',
      click_and_know: 'Haz clic y descubre',
      click_to_copy_the_wallet: 'Haga clic para copiar billetera',
      client: 'Cliente',
      clients: 'Clientes',
      close: 'Cierre',
      cnh: 'CNH (Licencia)',
      cnhDate: 'Fecha de emisión de la CNH',
      cnpj: 'CNPJ',
      cnpj_invalid: 'CNPJ no válido',
      coin: 'Moneda',
      color: 'Color',
      commercial: 'Comercial',
      company: 'Nombre de la empresa',
      complement: 'Complementar',
      comporRenda: '¿Vas a ganar dinero con alguien?',
      compRenda: 'Tipo de prueba de ingresos',
      confirm_approve: 'Confirmar aprobación',
      confirm_delete: 'Confirmar la eliminación',
      confirm_new_password: 'Confirmar nueva contraseña',
      confirm_new_password_security: 'Confirmar nueva contraseña de seguridad',
      confirm_password: 'confirme la contraseña',
      confirm_password_security: 'Confirmar contraseña de seguridad',
      confirm_your_new_password: 'Ingrese su nueva contraseña nuevamente',
      confirmed: 'Confirmado',
      confirmed_email: 'Correo electrónico confirmado',
      congratulations: 'Felicidades',
      contact: 'Contacto',
      contract: 'Contrato',
      copied: 'Copiado',
      copy: 'Copiar',
      copy_success: '¡Copiado exitosamente!',
      correspondences: 'Correspondencia',
      countries: 'Países',
      country: 'País',
      cpf: 'CPF',
      cpf_invalid: 'CPF no válido',
      create_order_success: '¡Donación creada con éxito!',
      created: 'Registrado en',
      cred: 'Crédito',
      cred_binario: 'Crédito binario',
      cred_deb: 'Créditos/débitos',
      cred_game_donate: 'Juego Donar crédito',
      cred_ind_direta: 'Paso de crédito',
      cred_ind_indireta: 'Paso de crédito',
      cred_pagar_com_saldo: 'Crédito Donar con Saldo',
      cred_pontos: 'Puntos de crédito',
      cred_recb_trader: 'Comerciante receptor de crédito',
      cred_renovacao: 'Crédito de renovación',
      cred_saldo_disp: 'Crédito del saldo de la donación',
      cred_upgrade: 'Crédito de actualización',
      credit_card_payment: 'Pago con tarjeta de crédito',
      credit_card_payment_return: 'Devolución de pago con tarjeta de crédito',
      credit_card_receipt: 'recibo de tarjeta de crédito',
      current: 'Actual',
      current_password: 'Contraseña actual',
      current_wallet: 'Portafolio actual',
      dashboard: 'Panel de control',
      data_added: 'Datos agregados',
      data_saved: 'Datos guardados',
      datas: 'Datos',
      date: 'Fecha',
      date_first_installment: 'Fecha del primer pago',
      ddi: 'DDI',
      deb_de_trader: 'Deuda del comerciante',
      deb_game_donate: 'Juego Donar Débito',
      deb_saldo: 'Balance de débito',
      deb_saldo_disp: 'Débito del saldo de la donación',
      deb_saldo_pendent: 'Débito de saldo pendiente',
      deb_val_indev: 'Débito de monto indebido',
      debit_card_payment: 'Pago con tarjeta de débito',
      debit_card_payment_return: 'Devolución de pago con tarjeta de débito',
      debit_card_receipt: 'Recibo con tarjeta de débito',
      debt: 'Deuda',
      debt_td: 'Débito',
      December: 'Diciembre',
      decImpRenda: 'declarar el impuesto sobre la renta',
      deleted_document: 'El documento fue eliminado de su registro',
      department: 'Departamento',
      dependents: 'Dependientes',
      desc: 'Hacia abajo',
      description: 'Descripción',
      details: 'Detalles',
      dev_econom: 'Desarrollo economico',
      developer: 'Desarrollador',
      directly_indicated: 'Indicado directamente',
      directly_indicateds: 'Referido directamente',
      district: 'Barrio / distrito',
      divorced: 'Divorciado',
      doa_men_receb: 'Donación mensual de plataforma recibida',
      doacao_td: 'Donación',
      doctorate_complete: 'Doctorado - Completo',
      doctorate_incomplete: 'Doctorado - Incompleto',
      document: 'Documento',
      document_already_registered: 'Documento ya registrado',
      documents: 'Documentos',
      donate: 'Donar',
      donor: 'Donante',
      dont_know_zip: '¿No sabes el código postal? Haga clic aquí',
      dou_fe: 'Confirmo que la información anterior es correcta.',
      downloads: 'Descargar archivos',
      drop_images: 'Haz clic o arrastra las imágenes aquí.',
      due_date: 'Madurez',
      edit: 'Para editar',
      edit_option: 'Opción de edición',
      edit_question: 'Editar pregunta',
      email: 'Correo electrónico',
      email_already_registered: 'correo electrónico ya registrado',
      email_and_or_password_wrong: 'Correo electrónico y/o contraseña incorrectos!',
      email_for_login: 'Este correo electrónico será su inicio de sesión',
      email_or_password_wrong: 'Correo o contraseña incorrectos',
      employee: 'Empleado',
      employees: 'Empleados',
      empty: 'Vacío',
      enter_your_new_password: 'Introduzca su nueva contraseña',
      entrada: '¿Tiene un valor de entrada? En caso afirmativo, ¿qué valor?',
      entries_until_today: 'Entradas hasta la fecha',
      error_confirm_password: 'La confirmación y la nueva contraseña deben ser iguales.',
      error_email: 'Formato de correo inválido',
      error_password: 'Contraseña incorrecta',
      error_phone: 'Numero de telefono invalido',
      estadoInteresse: 'estado',
      estimate: 'Estimado',
      exchange: 'Reemplazo',
      expired: 'Venció',
      extorno_td: 'Inversión',
      extra: 'Extra',
      extract: 'Movimiento',
      extracts: 'Movimientos',
      fantasy: 'Fantasía',
      fatherName: 'Nombre del Padre',
      February: 'Febrero',
      fee: 'Cargo por retiro',
      feedback: 'Devolver',
      feminine: 'Femenino',
      fgts: 'Valor de su FGTS',
      field_required: 'Finalización obligatoria',
      final: 'Final',
      finance: 'Financiero',
      financial: 'Financiero',
      financial_payment: 'Pago financiero',
      financial_payment_return: 'Devolución del pago financiero',
      financial_receipt: 'Recibo de financiación',
      financing: 'Financiación',
      financings: 'Financiación',
      first_10_pages: 'Primeras 10 páginas',
      fisica: 'Persona física',
      for_year: 'Por año',
      forget_password: 'Olvidé la contraseña',
      forgot_password: 'Olvido la contraseña',
      form: 'Molde',
      form_of_payment: 'Forma de pago',
      found: 'Encontró',
      founds: 'Encontró',
      free: 'Gratis',
      friend_indicated: 'Recomendado por mis amigos',
      friend_indicateds: 'Recomendado por mis amigos',
      fuel: 'Combustible',
      full_name: 'Nombre completo',
      funcPublico: 'es un servidor publico',
      fundamental_complete: 'Fundamental - Completo',
      fundamental_incomplete: 'Fundamental - Incompleto',
      ganh_diario: 'Ganancias diarias',
      gender: 'Género',

      grand_total: 'Total general',
      group: 'Grupo',
      has_withdraw_today: 'Ya se ha solicitado un retiro hoy',
      high: 'Alto',
      home: 'Comenzar',
      how_create_binance_apikey: 'Cómo crear una clave API en Binance',
      how_it_works: 'Como funciona',
      how_to_call: '¿Cómo quieres que te llamen?',
      id_td: 'Paso de crédito',
      images: 'Imágenes',
      in_construction: 'En construcción',
      in_order: 'En orden',
      in_stock: 'En stock',
      indicant: 'Indicador',
      indicated_by: 'Estás siendo referido por',
      indicated_not_found: 'El Referidor indicado no fue encontrado...',
      indicateds: 'Amigos',
      indicated: 'Amigo',
      indirects: 'Amigos',
      info: 'Boletines',
      info_contact: 'Información del contacto',
      initial: 'Hogar',
      insert_new: 'Ingresar nuevo',
      installments: 'Cuotas',
      intelligence: 'Inteligencia',
      interesse: '¿Cuál es la región de interés?',
      invalid_contact: 'Formato de contacto no válido',
      invalid_images: 'Imágenes no válidas',
      invalid_token: 'simbolo no valido',
      invite: 'Invitar',
      invite_friends: 'Invita a tus amigos',
      January: 'Enero',
      July: 'Julio',
      June: 'Junio',
      juridica: 'Persona jurídica',
      know_exact_result: '¿Sabes el valor exacto?',
      lead: 'Juntos estaremos construyendo un nuevo futuro.',
      learn_more: 'Sepa mas',
      left: 'Izquierda',
      left_preferential: 'Prefiriendo el lado izquierdo',
      legalNature: 'Naturaleza jurídica',
      level: 'Nivel',
      limite_80: 'Has alcanzado el {{perc}} de tu límite.\n Realiza una mejora y evita la suspensión de tus ingresos.',
      limite_excedido: 'Límite de débito excedido',
      linear: 'Lineal',
      link_email: 'Haga clic en el enlace enviado al correo electrónico registrado.',
      link_invite_copy: 'Compartir enlace copiado correctamente',
      liquid: 'Líquido',
      list: 'Lista',
      loading: 'Cargando',
      location: 'Ubicación',
      locked: 'Obstruido',
      login: 'Entrar',
      logout: 'Salir',
      low: 'Bajo',
      male: 'Masculino',
      manage_users: 'Gestionar donantes',
      manager: 'Gerente',
      manufacturer: 'Fabricante',
      March: 'Marzo',
      maritalStatus: 'Estado civil',
      market: 'Mercado',
      married: 'Casado',
      master_complete: 'Maestría - Completa',
      master_incomplete: 'Maestría - Incompleta',
      max_payment: 'Pago máximo',
      May: 'Puede',
      message: 'Mensaje',
      messageNotSent: 'No fue posible enviar tu mensaje',
      messageSent: 'Su mensaje ha sido enviado con éxito',
      middle_complete: 'Medio - Completo',
      middle_incomplete: 'Medio - Incompleto',
      min_amount_error: 'El valor debe ser al menos:',
      min_withdraw: 'Monto mínimo de retiro',
      missing: 'Desaparecido',
      mission: 'Misión',
      mission_vision_values: 'Misión, visión y valores',
      model: 'Modelo',
      monitors: 'Monitores',
      monthly: 'Mensualidad',
      motherName: 'Nombre de la madre',
      movements: 'Movimientos',
      msg_renovacao: 'Has alcanzado tu límite de ganancias, renueva tu paquete.',
      my_account: 'Mi cuenta',
      my_wallet: 'Mi billetera',
      mystock: 'Mi acción',
      name: 'Nombre',
      nascMaisVelhoRenda2: '¿Cuál es la fecha de nacimiento del mayor?',
      nationality: 'Nacionalidad (País de nacimiento)',
      naturalness: 'Lugar de nacimiento (Ciudad de nacimiento)',
      nature: 'Naturaleza',
      network: 'Comunidad',
      new: 'Nuevo',
      new_call: 'Nueva convocatoria',
      new_financing: 'Nueva Financiación',
      new_order: 'Nueva orden',
      new_password: 'Nueva contraseña',
      new_password_security: 'Nueva contraseña de seguridad',
      new_seller: 'Nuevo vendedor',
      new_wallet: 'Nueva billetera',
      news: 'Noticias',
      next: 'Próximo',
      nick: 'Donante (iniciar sesión)',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS ya registrado',
      no: 'No',
      no_approve: 'Aún no aprobado',
      no_delete: 'No borres',
      no_literate: 'No alfabetizado',
      no_repeat: 'Los datos no se pueden repetir',
      no_results_found: 'Ningún resultado encontrado',
      no_services_or_products_added: 'No se agregaron servicios ni productos',
      no_settings: 'Ve a Configuración y completa tus datos.',
      none_friends_indicated: 'Aún no he recomendado a ningún amigo',
      not_authorized: 'No autorizado',
      not_found: 'No encontrado',
      not_login: 'No se puede iniciar sesión, verifique sus datos e inténtelo nuevamente.',
      not_orders: 'Sin órdenes de pago',
      not_permission: 'Usted no tiene permiso para acceder a esta página',
      not_registered: '¿Todavía no estas registrado? Haga clic aquí y regístrese',
      not_results: 'No se encontraron registros',
      not_self_register: 'No se puede registrar',
      not_today_entry: 'Todavía no hemos tenido entradas hoy.',
      notfound_ad: 'No se encontraron anuncios',
      November: 'Noviembre',
      number: 'Número',
      obs: 'Comentarios',
      obs_pay:
        'Si su corredor debita tarifas del monto enviado, deberá realizar la corrección manualmente sumando los montos de estas tarifas.',
      October: 'Octubre',
      of: 'en',
      off: 'aniquilado',
      on_total: 'Del total',
      open: 'Abierto',
      opening: 'Apertura',
      operation: 'Actuación',
      ordenation: 'Realizar pedidos',
      order: 'Donación',
      order_book: 'Libro de pedidos',
      order_not_alowed_to_pay: 'Donación no autorizada para Donar con saldo',
      order_not_found: 'Donación no encontrada',
      order_payed: 'Donación #{{id}} pagada exitosamente',
      orders: 'Donaciones',
      other: 'Otro',
      other_services_or_products: 'Otros servicios o productos',
      paid: 'Pagado',
      paid_date: 'dia de pago',
      partial: 'Parcialmente pagado',
      passport: 'Pasaporte',
      password: 'Contraseña',
      password_security: 'Contraseña de seguridad',
      pay: 'Donar',
      pay_exact: 'Paga el importe en {{coin}}',
      pay_generate: 'Generar pago',
      pay_selected: 'Donar Seleccionado',
      pay_value_in: 'Pagar el importe en',
      pay_with_balance: 'Donar con Saldo',
      payable: 'Donar',
      payer: 'Pagador',
      payment: 'Pago',
      ped_retirada: 'Donación de retiro',
      ped_retirada_gan_diario: 'Donación de retiro de ganancias diarias',
      pendent: 'Pendiente',
      people: 'Gente',
      person: 'Persona',
      person_found_in_other_prefecture: 'Persona ya empadronada en otro municipio',
      personal: 'Tipo',
      personal_addresses: 'Direcciones',
      personal_contacts: 'Contactos',
      personal_data: 'Datos personales',
      personal_infos: 'Información',
      personal_pcva: 'Programa Casa Verde y Amarilla',
      phone: 'Teléfono',
      photo: 'Fotografía',
      pix: 'FOTO',
      pix_payment: 'Pago en foto',
      pix_payment_return: 'Devolución de pago en PIX',
      pix_receipt: 'Recibir en PIX',
      pl_carreira: 'Plano de carrera',
      plan: 'Departamento',
      plates: 'Platos',
      please_wait: 'Espere por favor',
      plus: 'Más',
      point: 'Punto',
      points: 'Puntos',
      portfolio: 'portafolio',
      pos_complete: 'Lato sensu - Completo',
      pos_incomplete: 'Lato sensu - Incompleto',
      prefecture: 'Ayuntamiento',
      prefecture_addresses: 'Direcciones',
      prefecture_contacts: 'Contactos',
      prefecture_data: 'Datos del Ayuntamiento',
      prefecture_infos: 'Información',
      prefectures: 'Ayuntamientos',
      prev: 'Anterior',
      price: 'Precio',
      print: 'Imprimir',
      privacy_policies: 'Políticas de privacidad',
      private_works: 'Obras Privadas',
      profession: 'Profesión',
      profile: 'Perfil',
      program: 'Programa',
      programs: 'Software',
      project: 'Proyecto',
      projects: 'Proyectos',
      public_works: 'Obras Públicas',
      purchase: 'Compra',
      qualify_binary: 'Calificación binaria',
      qualquer: 'Cualquier',
      quantity: 'Cantidad',
      quotation: 'Precio',
      read: 'Leer',
      receivable: 'Para recibir',
      recipient: 'Receptor',
      recommendations: 'Recomendaciones',
      redefine_password: 'Redefinir contraseña',
      register: 'Registro',
      register_people: 'Registro de Personas',
      register_wallet: 'Registre una billetera para realizar retiros',
      registers: 'Registros',
      registers_users: 'Registros de donantes',
      reject: 'Rechazar',
      remaining: 'Restante',
      remove: 'Para eliminar',
      rendaIndividual: 'Ingreso bruto individual',
      renew: 'Renovar',
      reply: 'Responder',
      reports: 'Informes',
      required_data_not_sent: 'Datos requeridos no enviados',
      resend_email: 'Reenvíe el correo electrónico',
      residential: 'Residencial',
      responded: 'Contestada',
      restricao: '¿Tiene restricciones sobre su nombre? (SPC/Serasa)',
      result: 'Resultado',
      results: 'Resultados',
      resume_data: 'Resumen de datos',
      revoked: 'Derogado',
      rg: 'Registro General (Identidad)',
      rgAgency: 'emisor RG',
      rgDate: 'Fecha de emisión de RG',
      rgUf: 'Estado de RG',
      right: 'Bien',
      right_preferential: 'Prefiriendo el lado derecho',
      roi: 'Ganancias diarias',
      sale: 'Venta',
      saq_duplicado: 'Retiro duplicado',
      save: 'Ahorrar',
      schooling: 'Educación',
      search: 'Buscar',
      secretary: 'Secretario',

      security_password: 'Contraseña de seguridad',
      see: 'Para ver',
      see_advents: 'ver los anuncios',
      see_call: 'Ver convocatoria',
      see_details: 'Ver detalles',
      see_details_wallet: 'Ver detalles de la billetera',
      see_financing: 'Ver financiación',
      see_order: 'Ver donación',
      see_user: 'Ver donantes',
      select_vehicle: 'Seleccionar vehículo',
      self_register: 'Registro',
      sell_volume: 'El volumen de ventas',
      seller: 'Vendedor',
      send_call: 'Enviar llamada',
      send_to: 'Enviar para',
      September: 'Septiembre',
      settings: 'ajustes',
      share_your_link: 'Comparte tu enlace',
      show: 'Mostrar hasta',
      show_from: 'Mostrando desde',
      side: 'Lado',
      sign_in_to_start_your_session: 'Inicia sesión para iniciar tu sesión',
      sign_up: 'Registro',
      single: 'Soltero',
      sold: 'Vendido',
      state: 'estado',
      status: 'Estado',
      strategies: 'Estrategias',
      street: 'Camino',
      subject: 'Sujeto',
      submit: 'Mandar',
      submit_placeholder: 'Por favor complete su correo electrónico',
      subscribe: 'Suscríbete para recibir nuestras novedades',
      subtitle: 'Subtítulo',
      success_update_user: '¡Donante actualizado exitosamente!',
      success_wallet: 'Portafolio actualizado exitosamente!',
      suggestions: 'Sugerencias',
      superior_complete: 'Superior completo',
      superior_incomplete: 'Superior incompleta',
      supervisor: 'Supervisor',
      support: 'Soporte',
      support_materials: 'Materiales de apoyo',
      suspended: 'Suspendido',
      symbol: 'Símbolo',
      symbols: 'Símbolos',
      system: 'Sistema',
      target: 'Meta',
      telegram_chat_id: 'ID de chat de Telegram',
      tempoCarteira: '¿Cuánto tiempo lleva registrado en su cartera?',
      terms: 'Acepto los términos de uso',
      text_hint_binance:
        'Al crear la clave API en Binance, ingrese la IP en las restricciones de acceso:\n172.105.78.139\nLuego seleccione las opciones:\n• HABILITAR OPCIONES EUROPEAS\n• HABILITAR COMERCIO PUNTUAL Y DE MARGEN',
      this_value_in: 'Este valor en',
      ticker_health: 'TickerSalud',
      title: 'Título',
      to: 'Hasta',
      to_pay: 'Para donar',
      to_receive: 'A recibir',
      today_entry: 'La entrada de hoy',
      token_not_found_or_already_used: 'Token no encontrado o ya utilizado',
      total: 'Total',
      total_entry: 'Entrada total',
      traderbot: 'Comerciante de robots',
      transf_credito: 'Transferencia de credito',
      transf_debito: 'Transferencia de deuda',
      transfer_payment: 'Pago por transferencia',
      transfer_payment_return: 'Devolución de pago mediante transferencia',
      transfer_receipt: 'Recibo en transferencia',
      transparency: 'Transparencia',
      tx_adm_cred: 'Administrar impuestos',
      tx_adm_deb: 'Administrar impuestos',
      tx_retirada: 'Cargo por retiro',
      tx_retirada_gan_diario: 'Tarifa de retiro de ganancias diarias',
      type: 'Tipo',
      update: 'Actualizar',
      update_wallet: 'Actualizar billetera',
      upgrade: 'Mejora',
      used: 'Usado',
      user: 'Donante',
      user_active: 'Donante activo',
      user_already_exists: '¡El donante ya existe!',
      user_and_or_password_wrong: '¡Donante y/o contraseña incorrectos!',
      user_inactive: 'Donante inactivo',
      user_not_found: 'Donante no encontrado',
      user_not_logged: 'El donante no ha iniciado sesión',
      user_or_password_wrong: 'Donante o contraseña incorrecta',
      users: 'Donantes',
      users_list: 'Lista de donantes',
      valid_images: 'Imágenes válidas',
      valorRenda2: '¿Cuál es el valor del segundo ingreso?',
      value: 'Valor',
      value_donate_on: 'Valor de la donación en',
      value_financed: 'Monto financiado',
      value_installment: 'Valor de la cuota',
      values: 'Valores',
      vehicle: 'Vehículo',
      vehicles: 'Vehículos',
      view_order: 'Ver pedido',
      vision: 'Visión',
      visitor: 'Visitante',
      voucher: 'Vale',
      wait_confirm: 'Esperando confirmacion',
      waiting: 'Espera',
      waiting_email: 'El registro fue exitoso, ahora todo lo que necesitas hacer es confirmar tu correo electrónico.',
      register_ok: 'El registro se completó con éxito.',
      waiting_payment: 'Esperando hacer una donación',
      wallet: 'portafolio',
      wallet_generate: 'Generar Portafolio',
      wallets: 'Carteras',
      want_donate: 'quiero donar',
      want_now_more_about: 'quiero saber más sobre',
      warning: 'ATENCIÓN',
      we_are: 'Son',
      webhooks: 'Ganchos web',
      welcome: 'Sea bienvenido',
      who_we_are: 'Quienes somos',
      who_we_are_subtitle: 'Una breve descripción de la',
      widower: 'Viudo',
      will_expire_in: 'caducará en',
      with: 'con',
      withdraw: 'Retiro',
      withdraw_sended_email:
        '¡Retiro solicitado, revise su correo electrónico y haga clic en el enlace para autorizar!',
      withdrawal: 'Retiros',
      withdrawals: 'Retiros',
      year: 'Año',
      years: 'Años',
      yes: 'Sí',
      you_have: 'Tú tienes',
      your_country: 'Su país',
      your_dashboard: 'Este es tu Panel de Control.',
      your_link: 'Su enlace',
      zipcode: 'Código postal',
      withdrawal_sended: 'Retiro enviado',
      withdrawal_pending: '¡Retiro ya solicitado! \nEspere el pago para realizar otro retiro.',
      limite_80_top: 'Has alcanzado {{perc}} tu límite.\n Anticipa tu renovación y evita suspender tus ingresos.',
      game: 'Juego',
      games: 'Juegos',
      winning_numbers: 'Números sorteados',
      your_numbers: 'Tus números',
      add_numbers: 'Agregar números',
      next_draw: 'Próximo sorteo',
      can_bet_until: 'Puedes apostar hasta',
      your_bets: 'Tus apuestas',
      add_bet: 'Agregar apuesta',
      bet: 'Apuesta',
      bets: 'Apuestas',
      numbers: 'Números',
      special_numbers: 'Números especiales',
      resume: 'Resumen',
      choosed_numbers: 'Números elegidos',
      choosed_special_numbers: 'Números especiales elegidos',
      bets_finished: 'Apuestas resueltas',
      prize_draw: 'Sorteo de premios',
      balance_network: 'Saldo comunitario',
      telegram: 'Telegrama',
      logs: 'Registros',
      release_withdrawals: 'Liberar retiros',
      bot_active: 'Bot de lanzamiento',
      available: 'Disponible',
      sunday: 'Domingo',
      monday: 'Segundo',
      tuesday: 'Tercero',
      wednesday: 'Cuatro',
      thursday: 'Quinto',
      friday: 'Viernes',
      saturday: 'Sábado',
      title_binary: 'Establecer porcentajes binarios para cada día',
      ok_perc_change: 'Los porcentajes cambiaron exitosamente',
      cred_deb_title: 'Saldo de crédito o débito',
      send: 'Mandar',
      voucher_pendent: 'Vale pendiente',
      leader_pendent: 'Líder pendiente',
      voucher_approved: 'Cupón aprobado',
      leader_approved: 'Líder aprobado',
      no_binary_at_today: 'No hay ningún Binario registrado ese día',
      generate_file: 'Generar archivo',
      withdrawals_file_created: 'Archivo de retiro creado',
      withdrawals_sended: 'Solicitudes de retiro enviadas',
      see_generated_files: 'Ver archivos generados',
      files: 'Archivos',
      file: 'Archivo',
      verify: 'Verificar',
      rescue: 'Rescate',
      awarded: 'Galardonado',
      rescued: 'Rescatada',
      repeat: 'Repetir',
      draw_date: 'Fecha del sorteo',
      bet_date: 'Fecha de apuesta',
      drawn_numbers: 'Números sorteados',
      awardeds_numbers: 'Números de premio',
      no_award: 'Sin premio',
      you_hit_number: 'Tienes {{qty}} número correcto',
      you_hit_numbers: 'Tienes {{qty}} números correctos',
      lottery: 'Lotería',
      lotteries: 'Loterías',
      balls: 'pelotas',
      special_balls: 'Especiales',
      levy: 'recopilación',
      major_awarded: 'mas bolas',
      hits: 'golpes',
      cred_mandala: 'Crédito de Star Matrix',
      renew_mandala: 'Renovación de la Star Matrix',
      mandala: 'Star Matrix',
      mandala2: 'Star Matrix 2.0',
      mandalas: 'Star Matrix',
      mandala_in: 'Participa en la Star Matrix',
      rifas: 'Star Matrix',
      rifa: 'Star Matrix',
      lascado: 'esta desconchado',
      estribado: 'esta revuelto',
      none: 'Ninguno',
      directs: 'Amigos',
      matuto: 'matuto',
      matutos: 'Matutos',
      cupom: 'Número de cupón en Star Matrix',
      your_position: 'Tu posición en la Star Matrix',
      closed: 'Cerrado',
      gang: 'Star Matrix',
      gangs: 'Star Matrix',
      influencer_required: 'Necesitas elegir un influencer',
      pendent_order: 'Tienes Donaciones Pendientes',
      influencer: 'Hombre de influencia',
      change_influencer: 'Intercambiar influenciador',
      no_influencer: 'Sin influenciador',
      msg_no_influencer: 'Aún no has elegido ningún Influencer',
      you_are_influencer: '¿Eres un Influencer? Haga clic aquí y regístrese',
      register_influencer: 'Registro de influencers',
      add_your_photo: 'Añade tu foto',

      hello: 'Hola',
      msg_add_order:
        'Gracias por registrarte con {{name}}.\nEstamos felices de tenerte como parte de nuestra comunidad.\n\nPara ver la transmisión y disfrutar de todos los beneficios, debes pagar la MEMBRESÍA.\nLa MEMBRESÍA es una- tarifa por tiempo que le brinda acceso a nuestro contenido exclusivo, soporte técnico, actualizaciones y mucho más.\n\nPara pagar su membresía, simplemente pague a través de PIX usando el código QR a continuación\no copiando la dirección justo debajo del código QR y pegándola en la aplicación de tu banco.\n\nLa confirmación es automática e inmediata (normalmente en 10 segundos).\n\n¡Y eso es todo! Después de eso podrás disfrutar de {{name}}.\n\nSi tienes alguna pregunta o problema, contáctanos por correo electrónico a {{email}}.\n\nSaludos cordiales',
      msg_mandala_order:
        'Estás a un paso de participar en nuestra ayuda mutua, Star Matrix.\n\nStar Matrix es una forma divertida y segura de ganar un buen dinero.\n\nPara participar solo necesitas hacer la Donación accediendo con su corredor y leyendo el código QR a continuación \n\nLa confirmación es automática pero puede tardar unos minutos.\n\nDespués de eso podrá disfrutar de {{name}}.\n\nSi tiene alguna pregunta o problema, por favor contacte a su nominador.\n\nAtentamente',
      msg_cota_solidaria_order:
        'Con Cuotas Solidarias contribuyes a nuestra labor social ayudando a familias necesitadas e incluso compites por un auto.\n\nPara pagar y adquirir la Cuota Solidaria solo realiza el pago vía PIX usando el Código QR a continuación\ni copiando la dirección a continuación del código QR y pégalo en la aplicación de tu banco.\n\nLa confirmación es automática e inmediata (generalmente en 10 segundos).\n\n¡Y eso es todo! Después de eso ya estarás participando de la Cuota Solidaria.\n\nSi tienes alguna duda o problema, por favor contáctanos a través de un Ticket.\n\nAtentamente',
      followers: 'Seguidores',
      required_fields: 'Campos obligatórios',
      your_influencer: 'Tu influenciador',
      cota_solidaria: 'Cuota Solidaria',
      cotas_solidarias: 'Cuotas Solidarias',
      add_cota_solidaria: 'Agregar Cuota Solidaria',
      add_cotas_solidarias: 'Sumar Cuotas Solidarias',
      coupon: 'Cupón',
      error_mandala_open: 'Ya hay una donación abierta de Star Matrix',
      error_cotaSolidaria_open: 'Ya tienes abierta una Donación de Cuota Solidaria',
      cotaSolidaria_not_created: 'No se creó Cuota Solidaria',
      success_mandala: 'Donación Star Matrix creada con éxito',
      success_cotaSolidaria: 'Cuota Solidaria creada con éxito',
      cotas_solidaria_desc:
        'Confirma que deseas adquirir {{qty}} Cuotas Solidarias al precio de {{price}} por un total de {{total}}',
      cota_solidaria_desc:
        'Confirma que deseas comprar {{qty}} Cuota Solidaria al precio de {{price}} por un total de {{total}}',
      builder: 'Constructor',
			top: 'Arriba',
			day: 'día',
      days: 'días',
      hour: 'hora',
      hours: 'horas',
      minute: 'minutos',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
      comming: 'En',
      comming_soon: 'Lanzamiento mundial en',
			net: 'Red',
			raffels: 'sorteos',
			new_raffle: 'Nuevo sorteo',
    },
  },
}
export { messages }
