// import React, { useState } from 'react'
import { i18n } from '../../utils/translates/i18n'
import { saveInfluencer } from '../../services/InfluencersService'

/**
 * props:
 * - data
 * - onEditClick
 */
export default function InfluencerRow({ influencer, editInfluencer }) {
  const token = sessionStorage.getItem('token')
  async function doSubmit(inf) {
    const success = await saveInfluencer(token, inf.id, { ...inf, status: 4 })
    if (success.status) window.location.reload()
  }
  return (
    <>
      <tr>
        <td className='p-1'>
          <img
            src={influencer?.img ? `${process.env.REACT_APP_API_URL}/images/${influencer?.img}` : '/img/user.png'}
            alt={influencer?.name}
						className="img-fluid rounded border border-2 border-dark"
						style={{ height: '80px', width: 'auto' }}
            crossOrigin="anonymous"
          />
        </td>
        <td>
          <a className={`btn-default btn-sm m-0 p-0`} href="#!">
            <span className="fw-bold">{influencer?.name?.toUpperCase()}</span>
            <p style={{ whiteSpace: 'pre-line' }}>
              <small>{influencer?.at}</small>
            </p>
          </a>
        </td>
        <td>
          <a className={`btn-default btn-sm m-0 p-0`} href="#!">
            <span className="fw-bold">{influencer?.nick}</span>
          </a>
        </td>
        <td>
          <a
            href={`https://www.instagram.com/${influencer?.instagram}`}
            className="btn btn-info btn-sm"
            target="_blank"
            rel="noreferrer">
            {influencer?.instagram}
          </a>
        </td>
        <td>{influencer?.followers}</td>
        <td>
          <span className={`btn btn-sm btn-${influencer?.statuses?.bg}`}>{i18n.t(influencer?.statuses.status)}</span>
        </td>
        <td>
          {influencer?.status === 1 && (
            <button type="button" className={`btn btn-success btn-sm me-2`} onClick={() => doSubmit(influencer)}>
              <i className="fa fa-check me-2"></i> {i18n.t('Aprovar')}
            </button>
          )}
          <button
            type="button"
            className={`btn btn-secondary btn-sm`}
            title="Edit this Influencer"
            data-bs-toggle="modal"
            data-bs-target="#modalInfluencer"
            onClick={() => editInfluencer(influencer)}>
            <i className="fa fa-edit me-2"></i> {i18n.t('edit')}
          </button>
        </td>
      </tr>
      {/* <pre>{JSON.stringify(influencer, null, 2)}</pre> */}
    </>
  )
}
