/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { getWithdrawalsFiles } from '../../services/WithdrawalsService'
import { i18n } from '../../utils/translates/i18n'
import { FormateDate } from '../../utils/CustomFormatations'

export default function WithdrawalsFiles() {
  const defaultLocation = useLocation()
  const history = useHistory()

  const [withdrawalsFiles, setWithdrawalsFiles] = useState([])
  const [show, setShow] = useState(false)

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)

  const doGetWithdrawalsFiles = async () => {
    const success = await getWithdrawalsFiles(token, page, lim)
    if (success.status) {
      setWithdrawalsFiles(success.list.rows)
      setCount(success.list.count)
    }
    setShow(true)
  }

  useEffect(() => {
    doGetWithdrawalsFiles()
  }, [token, page, lim])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const t = sessionStorage.getItem('t') === 'true'
  return (
    !t && (
      <React.Fragment>
        <Menu />
        <main className="content">
          {!show ? (
            <>{i18n.t('please_waiting')}</>
          ) : (
            <>
              <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <h2 className="h4">{i18n.t('withdrawals')}</h2>
                  </div>
                </div>
                <Pagination count={count} pageSize={lim}>
                  <div className="btn btn-primary me-3">{i18n.t('see_generated_files')}</div>
                  <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
                    <option className="bg-light" value="5">
                      5 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="10">
                      10 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="25">
                      25 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="50">
                      50 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="100">
                      100 {i18n.t('registers')}
                    </option>
                  </select>
                </Pagination>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                  {/* <pre>{JSON.stringify(editWithdraw)}</pre> */}
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="border-gray-200">{i18n.t('date')}</th>
                        <th className="border-gray-200">{i18n.t('file')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawalsFiles?.map((w, i) => (
                        <tr key={i}>
                          <td>{FormateDate(w.createdAt, false, true)}</td>
                          <td>
                            <a
                              href={`${process.env.REACT_APP_API_URL}/withdrawals_files/${w.file}`}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="btn btn-primary">
                              {w.file}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </main>
      </React.Fragment>
    )
  )
}
