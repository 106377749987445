const messages = {
  ru: {
    translations: {
      a_crase: '',
      about: 'О нас',
      above_the_same: 'Точно то же самое, что и выше',
      action: 'Действие',
      actionTemplates: 'Модели действий',
      activate_date: 'Дата активации',
      active_automations: 'Активная автоматизация',
      active_connections: 'Активные соединения',
      active_monitors: 'Активные мониторы',
      active_users: 'Активные доноры',
      ad: 'Объявление',
      add: 'Присоединение',
      add_option: 'Добавить вариант',
      add_payment: 'Добавить платеж',
      add_question: 'Добавить вопрос',
      add_receipt: 'Добавить квитанцию',
      add_wallet: 'Добавить кошелек',
      address: 'Адрес',
      admin: 'Администрация',
      advance: 'Продвигать',
      affected: 'Достиг',
      after: 'После',
      air_transport: 'Воздушный транспорт',
      ajust_limite: 'Регулировка предела',
      ajust_saldo: 'Регулировка баланса',
      alert: 'Тревога',
      alerts: 'Оповещения',
      all: 'Все',
      all_departments: 'Все отделы',
      allies_of: 'Союзники',
      allRightsReserved: 'Все права защищены.',
      almost_there: 'Почти готово',
      already_offset: 'я уже компенсировал',
      already_registered: 'Уже зарегистрирован? Нажмите здесь и войдите',
      amount: 'Ценить',
      amount_above_balance: 'Недостаточно средств',
      and: 'Это',
      and_more: 'и более',
      answer: 'Ответ',
      ao: 'к',
      approve: 'Согласовать',
      April: 'апрель',
      asc: 'Восходящий',
      at: 'в',
      August: 'Август',
      automations: 'Автоматизация',
      awaiting_email_confirmation: 'Ждем подтверждения по электронной почте!',
      b_order: 'Заказ',
      b_orders: 'Заказы',
      back: 'Вернуться назад',
      bairroInteresse: 'Район',
      balance: 'Баланс',
      balance_available: 'Баланс пожертвований',
      banned: 'Запрещено',
      before_contact: 'Прежде чем связаться с нами',
      binary: 'Двоичный',
      birth: 'Рождение',
      blind_man: 'Перевернутый',
      body_monthly:
        'Срок действия вашей ежемесячной платы истек, и ваш BOT TRADER был деактивирован. Оплатите платежное поручение и снова наслаждайтесь преимуществами.',
      bonus_saldo_trader: 'Бонус трейдерского баланса',
      book_health: 'КнигаЗдоровье',
      but_possible_register:
        'Зарегистрироваться по-прежнему можно, но вы не будете связаны ни с одним приглашенным другом.',
      buy_volume: 'Объем покупки',
      buyer: 'Покупатель',
      by: 'за',
      bye: 'Увидимся позже',
      call: 'Называется',
      call_to: 'Позвонить',
      calls: 'Вызовы',
      cancel: 'Отмена',
      canceled: 'Отменено',
      career_plan: 'Карьерная лестница',
      casa_verde_amarela: 'Желто-Зеленый Дом',
      cash_payment: 'Наличный расчет',
      cash_payment_return: 'Возврат наличного платежа',
      cash_receipt: 'Кассовый чек',
      change_indicant: 'Индикатор изменения',
      change_photo: 'Измени фотографию',
      change_plan: 'Изменить план',
      check_payment: 'Оплата чеком',
      check_payment_return: 'Возврат оплаты чеком',
      check_receipt: 'Квитанция по чеку',
      choose: 'Выбирать',
      cidadeInteresse: 'Город',
      city: 'Город',
      claims: 'Претензии',
      clean: 'Чистить',
      click_and_know: 'Нажмите и откройте для себя',
      click_to_copy_the_wallet: 'Нажмите, чтобы скопировать кошелек',
      client: 'Клиент',
      clients: 'Клиенты',
      close: 'Закрытие',
      cnh: 'CNH (лицензия)',
      cnhDate: 'Дата выпуска CNH',
      cnpj: 'НКНПЖ',
      cnpj_invalid: 'Неверный CNPJ',
      coin: 'Монета',
      color: 'Цвет',
      commercial: 'Коммерческий',
      company: 'Название компании',
      complement: 'Дополнить',
      comporRenda: 'Собираетесь ли вы зарабатывать деньги с кем-то?',
      compRenda: 'Тип подтверждения дохода',
      confirm_approve: 'Подтвердить одобрение',
      confirm_delete: 'Подтвердить удаление',
      confirm_new_password: 'Подтвердите новый пароль',
      confirm_new_password_security: 'Подтвердите новый пароль безопасности',
      confirm_password: 'Подтвердите пароль',
      confirm_password_security: 'Подтвердите пароль безопасности',
      confirm_your_new_password: 'Введите новый пароль еще раз',
      confirmed: 'Подтвержденный',
      confirmed_email: 'Подтвержденный адрес электронной почты',
      congratulations: 'Поздравления',
      contact: 'Контакт',
      contract: 'Договор',
      copied: 'Скопировано',
      copy: 'Копировать',
      copy_success: 'Скопировано успешно!',
      correspondences: 'Переписка',
      countries: 'Страны',
      country: 'Страна',
      cpf: 'КПФ',
      cpf_invalid: 'Неверный CPF',
      create_order_success: 'Пожертвование успешно создано!',
      created: 'Зарегистрировано в',
      cred: 'Кредит',
      cred_binario: 'Бинарный кредит',
      cred_deb: 'Кредиты/дебеты',
      cred_game_donate: 'Пожертвовать кредит на игру',
      cred_ind_direta: 'Шаг кредита',
      cred_ind_indireta: 'Шаг кредита',
      cred_pagar_com_saldo: 'Кредит Пожертвовать с баланса',
      cred_pontos: 'Кредитные баллы',
      cred_recb_trader: 'Трейдер получения кредита',
      cred_renovacao: 'Продление кредита',
      cred_saldo_disp: 'Пожертвование Баланс Кредит',
      cred_upgrade: 'Кредит на обновление',
      credit_card_payment: 'Оплата кредитной картой',
      credit_card_payment_return: 'Возврат оплаты кредитной картой',
      credit_card_receipt: 'Квитанция по кредитной карте',
      current: 'Текущий',
      current_password: 'Текущий пароль',
      current_wallet: 'Текущий портфель',
      dashboard: 'Панель управления',
      data_added: 'Добавлены данные',
      data_saved: 'Сохраненные данные',
      datas: 'Данные',
      date: 'Дата',
      date_first_installment: 'Дата первого платежа',
      ddi: 'ДДИ',
      deb_de_trader: 'Трейдерский долг',
      deb_game_donate: 'Дебет пожертвования игры',
      deb_saldo: 'Дебетовый баланс',
      deb_saldo_disp: 'Дебет баланса пожертвования',
      deb_saldo_pendent: 'Дебетовый остаток непогашенного остатка',
      deb_val_indev: 'Дебет неоплаченной суммы',
      debit_card_payment: 'Оплата дебетовой картой',
      debit_card_payment_return: 'Возврат оплаты дебетовой картой',
      debit_card_receipt: 'Квитанция на дебетовую карту',
      debt: 'Долг',
      debt_td: 'Дебет',
      December: 'Декабрь',
      decImpRenda: 'Декларировать подоходный налог',
      deleted_document: 'Документ был удален из вашей записи',
      department: 'Отделение',
      dependents: 'Иждивенцы',
      desc: 'Вниз',
      description: 'Описание',
      details: 'Подробности',
      dev_econom: 'Экономическое развитие',
      developer: 'Разработчик',
      directly_indicated: 'Прямо указано',
      directly_indicateds: 'Направлено напрямую',
      district: 'Район/район',
      divorced: 'В разводе',
      doa_men_receb: 'Получено ежемесячное пожертвование на платформу',
      doacao_td: 'Пожертвование',
      doctorate_complete: 'Докторантура - полная',
      doctorate_incomplete: 'Докторантура - неполная',
      document: 'Документ',
      document_already_registered: 'Документ уже зарегистрирован',
      documents: 'Документы',
      donate: 'Пожертвовать',
      donor: 'Донор',
      dont_know_zip: 'Не знаете почтовый индекс? кликните сюда',
      dou_fe: 'Я подтверждаю, что приведенная выше информация верна.',
      downloads: 'Скачать файлы',
      drop_images: 'Нажмите или перетащите изображения сюда',
      due_date: 'Зрелость',
      edit: 'Редактировать',
      edit_option: 'Редактировать вариант',
      edit_question: 'Изменить вопрос',
      email: 'Электронная почта',
      email_already_registered: 'Электронная почта уже зарегистрирована',
      email_and_or_password_wrong: 'Неправильный адрес электронной почты и/или пароль!',
      email_for_login: 'Этот адрес электронной почты будет вашим логином',
      email_or_password_wrong: 'Неверный адрес электронной почты или пароль',
      employee: 'Сотрудник',
      employees: 'Сотрудники',
      empty: 'Пустой',
      enter_your_new_password: 'Введите новый пароль',
      entrada: 'Имеет ли он входное значение? Если ДА, какое значение',
      entries_until_today: 'Записи на сегодняшний день',
      error_confirm_password: 'Подтверждение и новый пароль должны быть одинаковыми.',
      error_email: 'Неверный формат электронной почты',
      error_password: 'неверный пароль',
      error_phone: 'Неправильный номер телефона',
      estadoInteresse: 'состояние',
      estimate: 'Оцененный',
      exchange: 'Замена',
      expired: 'Истекший',
      extorno_td: 'Разворот',
      extra: 'Дополнительный',
      extract: 'Движение',
      extracts: 'Движения',
      fantasy: 'Фантазия',
      fatherName: 'Имя Отца',
      February: 'февраль',
      fee: 'Комиссия за снятие средств',
      feedback: 'Возвращаться',
      feminine: 'Женский',
      fgts: 'Стоимость вашего ФГТС',
      field_required: 'Обязательное завершение',
      final: 'Финал',
      finance: 'Финансовый',
      financial: 'Финансовый',
      financial_payment: 'Финансовый платеж',
      financial_payment_return: 'Возврат финансового платежа',
      financial_receipt: 'Получение финансов',
      financing: 'Финансирование',
      financings: 'Финансирование',
      first_10_pages: 'Первые 10 страниц',
      fisica: 'Физическое лицо',
      for_year: 'Ежегодно',
      forget_password: 'Я забыл пароль',
      forgot_password: 'Забыли пароль',
      form: 'Форма',
      form_of_payment: 'Форма оплаты',
      found: 'Найденный',
      founds: 'Найденный',
      free: 'Бесплатно',
      friend_indicated: 'Рекомендовано моими друзьями',
      friend_indicateds: 'Рекомендовано моими друзьями',
      fuel: 'Топливо',
      full_name: 'Полное имя',
      funcPublico: 'является государственным служащим',
      fundamental_complete: 'Фундаментальный – полный',
      fundamental_incomplete: 'Фундаментальный – неполный',
      ganh_diario: 'Ежедневный доход',
      gender: 'Пол',

      grand_total: 'общий итог',
      group: 'Группа',
      has_withdraw_today: 'Вывод средств уже был запрошен сегодня',
      high: 'Высокий',
      home: 'Начинать',
      how_create_binance_apikey: 'Как создать ключ API на Binance',
      how_it_works: 'Как это работает',
      how_to_call: 'Как ты хочешь, чтобы тебя называли?',
      id_td: 'Шаг кредита',
      images: 'Изображений',
      in_construction: 'В разработке',
      in_order: 'Чтобы',
      in_stock: 'В наличии',
      indicant: 'Индикатор',
      indicated_by: 'Вас направляет',
      indicated_not_found: 'Указанный реферер не найден...',
      indicateds: 'Друзья',
      indicated: 'Друг',
      indirects: 'Друзья',
      info: 'Информационные бюллетени',
      info_contact: 'Контактная информация',
      initial: 'Дом',
      insert_new: 'Введите новый',
      installments: 'Рассрочка',
      intelligence: 'Интеллект',
      interesse: 'Какой регион интересен',
      invalid_contact: 'Неверный формат контакта',
      invalid_images: 'Недопустимые изображения',
      invalid_token: 'Неверный токен',
      invite: 'Пригласить',
      invite_friends: 'Пригласить друзей',
      January: 'январь',
      July: 'Июль',
      June: 'Июнь',
      juridica: 'Законопослушный гражданин',
      know_exact_result: 'Знаете ли вы точную стоимость?',
      lead: 'Вместе мы построим новое будущее.',
      learn_more: 'Узнать больше',
      left: 'Левый',
      left_preferential: 'Предпочитаю левую сторону',
      legalNature: 'Правовая природа',
      level: 'Уровень',
      limite_80: 'Вы достигли {{perc}} вашего лимита.\n Внесите улучшения и избегайте приостановки вашего дохода.',
      limite_excedido: 'Дебетовый лимит превышен',
      linear: 'Линейный',
      link_email: 'Нажмите на ссылку, отправленную на зарегистрированный адрес электронной почты!',
      link_invite_copy: 'Ссылка для общего доступа успешно скопирована.',
      liquid: 'Жидкость',
      list: 'Список',
      loading: 'Загрузка',
      location: 'Расположение',
      locked: 'Заблокировано',
      login: 'Войти',
      logout: 'Выйти',
      low: 'Низкий',
      male: 'Мужской род',
      manage_users: 'Управление донорами',
      manager: 'Менеджер',
      manufacturer: 'Производитель',
      March: 'Маршировать',
      maritalStatus: 'Семейное положение',
      market: 'Рынок',
      married: 'Женатый',
      master_complete: 'Степень магистра - полная',
      master_incomplete: 'Степень магистра - неполная',
      max_payment: 'Максимальная выплата',
      May: 'Может',
      message: 'Сообщение',
      messageNotSent: 'Не удалось отправить ваше сообщение',
      messageSent: 'Ваше сообщение было отправлено успешно',
      middle_complete: 'Средний — Полный',
      middle_incomplete: 'Средний – Неполный',
      min_amount_error: 'Значение должно быть как минимум:',
      min_withdraw: 'Минимальная сумма вывода',
      missing: 'Отсутствующий',
      mission: 'Миссия',
      mission_vision_values: 'Миссия, видение и ценности',
      model: 'Модель',
      monitors: 'Мониторы',
      monthly: 'Ежемесячно оплата',
      motherName: 'Имя матери',
      movements: 'Движения',
      msg_renovacao: 'Вы достигли лимита заработка, продлите пакет.',
      my_account: 'Мой счет',
      my_wallet: 'Мой бумажник',
      mystock: 'Мой запас',
      name: 'Имя',
      nascMaisVelhoRenda2: 'Какая дата рождения у старшего?',
      nationality: 'Гражданство (Страна рождения)',
      naturalness: 'Место рождения (Город рождения)',
      nature: 'Природа',
      network: 'Сообщество',
      new: 'Новый',
      new_call: 'Новое призвание',
      new_financing: 'Новое финансирование',
      new_order: 'Новый заказ',
      new_password: 'Новый пароль',
      new_password_security: 'Новый пароль безопасности',
      new_seller: 'Новый продавец',
      new_wallet: 'Новый кошелек',
      news: 'Новости',
      next: 'Следующий',
      nick: 'Донор (логин)',
      nis: 'ПИС/НИС',
      nis_already_registered: 'PIS/NIS уже зарегистрированы',
      no: 'Нет',
      no_approve: 'Еще не одобрено',
      no_delete: 'Не удалять',
      no_literate: 'Неграмотный',
      no_repeat: 'Данные не могут повторяться',
      no_results_found: 'результатов не найдено',
      no_services_or_products_added: 'Никаких услуг и товаров не добавлено',
      no_settings: 'Зайдите в Настройки и заполните свои данные.',
      none_friends_indicated: 'Я еще не пригласил друзей',
      not_authorized: 'Не авторизовано',
      not_found: 'Не найдено',
      not_login: 'Не удалось войти в систему, проверьте свои данные и повторите попытку!',
      not_orders: 'Нет платежных поручений',
      not_permission: 'Вы не имеете доступа к этой странице',
      not_registered: 'Еще не зарегистрированы? Нажмите здесь и зарегистрируйтесь',
      not_results: 'Записей не найдено',
      not_self_register: 'Невозможно зарегистрироваться',
      not_today_entry: 'У нас до сих пор не было билетов сегодня',
      notfound_ad: 'Объявления не найдены',
      November: 'ноябрь',
      number: 'Число',
      obs: 'Комментарии',
      obs_pay:
        'Если ваш брокер списывает комиссии с отправленной суммы, вам необходимо внести корректировку вручную, добавив суммы этих комиссий.',
      October: 'Октябрь',
      of: 'в',
      off: 'уничтожен',
      on_total: 'Из общего числа',
      open: 'Открыть',
      opening: 'Открытие',
      operation: 'Производительность',
      ordenation: 'Заказ',
      order: 'Пожертвование',
      order_book: 'Книга заказов',
      order_not_alowed_to_pay: 'Несанкционированное пожертвование на Donate с балансом',
      order_not_found: 'Пожертвование не найдено',
      order_payed: 'Пожертвование №{{id}} успешно оплачено',
      orders: 'Пожертвования',
      other: 'Другой',
      other_services_or_products: 'Другие услуги или продукты',
      paid: 'Выплаченый',
      paid_date: 'День оплаты',
      partial: 'Частично оплачено',
      passport: 'Заграничный пасспорт',
      password: 'Пароль',
      password_security: 'Пароль безопасности',
      pay: 'Пожертвовать',
      pay_exact: 'Заплатите сумму в {{coin}}',
      pay_generate: 'Создать платеж',
      pay_selected: 'Пожертвовать выбранное',
      pay_value_in: 'Оплатите сумму в',
      pay_with_balance: 'Пожертвовать с баланса',
      payable: 'Пожертвовать',
      payer: 'Плательщик',
      payment: 'Оплата',
      ped_retirada: 'Вывод пожертвований',
      ped_retirada_gan_diario: 'Пожертвование на вывод ежедневного дохода',
      pendent: 'В ожидании',
      people: 'Люди',
      person: 'Человек',
      person_found_in_other_prefecture: 'Лицо, уже зарегистрированное в другом муниципалитете',
      personal: 'Ребята',
      personal_addresses: 'Адреса',
      personal_contacts: 'Контакты',
      personal_data: 'Личные данные',
      personal_infos: 'Информация',
      personal_pcva: 'Программа «Зеленый и желтый дом»',
      phone: 'Телефон',
      photo: 'Фотография',
      pix: 'ПИКС',
      pix_payment: 'Оплата в PIX',
      pix_payment_return: 'Возврат оплаты в PIX',
      pix_receipt: 'Прием в PIX',
      pl_carreira: 'Карьерная лестница',
      plan: 'Плоский',
      plates: 'Тарелки',
      please_wait: 'пожалуйста, подождите',
      plus: 'Плюс',
      point: 'Точка',
      points: 'Точки',
      portfolio: 'портфолио',
      pos_complete: 'Lato Sensu - Полный',
      pos_incomplete: 'Lato sensu – неполный',
      prefecture: 'Ратуша',
      prefecture_addresses: 'Адреса',
      prefecture_contacts: 'Контакты',
      prefecture_data: 'Данные мэрии',
      prefecture_infos: 'Информация',
      prefectures: 'Ратуши',
      prev: 'Предыдущий',
      price: 'Цена',
      print: 'Распечатать',
      privacy_policies: 'Политика конфиденциальности',
      private_works: 'Частные работы',
      profession: 'Профессия',
      profile: 'Профиль',
      program: 'Программа',
      programs: 'Программное обеспечение',
      project: 'Проект',
      projects: 'Проекты',
      public_works: 'Общественные работы',
      purchase: 'Покупка',
      qualify_binary: 'Бинарная квалификация',
      qualquer: 'Любой',
      quantity: 'Количество',
      quotation: 'Цена',
      read: 'Читать',
      receivable: 'Получать',
      recipient: 'Получатель',
      recommendations: 'Рекомендации',
      redefine_password: 'Переопределить пароль',
      register: 'Записывать',
      register_people: 'Регистрация лиц',
      register_wallet: 'Зарегистрируйте кошелек для вывода средств',
      registers: 'Рекорды',
      registers_users: 'Регистрация доноров',
      reject: 'Отклонять',
      remaining: 'Оставшийся',
      remove: 'Удалять',
      rendaIndividual: 'Индивидуальный валовой доход',
      renew: 'Продлить',
      reply: 'Ответить',
      reports: 'Отчеты',
      required_data_not_sent: 'Необходимые данные не отправлены',
      resend_email: 'Отправить письмо повторно',
      residential: 'Жилой',
      responded: 'Ответил',
      restricao: 'Есть ли у вас ограничения на имя? (SPC/Сераса)',
      result: 'Результат',
      results: 'Полученные результаты',
      resume_data: 'Сводка данных',
      revoked: 'Отменен',
      rg: 'Общая регистрация (идентификация)',
      rgAgency: 'эмитент РГ',
      rgDate: 'Дата выпуска РГ',
      rgUf: 'Состояние РГ',
      right: 'Верно',
      right_preferential: 'Предпочитаю правую сторону',
      roi: 'Ежедневный доход',
      sale: 'Распродажа',
      saq_duplicado: 'Дубликат вывода средств',
      save: 'Сохранить',
      schooling: 'Образование',
      search: 'Поиск',
      secretary: 'Секретарь',

      security_password: 'Пароль безопасности',
      see: 'Чтобы увидеть',
      see_advents: 'Посмотреть рекламу',
      see_call: 'Посмотреть звонок',
      see_details: 'смотрите подробности',
      see_details_wallet: 'Просмотр сведений о кошельке',
      see_financing: 'Посмотреть финансирование',
      see_order: 'Посмотреть пожертвование',
      see_user: 'Посмотреть доноров',
      select_vehicle: 'Выберите автомобиль',
      self_register: 'регистр',
      sell_volume: 'Объем продаж',
      seller: 'Продавец',
      send_call: 'Отправить звонок',
      send_to: 'Отправить',
      September: 'Сентябрь',
      settings: 'настройки',
      share_your_link: 'Поделитесь своей ссылкой',
      show: 'Показать до',
      show_from: 'Отображение с',
      side: 'Сторона',
      sign_in_to_start_your_session: 'Войдите, чтобы начать сеанс',
      sign_up: 'регистр',
      single: 'Одинокий',
      sold: 'Продал',
      state: 'состояние',
      status: 'Положение дел',
      strategies: 'Стратегии',
      street: 'Дорога',
      subject: 'Предмет',
      submit: 'Отправлять',
      submit_placeholder: 'Пожалуйста, заполните свой адрес электронной почты',
      subscribe: 'Подпишитесь, чтобы получать наши новости',
      subtitle: 'Подпись',
      success_update_user: 'Донор успешно обновлен!',
      success_wallet: 'Портфолио успешно обновлено!',
      suggestions: 'Предложения',
      superior_complete: 'Окончил',
      superior_incomplete: 'Неполное высшее',
      supervisor: 'Руководитель',
      support: 'Поддерживать',
      support_materials: 'Вспомогательные материалы',
      suspended: 'Приостановленный',
      symbol: 'Символ',
      symbols: 'Символы',
      system: 'Система',
      target: 'Цель',
      telegram_chat_id: 'Идентификатор чата Telegram',
      tempoCarteira: 'Как давно вы зарегистрировались в своем портфолио?',
      terms: 'Я принимаю условия использования',
      text_hint_binance:
        'При создании ключа API на Binance введите IP в ограничениях доступа:\n172.105.78.139\nЗатем выберите параметры:\n• ВКЛЮЧИТЬ ЕВРОПЕЙСКИЕ ОПЦИИ\n• ВКЛЮЧИТЬ СПОТНУЮ И МАРЖИННУЮ ТОРГОВЛЮ.',
      this_value_in: 'Это значение в',
      ticker_health: 'ТикерЗдоровье',
      title: 'Заголовок',
      to: 'До',
      to_pay: 'Пожертвовать',
      to_receive: 'дебиторская задолженность',
      today_entry: 'Сегодняшняя запись',
      token_not_found_or_already_used: 'Токен не найден или уже использован',
      total: 'Общий',
      total_entry: 'Общий вход',
      traderbot: 'Бот Трейдер',
      transf_credito: 'Кредитный перевод',
      transf_debito: 'Перевод долга',
      transfer_payment: 'Оплата переводом',
      transfer_payment_return: 'Возврат оплаты переводом',
      transfer_receipt: 'Квитанция в передаче',
      transparency: 'Прозрачность',
      tx_adm_cred: 'Администрирование налогов',
      tx_adm_deb: 'Администрирование налогов',
      tx_retirada: 'Комиссия за снятие средств',
      tx_retirada_gan_diario: 'Комиссия за снятие ежедневной прибыли',
      type: 'Тип',
      update: 'Обновить',
      update_wallet: 'Обновить кошелек',
      upgrade: 'Улучшение',
      used: 'Использовал',
      user: 'Донор',
      user_active: 'Активный донор',
      user_already_exists: 'Донор уже существует!',
      user_and_or_password_wrong: 'Неправильный донор и/или пароль!',
      user_inactive: 'Неактивный донор',
      user_not_found: 'Донор не найден',
      user_not_logged: 'Донор не авторизован',
      user_or_password_wrong: 'Неверный донор или пароль.',
      users: 'Доноры',
      users_list: 'Список доноров',
      valid_images: 'Действительные изображения',
      valorRenda2: 'Сколько стоит второй доход',
      value: 'Ценить',
      value_donate_on: 'Сумма пожертвования в',
      value_financed: 'Сумма финансирования',
      value_installment: 'Стоимость рассрочки',
      values: 'Ценности',
      vehicle: 'Транспортное средство',
      vehicles: 'Транспортные средства',
      view_order: 'Посмотреть заказ',
      vision: 'Зрение',
      visitor: 'Посетитель',
      voucher: 'Ваучер',
      wait_confirm: 'Ожидание подтверждения',
      waiting: 'Ожидающий',
      waiting_email:
        'Регистрация прошла успешно, теперь все, что вам нужно сделать, это подтвердить свой адрес электронной почты.',
      register_ok: 'Регистрация прошла успешно.',
      waiting_payment: 'Ожидание пожертвования',
      wallet: 'портфолио',
      wallet_generate: 'Создать портфолио',
      wallets: 'Кошельки',
      want_donate: 'Я хочу пожертвовать',
      want_now_more_about: 'Я хочу узнать больше о',
      warning: 'ВНИМАНИЕ',
      we_are: 'Являются',
      webhooks: 'Вебхуки',
      welcome: 'Добро пожаловать',
      who_we_are: 'Кто мы',
      who_we_are_subtitle: 'Краткое описание',
      widower: 'вдовец',
      will_expire_in: 'истечет через',
      with: 'с',
      withdraw: 'Снятие',
      withdraw_sended_email:
        'Запрошен вывод средств, проверьте свою электронную почту и нажмите ссылку для авторизации!',
      withdrawal: 'Вывод средств',
      withdrawals: 'Вывод средств',
      year: 'Год',
      years: 'Годы',
      yes: 'Да',
      you_have: 'У вас есть',
      your_country: 'Твоя страна',
      your_dashboard: 'Это ваша панель управления.',
      your_link: 'Ваша ссылка',
      zipcode: 'Почтовый индекс',
      withdrawal_sended: 'Вывод отправлен',
      withdrawal_pending:
        'Вывод средств уже запрошен! \nПожалуйста, дождитесь платежа, чтобы сделать еще один вывод средств.',
      limite_80_top: 'Вы достигли {{perc}} вашего лимита.\n Ожидайте продления и избегайте приостановки вашего дохода.',
      game: 'Игра',
      games: 'Игры',
      winning_numbers: 'нарисованные числа',
      your_numbers: 'Ваши номера',
      add_numbers: 'Добавить номера',
      next_draw: 'Следующий розыгрыш',
      can_bet_until: 'Вы можете сделать ставку до',
      your_bets: 'Ваши ставки',
      add_bet: 'Добавить ставку',
      bet: 'Делать ставку',
      bets: 'Пари',
      numbers: 'Числа',
      special_numbers: 'Специальные номера',
      resume: 'Краткое содержание',
      choosed_numbers: 'Выбранные номера',
      choosed_special_numbers: 'Выбраны специальные номера',
      bets_finished: 'Расчетные ставки',
      prize_draw: 'Розыгрыш призов',
      balance_network: 'Баланс сообщества',
      telegram: 'Телеграмма',
      logs: 'Журналы',
      release_withdrawals: 'Выпуск вывода средств',
      bot_active: 'Релиз бота',
      available: 'Доступный',
      sunday: 'Воскресенье',
      monday: 'Второй',
      tuesday: 'Третий',
      wednesday: 'Четвертый',
      thursday: 'Пятый',
      friday: 'Пятница',
      saturday: 'Суббота',
      title_binary: 'Установите бинарные проценты на каждый день',
      ok_perc_change: 'Проценты успешно изменены',
      cred_deb_title: 'Кредитный или дебетовый баланс',
      send: 'Отправлять',
      voucher_pendent: 'Ваучер ожидает рассмотрения',
      leader_pendent: 'Ожидаемый лидер',
      voucher_approved: 'Ваучер одобрен',
      leader_approved: 'Утвержденный лидер',
      no_binary_at_today: 'В этот день не было зарегистрировано ни одного бинара.',
      generate_file: 'Создать файл',
      withdrawals_file_created: 'Файл вывода создан',
      withdrawals_sended: 'Запросы на вывод отправлены',
      see_generated_files: 'Просмотр созданных файлов',
      files: 'Файлы',
      file: 'Файл',
      verify: 'Проверить',
      rescue: 'Спасать',
      awarded: 'Награжден',
      rescued: 'Спасен',
      repeat: 'Повторить',
      draw_date: 'Дата розыгрыша',
      bet_date: 'Дата ставки',
      drawn_numbers: 'нарисованные числа',
      awardeds_numbers: 'Призовые номера',
      no_award: 'Нет приза',
      you_hit_number: 'Вы правильно угадали номер {{qty}}',
      you_hit_numbers: 'Вы правильно угадали числа: {{qty}}.',
      lottery: 'Лотерея',
      lotteries: 'Лотереи',
      balls: 'мячи',
      special_balls: 'Специальные предложения',
      levy: 'коллекция',
      major_awarded: 'больше мячей',
      hits: 'хиты',
      cred_mandala: 'Кредит Звездной Матрицы',
      renew_mandala: 'Обновление Звездной Матрицы',
      mandala: 'Звездная матрица',
      mandala2: 'Звездная матрица 2.0',
      mandalas: 'Звездная матрица',
      mandala_in: 'Участвуйте в Звездной Матрице',
      rifas: 'Звездная матрица',
      rifa: 'Звездная матрица',
      lascado: 'Он чипованный',
      estribado: 'Это перемешалось',
      none: 'Никто',
      directs: 'Друзья',
      matuto: 'Матуто',
      matutos: 'Матутос',
      cupom: 'Номер купона в звездной матрице',
      your_position: 'Ваше положение в звездной матрице',
      closed: 'Закрыто',
      gang: 'Звездная матрица',
      gangs: 'Звездная матрица',
      influencer_required: 'Вам нужно выбрать инфлюенсера',
      pendent_order: 'У вас есть ожидающие пожертвования',
      influencer: 'Влиятельный человек',
      change_influencer: 'Обмен влиятельным лицом',
      no_influencer: 'Нет влиятельного лица',
      msg_no_influencer: 'Вы еще не выбрали ни одного влиятельного лица',
      you_are_influencer: 'Вы влиятельный человек? Нажмите здесь и зарегистрируйтесь',
      register_influencer: 'Регистрация влиятельного лица',
      add_your_photo: 'Добавьте свою фотографию',

      hello: 'Привет',
      msg_add_order:
        'Благодарим вас за регистрацию в {{name}}.\nМы рады видеть вас частью нашего сообщества.\n\nЧтобы смотреть трансляцию и пользоваться всеми преимуществами, вам необходимо оплатить ЧЛЕНСТВО.\nЧЛЕНСТВО является одноразовым. повременная плата, которая дает вам доступ к нашему эксклюзивному контенту, технической поддержке, обновлениям и многому другому.\n\nЧтобы оплатить членство, просто заплатите через PIX, используя QR-код нижеили скопировав адрес чуть ниже QR-кода и вставив его. его в приложение вашего банка.\n\nПодтверждение происходит автоматически и мгновенно (обычно в течение 10 секунд).\n\nИ всё! После этого вы сможете пользоваться {{name}}.\n\nЕсли у вас возникнут какие-либо вопросы или проблемы, свяжитесь с нами по электронной почте {{email}}.\n\nС уважением',
      msg_mandala_order:
        'Вы в одном шаге от участия в нашей взаимопомощи, Звездной Матрице.\n\nЗвездная Матрица — это веселый и безопасный способ заработать хорошие деньги.\n\nЧтобы принять участие, вам просто нужно сделать Пожертвование, зайдя на сайт своему брокеру и прочитав QR-код ниже. \n\nПодтверждение происходит автоматически, но может занять несколько минут.\n\nПосле этого вы сможете пользоваться {{name}}.\n\nЕсли у вас есть какие-либо вопросы или проблемы, пожалуйста, свяжитесь с номинатором.\n\nС уважением',
      msg_cota_solidaria_order:
        'С помощью квот солидарности вы вносите свой вклад в нашу социальную работу, помогая нуждающимся семьям и даже соревнуясь за автомобиль.\n\nЧтобы оплатить и получить квоту солидарности, просто произведите платеж через PIX, используя QR-код нижеили скопировав адрес ниже QR-кода и вставьте его в приложение вашего банка.\n\nПодтверждение происходит автоматически и мгновенно (обычно в течение 10 секунд).\n\nИ все! После этого вы уже будете участвовать в Квоте Солидарности.\n\nЕсли у вас есть какие-либо вопросы или проблемы, свяжитесь с нами через Билет.\n\nС уважением',
      followers: 'Последователи',
      required_fields: 'Обязательные поля',
      your_influencer: 'Ваш влиятельный человек',
      cota_solidaria: 'Квота солидарности',
      cotas_solidarias: 'Квоты солидарности',
      add_cota_solidaria: 'Добавить квоту солидарности',
      add_cotas_solidarias: 'Добавьте квоты солидарности',
      coupon: 'Купон',
      error_mandala_open: 'Уже существует открытое пожертвование Звездной Матрицы',
      error_cotaSolidaria_open: 'У вас уже есть открытое пожертвование по квоте солидарности.',
      cotaSolidaria_not_created: 'Квота солидарности не была создана',
      success_mandala: 'Пожертвование Star Matrix успешно создано.',
      success_cotaSolidaria: 'Квота солидарности успешно создана',
      cotas_solidaria_desc:
        'Подтвердите, что вы хотите приобрести {{qty}} квот солидарности по цене {{price}} на общую сумму {{total}}.',
      cota_solidaria_desc:
        'Подтвердите, что вы хотите приобрести {{qty}} квоту солидарности по цене {{price}} на общую сумму {{total}}.',
      builder: 'Конструктор',
			top: 'Вершина',
			day: 'день',
      days: 'дни',
      hour: 'час',
      hours: 'часы',
      minute: 'минуты',
      minutes: 'минуты',
      second: 'второй',
      seconds: 'секунды',
      comming: 'Отсутствующий',
      comming_soon: 'Всемирный запуск в',
			net: 'Сеть',
			raffels: 'лотереи',
			new_raffle: 'Новый лотерей',
    },
  },
}
export { messages }
