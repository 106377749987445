import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/raffels`

export async function getRaffels(search, page, limit, status, ordenation) {
  const endPoint = `${ENDPOINT}/list${page ? `?page=${page}` : ''}`
  const response = await axios.post(endPoint, { status, limit, search, ordenation })
  return response.data
}

export async function addRaffel(post) {
  const endPoint = `${ENDPOINT}/add`
  const response = await axios.post(endPoint, post)
  return response.data
}
