import React, { useEffect } from 'react'
import NavBar from './NavBar'
import SideBar from './SideBar'
import { validateToken } from '../../services/AuthService'
import { Redirect } from 'react-router-dom'

function Menu() {
  const token = sessionStorage.getItem('token')
  useEffect(() => {
    if (token) {
      validateToken().then(success => {
        if (!success.status) {
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('t')
          return <Redirect to="/" />
        }
      })
    }
  }, [token])
  return (
    <React.Fragment>
      <NavBar />
      <SideBar />
    </React.Fragment>
  )
}

export default Menu
