import React, { useState } from 'react'
import { FormateDate, FormateFiatCurrency } from '../../utils/CustomFormatations'
import { i18n } from '../../utils/translates/i18n'
import SwitchInput from '../../components/SwitchInput/SwitchInput'

/**
 * props:
 * - data
 * - onEditClick
 */
export default function WithdrawRow({ w, chooseWithdraw, editWithdraw, chargeback }) {
  const [showWallet, setShowWallet] = useState(false)
  return (
    <>
      <tr>
        <td>
          {w.status === 1 || w.status === 2 ? (
            <SwitchInput
              id="choose"
              text={i18n.t(w.statuses.status)}
              bg={`bg-${w.statuses?.bg}`}
              onChange={() => chooseWithdraw(w.id, w.amount)}
              isChecked={editWithdraw.includes(w.id)}
            />
          ) : (
            <span>{i18n.t(w.statuses.status)}</span>
          )}
        </td>
        <td>{FormateDate(w.createdAt, true)}</td>
        <td>
          <a className={`m-0 p-0 text-left`} href="#!">
            <span className="fw-bold">{w.user?.nick?.toUpperCase()}</span>
            <br />
            <small style={{ whiteSpace: 'pre-wrap' }}>{w.user?.name}</small>
          </a>
        </td>
        <td>{FormateFiatCurrency(w.amount)}</td>
        <td>
          <img
            src={`https://www.coinpayments.net/images/coins/${w.paymentMethod.toUpperCase()}.png`}
            alt={w.paymentMethod.toUpperCase()}
            className="me-2 icon icon-xs"
          />
          {w.paymentMethod.toUpperCase()}
        </td>
        <td>
          <button className="btn btn-secondary btn-xs me-2" onClick={() => setShowWallet(!showWallet)}>
            <i className="fa fa-eye"></i>
          </button>
          {showWallet ? (
            <span>{w.wallet}</span>
          ) : (
            <span title={w.wallet}>
              {w.wallet.slice(0, 5)}...{w.wallet.slice(-5)}
            </span>
          )}
        </td>
        <td>
          {w.status === 1 && (
            <button
              className="btn btn-danger btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#modalChargeback"
              onClick={() => chargeback(w.id)}>
              <i className="fa fa-ban"></i> {i18n.t('extorno_td')}
            </button>
          )}
        </td>
      </tr>
    </>
  )
}
