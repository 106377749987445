const messages = {
  fr: {
    translations: {
      a_crase: 'Le',
      about: 'À propos de nous',
      above_the_same: 'Exactement la même chose ci-dessus',
      action: 'Action',
      actionTemplates: "Modèles d'action",
      activate_date: "Date d'activation",
      active_automations: 'Automatisations actives',
      active_connections: 'Connexions actives',
      active_monitors: 'Moniteurs actifs',
      active_users: 'Donateurs actifs',
      ad: 'Annonce',
      add: 'Accession',
      add_option: 'Ajouter une option',
      add_payment: 'Ajouter un paiement',
      add_question: 'Ajouter une question',
      add_receipt: 'Ajouter un reçu',
      add_wallet: 'Ajouter un portefeuille',
      address: 'Adresse',
      admin: 'Administration',
      advance: 'Avance',
      affected: 'Atteint',
      after: 'Après',
      air_transport: 'Transport aérien',
      ajust_limite: 'Ajustement des limites',
      ajust_saldo: "Ajustement de l'équilibre",
      alert: 'Alerte',
      alerts: 'Alertes',
      all: 'Tous',
      all_departments: 'Tous les départements',
      allies_of: 'Alliés de',
      allRightsReserved: 'Tous droits réservés.',
      almost_there: 'Presque là',
      already_offset: "j'ai déjà compensé",
      already_registered: 'Déjà enregistré? Cliquez ici et entrez',
      amount: 'Valeur',
      amount_above_balance: 'Fonds insuffisants',
      and: "C'est",
      and_more: 'et plus',
      answer: 'Réponse',
      ao: 'au',
      approve: 'Approuver',
      April: 'Avril',
      asc: 'Ascendant',
      at: 'dans',
      August: 'Août',
      automations: 'Automatisations',
      awaiting_email_confirmation: 'En attente de confirmation par email !',
      b_order: 'Commande',
      b_orders: 'Ordres',
      back: 'Retourner',
      bairroInteresse: 'Quartier',
      balance: 'Équilibre',
      balance_available: 'Solde du don',
      banned: 'banni',
      before_contact: 'Avant de nous contacter',
      binary: 'Binaire',
      birth: 'Naissance',
      blind_man: 'Renversé',
      body_monthly:
        "Vos frais mensuels ont expiré et votre BOT TRADER a été inactivé, veuillez payer l'ordre de paiement et profiter à nouveau de vos avantages.",
      bonus_saldo_trader: 'Bonus de solde du commerçant',
      book_health: 'LivreSanté',
      but_possible_register: "Il est toujours possible de s'inscrire, mais vous ne serez lié à aucun ami parrain.",
      buy_volume: "Volume d'achat",
      buyer: 'Acheteur',
      by: 'par',
      bye: 'À plus tard',
      call: 'Appelé',
      call_to: 'Appeler pour',
      calls: 'Appels',
      cancel: 'Annuler',
      canceled: 'Annulé',
      career_plan: 'Cheminement de carrière',
      casa_verde_amarela: 'Maison Verte Jaune',
      cash_payment: 'Paiement en espèces',
      cash_payment_return: 'Restitution du paiement en espèces',
      cash_receipt: 'Ticket de caisse',
      change_indicant: 'Indicateur de changement',
      change_photo: 'Changer la photo',
      change_plan: 'Changer de forfait',
      check_payment: 'Paiement par chèque',
      check_payment_return: 'Retour du paiement par chèque',
      check_receipt: 'Réception par chèque',
      choose: 'Choisir',
      cidadeInteresse: 'Ville',
      city: 'Ville',
      claims: 'Réclamations',
      clean: 'Nettoyer',
      click_and_know: 'Cliquez et découvrez',
      click_to_copy_the_wallet: 'Cliquez pour copier le portefeuille',
      client: 'Client',
      clients: 'Clients',
      close: 'Fermeture',
      cnh: 'CNH (Licence)',
      cnhDate: "Date d'émission du CNH",
      cnpj: 'CNPJ',
      cnpj_invalid: 'CNPJ invalide',
      coin: 'Pièce de monnaie',
      color: 'Couleur',
      commercial: 'Commercial',
      company: "Nom de l'entreprise",
      complement: 'Complément',
      comporRenda: "Allez-vous gagner de l'argent avec quelqu'un ?",
      compRenda: 'Type de preuve de revenu',
      confirm_approve: "Confirmer l'approbation",
      confirm_delete: 'Confirmer la suppression',
      confirm_new_password: 'Confirmer le nouveau mot de passe',
      confirm_new_password_security: 'Confirmer le nouveau mot de passe de sécurité',
      confirm_password: 'Confirmez le mot de passe',
      confirm_password_security: 'Confirmer le mot de passe de sécurité',
      confirm_your_new_password: 'Saisissez à nouveau votre nouveau mot de passe',
      confirmed: 'Confirmé',
      confirmed_email: 'E-mail confirmé',
      congratulations: 'Toutes nos félicitations',
      contact: 'Contact',
      contract: 'Contracter',
      copied: 'Copié',
      copy: 'Copie',
      copy_success: 'Copié avec succès !',
      correspondences: 'Correspondance',
      countries: 'Des pays',
      country: 'Pays',
      cpf: 'CPF',
      cpf_invalid: 'CPF invalide',
      create_order_success: 'Don créé avec succès !',
      created: 'Enregistré',
      cred: 'Crédit',
      cred_binario: 'Crédit binaire',
      cred_deb: 'Crédits/Débits',
      cred_game_donate: 'Jeu Faire un don de crédit',
      cred_ind_direta: "Crédit d'étape",
      cred_ind_indireta: "Crédit d'étape",
      cred_pagar_com_saldo: 'Crédit Faire un don avec solde',
      cred_pontos: 'Points de crédit',
      cred_recb_trader: 'Trader de réception de crédit',
      cred_renovacao: 'Crédit de renouvellement',
      cred_saldo_disp: 'Crédit du solde du don',
      cred_upgrade: 'Crédit de surclassement',
      credit_card_payment: 'Paiement par carte bancaire',
      credit_card_payment_return: 'Retour du paiement par carte bancaire',
      credit_card_receipt: 'Reçu de carte de crédit',
      current: 'Actuel',
      current_password: 'Mot de passe actuel',
      current_wallet: 'Portefeuille actuel',
      dashboard: 'Panneau de contrôle',
      data_added: 'Données ajoutées',
      data_saved: 'Données enregistrées',
      datas: 'Données',
      date: 'Date',
      date_first_installment: 'Date du premier paiement',
      ddi: 'DDI',
      deb_de_trader: 'Dette commerciale',
      deb_game_donate: 'Jeu Faire un don Débit',
      deb_saldo: 'Solde débiteur',
      deb_saldo_disp: 'Débit du solde du don',
      deb_saldo_pendent: 'Débit du solde impayé',
      deb_val_indev: 'Montant indu au débit',
      debit_card_payment: 'Paiement par carte de débit',
      debit_card_payment_return: 'Retour du paiement par carte de débit',
      debit_card_receipt: 'Réception par carte de débit',
      debt: 'Dette',
      debt_td: 'Débit',
      December: 'Décembre',
      decImpRenda: "Déclarer l'impôt sur le revenu",
      deleted_document: 'Le document a été supprimé de votre dossier',
      department: 'Département',
      dependents: 'Personnes à charge',
      desc: 'Vers le bas',
      description: 'Description',
      details: 'Détails',
      dev_econom: 'Développement économique',
      developer: 'Développeur',
      directly_indicated: 'Directement indiqué',
      directly_indicateds: 'Référé directement',
      district: 'Quartier / quartier',
      divorced: 'Divorcé',
      doa_men_receb: 'Don mensuel à la plateforme reçu',
      doacao_td: 'Don',
      doctorate_complete: 'Doctorat - Complet',
      doctorate_incomplete: 'Doctorat - Incomplet',
      document: 'Document',
      document_already_registered: 'Document déjà enregistré',
      documents: 'Documents',
      donate: 'Faire un don',
      donor: 'Donneur',
      dont_know_zip: 'Vous ne connaissez pas le code postal ? Cliquez ici',
      dou_fe: 'Je confirme que les informations ci-dessus sont correctes.',
      downloads: 'Telecharger des fichiers',
      drop_images: 'Cliquez ou faites glisser les images ici',
      due_date: 'Maturité',
      edit: 'Éditer',
      edit_option: "Modifier l'option",
      edit_question: 'Modifier la question',
      email: 'E-mail',
      email_already_registered: 'Email déjà enregistré',
      email_and_or_password_wrong: 'Mauvais email et/ou mot de passe !',
      email_for_login: 'Cet email sera votre identifiant',
      email_or_password_wrong: 'email ou mot de passe incorrect',
      employee: 'Employé',
      employees: 'Employés',
      empty: 'Vide',
      enter_your_new_password: 'Entrez votre nouveau mot de passe',
      entrada: "Est-ce qu'il a une valeur d'entrée ? Si OUI, quelle valeur",
      entries_until_today: 'Entrées à ce jour',
      error_confirm_password: 'La confirmation et le nouveau mot de passe doivent être identiques',
      error_email: "Format d'email invalide",
      error_password: 'Mot de passe incorrect',
      error_phone: 'Numéro de téléphone invalide',
      estadoInteresse: 'État',
      estimate: 'Estimé',
      exchange: 'Remplacement',
      expired: 'Expiré',
      extorno_td: 'Renversement',
      extra: 'Supplémentaire',
      extract: 'Mouvement',
      extracts: 'Mouvements',
      fantasy: 'Fantaisie',
      fatherName: 'Le nom du père',
      February: 'Février',
      fee: 'frais de retrait',
      feedback: 'Retour',
      feminine: 'Féminin',
      fgts: 'Valeur de votre FGTS',
      field_required: 'Achèvement obligatoire',
      final: 'Final',
      finance: 'Financier',
      financial: 'Financier',
      financial_payment: 'Paiement financier',
      financial_payment_return: 'Restitution du paiement financier',
      financial_receipt: 'Réception du financement',
      financing: 'Financement',
      financings: 'Financement',
      first_10_pages: '10 premières pages',
      fisica: 'Personne physique',
      for_year: 'Par an',
      forget_password: "J'ai oublié le mot de passe",
      forgot_password: 'Mot de passe oublié',
      form: 'Formulaire',
      form_of_payment: 'Forme de payement',
      found: 'Trouvé',
      founds: 'Trouvé',
      free: 'Gratuit',
      friend_indicated: 'Recommandé par mes amis',
      friend_indicateds: 'Recommandé par mes amis',
      fuel: 'Carburant',
      full_name: 'Nom et prénom',
      funcPublico: 'est un fonctionnaire',
      fundamental_complete: 'Fondamental - Complet',
      fundamental_incomplete: 'Fondamental - Incomplet',
      ganh_diario: 'Gains quotidiens',
      gender: 'Genre',

      grand_total: 'total',
      group: 'Groupe',
      has_withdraw_today: "Un retrait a déjà été demandé aujourd'hui",
      high: 'Haut',
      home: 'Commencer',
      how_create_binance_apikey: 'Comment créer une clé API sur Binance',
      how_it_works: 'Comment ça fonctionne',
      how_to_call: 'Comment veux-tu qu’on t’appelle ?',
      id_td: "Crédit d'étape",
      images: 'Images',
      in_construction: 'En cours de construction',
      in_order: 'En ordre',
      in_stock: 'En stock',
      indicant: 'Indicateur',
      indicated_by: 'Vous êtes référé par',
      indicated_not_found: "Le référent indiqué n'a pas été trouvé...",
      indicateds: 'Amis',
      indicated: 'Ami',
      indirects: 'Amis',
      info: "Lettres d'information",
      info_contact: 'Coordonnées',
      initial: 'Maison',
      insert_new: 'Entrez Nouveau',
      installments: 'Versements',
      intelligence: 'Intelligence',
      interesse: "Quelle est la région d'intérêt",
      invalid_contact: 'Format de contact invalide',
      invalid_images: 'Images invalides',
      invalid_token: 'jeton invalide',
      invite: 'Inviter',
      invite_friends: 'Invite tes amis',
      January: 'Janvier',
      July: 'Juillet',
      June: 'Juin',
      juridica: 'Personne morale',
      know_exact_result: 'Connaissez-vous la valeur exacte ?',
      lead: 'Ensemble, nous bâtirons un nouvel avenir.',
      learn_more: 'Savoir plus',
      left: 'Gauche',
      left_preferential: 'Préférant le côté gauche',
      legalNature: 'Nature juridique',
      level: 'Niveau',
      limite_80:
        'Vous avez atteint {{perc}} de votre limite.\n Effectuez une amélioration et évitez la suspension de vos revenus.',
      limite_excedido: 'Limite de débit dépassée',
      linear: 'Linéaire',
      link_email: "Cliquez sur le lien envoyé à l'e-mail enregistré !",
      link_invite_copy: 'Lien de partage copié avec succès',
      liquid: 'Liquide',
      list: 'Liste',
      loading: 'Chargement',
      location: 'Emplacement',
      locked: 'Bloqué',
      login: 'Entrer',
      logout: 'Sortir',
      low: 'Faible',
      male: 'Masculin',
      manage_users: 'Gérer les donateurs',
      manager: 'Directeur',
      manufacturer: 'Fabricant',
      March: 'Mars',
      maritalStatus: 'État civil',
      market: 'Marché',
      married: 'Marié',
      master_complete: 'Master - Terminé',
      master_incomplete: 'Master - Incomplet',
      max_payment: 'Paiement maximum',
      May: 'Peut',
      message: 'Message',
      messageNotSent: "Il n'a pas été possible d'envoyer votre message",
      messageSent: 'Votre message a été envoyé avec succès',
      middle_complete: 'Moyen - Complet',
      middle_incomplete: 'Moyen - Incomplet',
      min_amount_error: 'La valeur doit être au minimum :',
      min_withdraw: 'Montant minimum de retrait',
      missing: 'Manquant',
      mission: 'Mission',
      mission_vision_values: 'Mission, vision et valeurs',
      model: 'Modèle',
      monitors: 'Moniteurs',
      monthly: 'Paiement mensuel',
      motherName: 'Le nom de la mère',
      movements: 'Mouvements',
      msg_renovacao: 'Vous avez atteint votre limite de gains, renouvelez votre forfait.',
      my_account: 'Mon compte',
      my_wallet: 'Mon portefeuille',
      mystock: 'Mon stock',
      name: 'Nom',
      nascMaisVelhoRenda2: "Quelle est la date de naissance de l'aîné ?",
      nationality: 'Nationalité (Pays de naissance)',
      naturalness: 'Lieu de naissance (Ville de naissance)',
      nature: 'Nature',
      network: 'Communauté',
      new: 'Nouveau',
      new_call: 'Nouvel appel',
      new_financing: 'Nouveau financement',
      new_order: 'nouvel ordre',
      new_password: 'nouveau mot de passe',
      new_password_security: 'Nouveau mot de passe de sécurité',
      new_seller: 'Nouveau vendeur',
      new_wallet: 'Nouveau portefeuille',
      news: 'Nouvelles',
      next: 'Suivant',
      nick: 'Donateur (connexion)',
      nis: 'IRP/NIS',
      nis_already_registered: 'PIS/NIS déjà enregistré',
      no: 'Non',
      no_approve: 'Pas encore approuvé',
      no_delete: 'Ne pas supprimer',
      no_literate: 'Pas alphabétisé',
      no_repeat: 'Les données ne peuvent pas être répétées',
      no_results_found: 'aucun résultat trouvé',
      no_services_or_products_added: 'Aucun service ou produit ajouté',
      no_settings: 'Allez dans Paramètres et remplissez vos coordonnées.',
      none_friends_indicated: "Je n'ai pas encore parrainé d'amis",
      not_authorized: 'Pas autorisé',
      not_found: 'Pas trouvé',
      not_login: 'Impossible de vous connecter, veuillez vérifier vos informations et réessayer !',
      not_orders: 'Aucun ordre de paiement',
      not_permission: "Vous n'avez pas la permission d'accéder à cette page",
      not_registered: 'Pas encore inscrit? Cliquez ici et inscrivez-vous',
      not_results: 'Aucun enregistrement trouvé',
      not_self_register: "Impossible de s'inscrire",
      not_today_entry: "Nous n'avons toujours pas eu de billets aujourd'hui",
      notfound_ad: 'Aucune annonce trouvée',
      November: 'Novembre',
      number: 'Nombre',
      obs: 'commentaires',
      obs_pay:
        'Si votre courtier prélève des frais sur le montant envoyé, vous devez effectuer la correction manuellement en additionnant les montants de ces frais.',
      October: 'Octobre',
      of: 'dans',
      off: 'Anéanti',
      on_total: 'Du total',
      open: 'Ouvrir',
      opening: 'Ouverture',
      operation: 'Performance',
      ordenation: 'Commande',
      order: 'Don',
      order_book: 'Carnet de commande',
      order_not_alowed_to_pay: 'Don non autorisé à Faire un don avec solde',
      order_not_found: 'Don introuvable',
      order_payed: 'Don n° {{id}} payé avec succès',
      orders: 'Des dons',
      other: 'Autre',
      other_services_or_products: 'Autres services ou produits',
      paid: 'Payé',
      paid_date: 'Jour de paie',
      partial: 'Partiellement payé',
      passport: 'Passeport',
      password: 'Mot de passe',
      password_security: 'Mot de passe de sécurité',
      pay: 'Faire un don',
      pay_exact: 'Payez le montant en {{coin}}',
      pay_generate: 'Générer un paiement',
      pay_selected: 'Faire un don Sélectionné',
      pay_value_in: 'Payez le montant en',
      pay_with_balance: 'Faire un don avec solde',
      payable: 'Faire un don',
      payer: 'Payeur',
      payment: 'Paiement',
      ped_retirada: 'Don de retrait',
      ped_retirada_gan_diario: 'Don de retrait de gains quotidiens',
      pendent: 'En attente',
      people: 'Personnes',
      person: 'Personne',
      person_found_in_other_prefecture: 'Personne déjà inscrite dans une autre commune',
      personal: 'Les gars',
      personal_addresses: 'Adresses',
      personal_contacts: 'Contacts',
      personal_data: 'Données personnelles',
      personal_infos: 'Information',
      personal_pcva: 'Programme des Maisons Vertes et Jaunes',
      phone: 'Téléphone',
      photo: 'Photographier',
      pix: 'PIX',
      pix_payment: 'Paiement en PIX',
      pix_payment_return: 'Retour de paiement en PIX',
      pix_receipt: 'Réception dans PIX',
      pl_carreira: 'Cheminement de carrière',
      plan: 'Plat',
      plates: 'Assiettes',
      please_wait: "S'il vous plaît, attendez",
      plus: 'Plus',
      point: 'Indiquer',
      points: 'Points',
      portfolio: 'Portefeuille',
      pos_complete: 'Lato sensu - Complet',
      pos_incomplete: 'Lato sensu - Incomplet',
      prefecture: "L'hôtel de ville",
      prefecture_addresses: 'Adresses',
      prefecture_contacts: 'Contacts',
      prefecture_data: 'Données de la mairie',
      prefecture_infos: 'Information',
      prefectures: 'Mairies',
      prev: 'Précédent',
      price: 'Prix',
      print: 'Imprimer',
      privacy_policies: 'Les politiques de confidentialité',
      private_works: 'Travaux Privés',
      profession: 'Profession',
      profile: 'Profil',
      program: 'Programme',
      programs: 'Logiciel',
      project: 'Projet',
      projects: 'Projets',
      public_works: 'Travaux publics',
      purchase: 'Achat',
      qualify_binary: 'Qualification binaire',
      qualquer: "N'importe lequel",
      quantity: 'Montant',
      quotation: 'Prix',
      read: 'Lire',
      receivable: 'Recevoir',
      recipient: 'Destinataire',
      recommendations: 'Recommandations',
      redefine_password: 'Redéfinir le mot de passe',
      register: 'Enregistrer',
      register_people: 'Enregistrement des personnes',
      register_wallet: 'Enregistrez un portefeuille pour effectuer des retraits',
      registers: 'Enregistrements',
      registers_users: 'Inscriptions des donateurs',
      reject: 'Rejeter',
      remaining: 'Restant',
      remove: 'Retirer',
      rendaIndividual: 'Revenu brut individuel',
      renew: 'Renouveler',
      reply: 'Répondre',
      reports: 'Rapports',
      required_data_not_sent: 'Données requises non envoyées',
      resend_email: "Renvoyer l'email",
      residential: 'Résidentiel',
      responded: 'Répondu',
      restricao: 'Avez-vous des restrictions sur votre nom ? (CPS/Serasa)',
      result: 'Résultat',
      results: 'Résultats',
      resume_data: 'Résumé des données',
      revoked: 'Abrogé',
      rg: 'Inscription générale (identité)',
      rgAgency: 'Émetteur RG',
      rgDate: "Date d'émission du RG",
      rgUf: 'État de RG',
      right: 'Droite',
      right_preferential: 'Préférer le côté droit',
      roi: 'Gains quotidiens',
      sale: 'Vente',
      saq_duplicado: 'Retrait en double',
      save: 'Sauver',
      schooling: 'Éducation',
      search: 'Recherche',
      secretary: 'secrétaire',

      security_password: 'Mot de passe de sécurité',
      see: 'Voir',
      see_advents: 'Voir les annonces',
      see_call: "Afficher l'appel",
      see_details: 'voir les détails',
      see_details_wallet: 'Afficher les détails du portefeuille',
      see_financing: 'Voir le financement',
      see_order: 'Voir le don',
      see_user: 'Voir les donateurs',
      select_vehicle: 'Sélectionnez un véhicule',
      self_register: 'Registre',
      sell_volume: 'Volume des ventes',
      seller: 'Vendeur',
      send_call: 'Envoyer un appel',
      send_to: 'Envoyer à',
      September: 'Septembre',
      settings: 'paramètres',
      share_your_link: 'Partagez votre lien',
      show: "Afficher jusqu'à",
      show_from: 'Affichage à partir de',
      side: 'Côté',
      sign_in_to_start_your_session: 'Connectez-vous pour démarrer votre session',
      sign_up: 'Registre',
      single: 'Célibataire',
      sold: 'Vendu',
      state: 'État',
      status: 'Statut',
      strategies: 'Stratégies',
      street: 'Route',
      subject: 'Sujet',
      submit: 'Envoyer',
      submit_placeholder: 'Veuillez remplir votre email',
      subscribe: 'Inscrivez-vous pour recevoir nos actualités',
      subtitle: 'Légende',
      success_update_user: 'Donateur mis à jour avec succès !',
      success_wallet: 'Portefeuille mis à jour avec succès !',
      suggestions: 'Suggestions',
      superior_complete: 'Diplômé',
      superior_incomplete: 'Incomplet supérieur',
      supervisor: 'Superviseur',
      support: 'Soutien',
      support_materials: 'Matériel de support',
      suspended: 'Suspendu',
      symbol: 'Symbole',
      symbols: 'Symboles',
      system: 'Système',
      target: 'But',
      telegram_chat_id: 'Identifiant de discussion Telegram',
      tempoCarteira: 'Depuis combien de temps êtes-vous inscrit dans votre portefeuille ?',
      terms: "J'accepte les conditions d'utilisation",
      text_hint_binance:
        "Lors de la création de la clé API sur Binance, saisissez l'IP dans les restrictions d'accès :\n172.105.78.139\nPuis sélectionnez les options :\n• ACTIVER LES OPTIONS EUROPÉENNES\n• ACTIVER LE SPOT & MARGIN TRADING",
      this_value_in: 'Cette valeur en',
      ticker_health: 'TickerSanté',
      title: 'Titre',
      to: "Jusqu'à",
      to_pay: 'Faire un don',
      to_receive: 'Recevable',
      today_entry: "L'entrée d'aujourd'hui",
      token_not_found_or_already_used: 'Jeton introuvable ou déjà utilisé',
      total: 'Total',
      total_entry: 'Entrée totale',
      traderbot: 'Commerçant de robots',
      transf_credito: 'Transfert de crédit',
      transf_debito: 'Transfert de dette',
      transfer_payment: 'Paiement par virement',
      transfer_payment_return: 'Retour du paiement par virement',
      transfer_receipt: 'Réception en virement',
      transparency: 'Transparence',
      tx_adm_cred: 'Administrer la taxe',
      tx_adm_deb: 'Administrer la taxe',
      tx_retirada: 'frais de retrait',
      tx_retirada_gan_diario: 'Frais de retrait des gains quotidiens',
      type: 'Taper',
      update: 'Mettre à jour',
      update_wallet: 'Mettre à jour le portefeuille',
      upgrade: 'Amélioration',
      used: 'Utilisé',
      user: 'Donneur',
      user_active: 'Donateur actif',
      user_already_exists: 'Le donateur existe déjà !',
      user_and_or_password_wrong: 'Mauvais donateur et/ou mot de passe !',
      user_inactive: 'Donateur inactif',
      user_not_found: 'Donneur introuvable',
      user_not_logged: 'Donateur non connecté',
      user_or_password_wrong: 'Donateur ou mot de passe incorrect',
      users: 'Donateurs',
      users_list: 'Liste des donateurs',
      valid_images: 'Images valides',
      valorRenda2: 'Quelle est la valeur du deuxième revenu',
      value: 'Valeur',
      value_donate_on: 'Valeur du don en',
      value_financed: 'Montant financé',
      value_installment: 'Valeur du versement',
      values: 'Valeurs',
      vehicle: 'Véhicule',
      vehicles: 'Véhicules',
      view_order: "Voir l'ordre",
      vision: 'Vision',
      visitor: 'Visiteur',
      voucher: 'Bon',
      wait_confirm: 'En attente de confirmation',
      waiting: 'En attendant',
      waiting_email: "L'inscription a réussi, il ne vous reste plus qu'à confirmer votre email.",
      register_ok: "L'inscription a été complétée avec succès.",
      waiting_payment: 'En attendant de faire un don',
      wallet: 'Portefeuille',
      wallet_generate: 'Générer un portefeuille',
      wallets: 'Portefeuilles',
      want_donate: 'je veux faire un don',
      want_now_more_about: 'Je veux en savoir plus sur',
      warning: 'ATTENTION',
      we_are: 'Sont',
      webhooks: 'Webhooks',
      welcome: 'Accueillir',
      who_we_are: 'Qui nous sommes',
      who_we_are_subtitle: 'Une brève description du',
      widower: 'Veuf',
      will_expire_in: 'expirera dans',
      with: 'avec',
      withdraw: 'Retrait',
      withdraw_sended_email: 'Retrait demandé, vérifiez votre email et cliquez sur le lien pour autoriser !',
      withdrawal: 'Retraits',
      withdrawals: 'Retraits',
      year: 'Année',
      years: 'Années',
      yes: 'Oui',
      you_have: 'Tu as',
      your_country: 'Votre pays',
      your_dashboard: 'Ceci est votre panneau de contrôle.',
      your_link: 'Votre lien',
      zipcode: 'Code postal',
      withdrawal_sended: 'Retrait envoyé',
      withdrawal_pending: 'Retrait déjà demandé ! \nVeuillez attendre le paiement pour effectuer un autre retrait.',
      limite_80_top:
        'Vous avez atteint {{perc}} votre limite.\n Anticipez votre renouvellement et évitez de suspendre vos revenus.',
      game: 'Jeu',
      games: 'Jeux',
      winning_numbers: 'Numéros tirés',
      your_numbers: 'Vos numéros',
      add_numbers: 'Ajouter des numéros',
      next_draw: 'Prochain tirage',
      can_bet_until: "Vous pouvez parier jusqu'à",
      your_bets: 'Vos paris',
      add_bet: 'Ajouter un pari',
      bet: 'Pari',
      bets: 'Paris',
      numbers: 'Nombres',
      special_numbers: 'Numéros spéciaux',
      resume: 'Résumé',
      choosed_numbers: 'Numéros choisis',
      choosed_special_numbers: 'Numéros spéciaux choisis',
      bets_finished: 'Paris réglés',
      prize_draw: 'Tirage au sort',
      balance_network: 'Équilibre communautaire',
      telegram: 'Télégramme',
      logs: 'Journaux',
      release_withdrawals: 'Libérer les retraits',
      bot_active: 'Libérer le robot',
      available: 'Disponible',
      sunday: 'Dimanche',
      monday: 'Deuxième',
      tuesday: 'Troisième',
      wednesday: 'Quatrième',
      thursday: 'Cinquième',
      friday: 'Vendredi',
      saturday: 'Samedi',
      title_binary: 'Définir des pourcentages binaires pour chaque jour',
      ok_perc_change: 'Les pourcentages ont été modifiés avec succès',
      cred_deb_title: 'Solde créditeur ou débiteur',
      send: 'Envoyer',
      voucher_pendent: 'Bon en attente',
      leader_pendent: 'Leader en attente',
      voucher_approved: 'Bon approuvé',
      leader_approved: 'Leader approuvé',
      no_binary_at_today: "Il n'y a pas de binaire enregistré ce jour-là",
      generate_file: 'Générer un fichier',
      withdrawals_file_created: 'Dossier de retrait créé',
      withdrawals_sended: 'Demandes de retrait envoyées',
      see_generated_files: 'Afficher les fichiers générés',
      files: 'Des dossiers',
      file: 'Déposer',
      verify: 'Vérifier',
      rescue: 'Sauvetage',
      awarded: 'Récompensé',
      rescued: 'Sauvé',
      repeat: 'Répéter',
      draw_date: 'Date du tirage',
      bet_date: 'Date du pari',
      drawn_numbers: 'Numéros tirés',
      awardeds_numbers: 'Numéros de prix',
      no_award: 'Aucun prix',
      you_hit_number: 'Vous avez bien obtenu le numéro {{qty}}',
      you_hit_numbers: 'Vous avez obtenu {{qty}} chiffres exacts',
      lottery: 'Loterie',
      lotteries: 'Loteries',
      balls: 'des balles',
      special_balls: 'Offres spéciales',
      levy: 'collection',
      major_awarded: 'plus de balles',
      hits: 'les coups',
      cred_mandala: 'Crédit Star Matrix',
      renew_mandala: 'Renouvellement de la matrice stellaire',
      mandala: "Star Matrix",
      mandala2: "Star Matrix 2.0",
      mandalas: "Star Matrix",
      mandala_in: 'Participez à la Star Matrix',
      rifas: "Star Matrix",
      rifa: "Star Matrix",
      lascado: "C'est ébréché",
      estribado: "C'est agité",
      none: 'Aucun',
      directs: 'Amis',
      matuto: 'Matuto',
      matutos: 'Matutos',
      cupom: 'Numéro de coupon dans Star Matrix',
      your_position: 'Votre position dans la matrice stellaire',
      closed: 'Fermé',
      gang: "Star Matrix",
      gangs: "Star Matrix",
      influencer_required: 'Vous devez choisir un influenceur',
      pendent_order: 'Vous avez des dons en attente',
      influencer: 'Influenceur',
      change_influencer: "Échanger d'influenceur",
      no_influencer: 'Aucun influenceur',
      msg_no_influencer: "Vous n'avez pas encore choisi d'influenceurs",
      you_are_influencer: 'Êtes-vous un influenceur ? Cliquez ici et inscrivez-vous',
      register_influencer: 'Inscription des influenceurs',
      add_your_photo: 'Ajoutez votre photo',

      hello: 'Bonjour',
      msg_add_order:
        "Merci de vous être inscrit sur {{name}}.\nNous sommes heureux de vous compter parmi notre communauté.\n\nPour regarder le flux et profiter de tous les avantages, vous devez payer l'ADHÉSION.\nL'ADHÉSION est unique. frais de temps qui vous donnent accès à notre contenu exclusif, à notre support technique, à nos mises à jour et bien plus encore.\n\nPour payer votre abonnement, payez simplement via PIX en utilisant le QR Code ci-dessousou en copiant l'adresse juste en dessous du QR Code et en la collant dans l'application de votre banque.\n\nLa confirmation est automatique et immédiate (généralement dans les 10 secondes).\n\nEt c'est tout ! Après cela, vous pourrez profiter de {{name}}.\n\nSi vous avez des questions ou des problèmes, veuillez nous contacter par e-mail à {{email}}.\n\nCordialement",
      msg_mandala_order:
        "Vous n'êtes qu'à un pas de participer à notre entraide, la Star Matrix.\n\nLa Star Matrix est un moyen amusant et sûr de gagner beaucoup d'argent.\n\nPour participer, il vous suffit de faire un don en accédant à votre courtier et en lisant le QR Code ci-dessous \n\nLa confirmation est automatique mais peut prendre quelques minutes.\n\nAprès cela, vous pourrez profiter de {{name}}.\n\nSi vous avez des questions ou des problèmes, veuillez contacter votre proposant.\n\nCordialement",
      msg_cota_solidaria_order:
        "Avec les Quotas de Solidarité vous contribuez à notre action sociale en aidant les familles dans le besoin et même en compétition pour une voiture.\n\nPour payer et acquérir le Quota de Solidarité, il suffit d'effectuer le paiement via PIX en utilisant le QR Code ci-dessousou en copiant l'adresse ci-dessous du QR Code et en le collant dans votre application bancaire.\n\nLa confirmation est automatique et immédiate (généralement dans les 10 secondes).\n\nEt c'est tout ! Après cela, vous participerez déjà au Quota Solidaire.\n\nSi vous avez des questions ou des problèmes, veuillez nous contacter via un Ticket.\n\nCordialement",
      followers: 'Suiveurs',
      required_fields: 'Champs obligatoires',
      your_influencer: 'Votre influenceur',
      cota_solidaria: 'Quota de solidarité',
      cotas_solidarias: 'Quotas de solidarité',
      add_cota_solidaria: 'Ajouter un quota de solidarité',
      add_cotas_solidarias: 'Ajouter des quotas de solidarité',
      coupon: 'Coupon',
      error_mandala_open: 'Il existe déjà un don Star Matrix ouvert',
      error_cotaSolidaria_open: "Vous disposez déjà d'un don de quota de solidarité ouvert",
      cotaSolidaria_not_created: "Le quota de solidarité n'a pas été créé",
      success_mandala: 'Don Star Matrix créé avec succès',
      success_cotaSolidaria: 'Quota de solidarité créé avec succès',
      cotas_solidaria_desc:
        'Confirmez que vous souhaitez acheter {{qty}} Quotas de Solidarité au prix de {{price}} pour un total de {{total}}',
      cota_solidaria_desc:
        'Confirmez que vous souhaitez acheter {{qty}} Quota de Solidarité au prix de {{price}} pour un total de {{total}}',
      builder: 'Constructeur',
			top: 'Haut',
			day: 'jour',
      days: 'jours',
      hour: 'heure',
      hours: 'heures',
      minute: 'minutes',
      minutes: 'minutes',
      second: 'seconde',
      seconds: 'secondes',
      comming: 'Manquante',
      comming_soon: 'Lancement mondial dans',
			net: 'Reseaux',
			raffels: 'tirages au sort',
			new_raffle: 'Nouveau tirage au sort',
    },
  },
}
export { messages }
