import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu/Menu'
// import Footer from '../../components/Footer/Footer'
import { i18n } from '../../utils/translates/i18n'
import Toast from '../../components/Toast/Toast'
import { getPercentageBinaries, savePercentages } from '../../services/BinaryService'

function Binary() {
  const token = sessionStorage.getItem('token')
  const [notification, setNotification] = useState({ text: '', type: '' })
  const [percentageBinaries, setPercentageBinaries] = useState({})
  const [show, setShow] = useState(false)

  const doGetPercentageBinaries = async token => {
    const success = await getPercentageBinaries(token)
    if (success.status) {
      setPercentageBinaries(success.percentages)
      setShow(true)
    }
  }

  const onInputChange = e => setPercentageBinaries(p => ({ ...p, [e.target.id]: e.target.value }))

  const doSave = async () => {
    setShow(false)
    const success = await savePercentages(token, percentageBinaries)
    if (success.status) {
      doGetPercentageBinaries()
      setNotification({ text: i18n.t('ok_perc_change'), type: 'success' })
    }
  }

  useEffect(() => {
    doGetPercentageBinaries(token)
  }, [token])

  const t = sessionStorage.getItem('t') === 'true'
  return (
    !t && (
      <React.Fragment>
        <Menu />
        <main className="content">
          <>
            {!show ? (
              <div className="alert alert-warning">{i18n.t('waiting')}</div>
            ) : (
              <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <h2 className="h4">{i18n.t('binary')}</h2>
                  </div>
                </div>
                <div className="card card-body border-0 shadow mb-5">
                  <h4>{i18n.t('title_binary')}</h4>
                  <div className="row mt-3">
                    <div className="form-group col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('sunday')}</span>
                        <input
                          type="text"
                          id="d0"
                          value={percentageBinaries.d0}
                          className="form-control"
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('monday')}</span>
                        <input
                          type="text"
                          id="d1"
                          value={percentageBinaries.d1}
                          className="form-control"
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('tuesday')}</span>
                        <input
                          type="text"
                          id="d2"
                          value={percentageBinaries.d2}
                          className="form-control"
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('wednesday')}</span>
                        <input
                          type="text"
                          id="d3"
                          value={percentageBinaries.d3}
                          className="form-control"
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('thursday')}</span>
                        <input
                          type="text"
                          id="d4"
                          value={percentageBinaries.d4}
                          className="form-control"
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('friday')}</span>
                        <input
                          type="text"
                          id="d5"
                          value={percentageBinaries.d5}
                          className="form-control"
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('saturday')}</span>
                        <input
                          type="text"
                          id="d6"
                          value={percentageBinaries.d6}
                          className="form-control"
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    {/* <pre>{JSON.stringify(percentageBinaries, null, 2)}</pre> */}
                  </div>
                  <button className="btn btn-success btn-lg mt-3 mx-auto" onClick={doSave}>
                    <i className="fa fa-check"></i> {i18n.t('save')}
                  </button>
                </div>
              </div>
            )}
          </>
        </main>
        {/* <Footer /> */}
        <Toast type={notification.type} text={notification.text} />
      </React.Fragment>
    )
  )
}

export default Binary
