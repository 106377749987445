const messages = {
  en: {
    translations: {
      a_crase: 'The',
      about: 'About Us',
      above_the_same: 'Exactly the same above',
      action: 'Action',
      actionTemplates: 'Action Models',
      activate_date: 'Activation date',
      active_automations: 'Active Automations',
      active_connections: 'Active Connections',
      active_monitors: 'Active Monitors',
      active_users: 'Active Donors',
      ad: 'Announcement',
      add: 'Accession',
      add_option: 'Add option',
      add_payment: 'Add Payment',
      add_question: 'Add Question',
      add_receipt: 'Add Receipt',
      add_wallet: 'Add Wallet',
      address: 'Address',
      admin: 'Administration',
      advance: 'Advance',
      affected: 'Reached',
      after: 'After',
      air_transport: 'Air Transport',
      ajust_limite: 'Limit Adjustment',
      ajust_saldo: 'Balance Adjustment',
      alert: 'Alert',
      alerts: 'Alerts',
      all: 'All',
      all_departments: 'All Departments',
      allies_of: 'Allies of',
      allRightsReserved: 'All rights reserved.',
      almost_there: 'Almost there',
      already_offset: 'I already compensated',
      already_registered: 'Already Registered? Click Here and Enter',
      amount: 'Value',
      amount_above_balance: 'Insufficient funds',
      and: 'It is',
      and_more: 'and more',
      answer: 'Response',
      ao: 'to the',
      approve: 'To approve',
      April: 'April',
      asc: 'Ascending',
      at: 'in',
      August: 'August',
      automations: 'Automations',
      awaiting_email_confirmation: 'Waiting for email confirmation!',
      b_order: 'Order',
      b_orders: 'Orders',
      back: 'To go back',
      bairroInteresse: 'Neighborhood',
      balance: 'Balance',
      balance_available: 'Donation Balance',
      banned: 'Banned',
      before_contact: 'Before contacting us',
      binary: 'Binary',
      birth: 'Birth',
      blind_man: 'Reversed',
      body_monthly:
        'Your Monthly Fee has expired and your BOT TRADER has been inactivated, please pay the Payment Order and enjoy your benefits again',
      bonus_saldo_trader: 'Trader Balance Bonus',
      book_health: 'BookHealth',
      but_possible_register: 'It is still possible to register, but you will not be linked to any referring friend.',
      buy_volume: 'Purchase Volume',
      buyer: 'Buyer',
      by: 'per',
      bye: 'See you later',
      call: 'Called',
      call_to: 'Call to',
      calls: 'Callings',
      cancel: 'Cancel',
      canceled: 'Canceled',
      career_plan: 'Career path',
      casa_verde_amarela: 'Yellow Green House',
      cash_payment: 'Cash payment',
      cash_payment_return: 'Return of cash payment',
      cash_receipt: 'Cash receipt',
      change_indicant: 'Change Indicator',
      change_photo: 'Change Photo',
      change_plan: 'Change plan',
      check_payment: 'Payment in check',
      check_payment_return: 'Return of payment by check',
      check_receipt: 'Receipt by check',
      choose: 'To choose',
      cidadeInteresse: 'City',
      city: 'City',
      claims: 'Claims',
      clean: 'To clean',
      click_and_know: 'Click and Discover',
      click_to_copy_the_wallet: 'Click to Copy Wallet',
      client: 'Client',
      clients: 'Customers',
      close: 'Closure',
      cnh: 'CNH (License)',
      cnhDate: 'CNH Issuance Date',
      cnpj: 'CNPJ',
      cnpj_invalid: 'Invalid CNPJ',
      coin: 'Coin',
      color: 'Color',
      commercial: 'Commercial',
      company: 'Company Name',
      complement: 'Complement',
      comporRenda: 'Are you going to make money with someone?',
      compRenda: 'Type of Income Proof',
      confirm_approve: 'Confirm approval',
      confirm_delete: 'Confirm deletion',
      confirm_new_password: 'Confirm New Password',
      confirm_new_password_security: 'Confirm new security password',
      confirm_password: 'Confirm the Password',
      confirm_password_security: 'Confirm security password',
      confirm_your_new_password: 'Enter your new password again',
      confirmed: 'Confirmed',
      confirmed_email: 'Confirmed email',
      congratulations: 'Congratulations',
      contact: 'Contact',
      contract: 'Contract',
      copied: 'Copied',
      copy: 'Copy',
      copy_success: 'Copied successfully!',
      correspondences: 'Correspondence',
      countries: 'Countries',
      country: 'Country',
      cpf: 'CPF',
      cpf_invalid: 'Invalid CPF',
      create_order_success: 'Donation Created Successfully!',
      created: 'Registered in',
      cred: 'Credit',
      cred_binario: 'Binary Credit',
      cred_deb: 'Credits/Debits',
      cred_game_donate: 'Game Donate Credit',
      cred_ind_direta: 'Step Credit',
      cred_ind_indireta: 'Step Credit',
      cred_pagar_com_saldo: 'Credit Donate with Balance',
      cred_pontos: 'Credit Points',
      cred_recb_trader: 'Credit Receive Trader',
      cred_renovacao: 'Renewal Credit',
      cred_saldo_disp: 'Donation Balance Credit',
      cred_upgrade: 'UpGrade Credit',
      credit_card_payment: 'Payment by credit card',
      credit_card_payment_return: 'Return of payment by credit card',
      credit_card_receipt: 'Credit card receipt',
      current: 'Current',
      current_password: 'Current password',
      current_wallet: 'Current Portfolio',
      dashboard: 'Control Panel',
      data_added: 'Added data',
      data_saved: 'Saved Data',
      datas: 'Data',
      date: 'Date',
      date_first_installment: 'Date of first payment',
      ddi: 'DDI',
      deb_de_trader: 'Trader Debt',
      deb_game_donate: 'Game Donate Debit',
      deb_saldo: 'Debit Balance',
      deb_saldo_disp: 'Donation Balance Debit',
      deb_saldo_pendent: 'Outstanding Balance Debit',
      deb_val_indev: 'Debit Undue Amount',
      debit_card_payment: 'Payment by debit card',
      debit_card_payment_return: 'Return of payment by debit card',
      debit_card_receipt: 'Receipt by debit card',
      debt: 'Debt',
      debt_td: 'Debit',
      December: 'December',
      decImpRenda: 'Declare income tax',
      deleted_document: 'Document was deleted from your record',
      department: 'Department',
      dependents: 'Dependents',
      desc: 'Downward',
      description: 'Description',
      details: 'Details',
      dev_econom: 'Economic Development',
      developer: 'Developer',
      directly_indicated: 'Directly indicated',
      directly_indicateds: 'Referred directly',
      district: 'Neighborhood / district',
      divorced: 'Divorced',
      doa_men_receb: 'Monthly Platform Donation Received',
      doacao_td: 'Donation',
      doctorate_complete: 'Doctorate - Complete',
      doctorate_incomplete: 'Doctorate - Incomplete',
      document: 'Document',
      document_already_registered: 'Document already registered',
      documents: 'Documents',
      donate: 'Donate',
      donor: 'Donor',
      dont_know_zip: "Don't know the zip code? Click here",
      dou_fe: 'I confirm that the information above is correct.',
      downloads: 'Download Files',
      drop_images: 'Click or Drag the images here',
      due_date: 'Maturity',
      edit: 'To edit',
      edit_option: 'Edit option',
      edit_question: 'Edit question',
      email: 'Email',
      email_already_registered: 'Email already registered',
      email_and_or_password_wrong: 'Wrong email and/or password!',
      email_for_login: 'This email will be your login',
      email_or_password_wrong: 'Incorrect email or password',
      employee: 'Employee',
      employees: 'Employees',
      empty: 'Empty',
      enter_your_new_password: 'Enter your new password',
      entrada: 'Does it have an entry value? If YES, what value',
      entries_until_today: 'Entries to date',
      error_confirm_password: 'Confirmation and New Password must be the same',
      error_email: 'Invalid Email Format',
      error_password: 'Incorrect password',
      error_phone: 'Invalid Phone Number',
      estadoInteresse: 'state',
      estimate: 'Estimated',
      exchange: 'Replacement',
      expired: 'Expired',
      extorno_td: 'Reversal',
      extra: 'Extra',
      extract: 'Movement',
      extracts: 'Movements',
      fantasy: 'Fantasy',
      fatherName: "Father's name",
      February: 'February',
      fee: 'Withdrawal Fee',
      feedback: 'Return',
      feminine: 'Feminine',
      fgts: 'Value of your FGTS',
      field_required: 'Mandatory completion',
      final: 'Final',
      finance: 'Financial',
      financial: 'Financial',
      financial_payment: 'Financial payment',
      financial_payment_return: 'Return of financial payment',
      financial_receipt: 'Receipt of finance',
      financing: 'Financing',
      financings: 'Financing',
      first_10_pages: 'First 10 pages',
      fisica: 'Physical person',
      for_year: 'Per annum',
      forget_password: 'I forgot the password',
      forgot_password: 'Forgot password',
      form: 'Form',
      form_of_payment: 'Form of payment',
      found: 'Found',
      founds: 'Found',
      free: 'Free',
      friend_indicated: 'Recommended by my friends',
      friend_indicateds: 'Recommended by my friends',
      fuel: 'Fuel',
      full_name: 'Full name',
      funcPublico: 'Is a Public Servant',
      fundamental_complete: 'Fundamental - Complete',
      fundamental_incomplete: 'Fundamental - Incomplete',
      ganh_diario: 'Daily Earnings',
      gender: 'Gender',

      grand_total: 'Grand total',
      group: 'Group',
      has_withdraw_today: 'A withdrawal has already been requested today',
      high: 'High',
      home: 'Start',
      how_create_binance_apikey: 'How to create API Key on Binance',
      how_it_works: 'How it works',
      how_to_call: 'What do you want to be called?',
      id_td: 'Step Credit',
      images: 'Images',
      in_construction: 'Under construction',
      in_order: 'In order',
      in_stock: 'In stock',
      indicant: 'Indicator',
      indicated_by: 'You are being referred by',
      indicated_not_found: 'The indicated Referrer was not found...',
      indicateds: 'Friends',
      indicated: 'Friend',
      indirects: 'Friends',
      info: 'Newsletters',
      info_contact: 'Contact information',
      initial: 'Home',
      insert_new: 'Enter New',
      installments: 'Installments',
      intelligence: 'Intelligence',
      interesse: 'What is the region of interest',
      invalid_contact: 'Invalid Contact Format',
      invalid_images: 'Invalid images',
      invalid_token: 'Invalid Token',
      invite: 'To invite',
      invite_friends: 'Invite your friends',
      January: 'January',
      July: 'July',
      June: 'June',
      juridica: 'Legal person',
      know_exact_result: 'Do you know the exact value?',
      lead: 'Together we will be building a new future.',
      learn_more: 'Know more',
      left: 'Left',
      left_preferential: 'Preferring the left side',
      legalNature: 'Legal Nature',
      level: 'Level',
      limite_80: 'You have reached {{perc}} of your limit.\n Make an Improvement and avoid suspension of your income.',
      limite_excedido: 'Debit limit exceeded',
      linear: 'Linear',
      link_email: 'Click on the Link Sent to Registered Email!',
      link_invite_copy: 'Share link copied successfully',
      liquid: 'Liquid',
      list: 'List',
      loading: 'Loading',
      location: 'Location',
      locked: 'Blocked',
      login: 'To enter',
      logout: 'To go out',
      low: 'Low',
      male: 'Masculine',
      manage_users: 'Manage Donors',
      manager: 'Manager',
      manufacturer: 'Manufacturer',
      March: 'March',
      maritalStatus: 'Marital status',
      market: 'Market',
      married: 'Married',
      master_complete: "Master's Degree - Complete",
      master_incomplete: "Master's Degree - Incomplete",
      max_payment: 'Maximum Payout',
      May: 'May',
      message: 'Message',
      messageNotSent: 'It was not possible to send your message',
      messageSent: 'Your message has been sent successfully',
      middle_complete: 'Medium - Full',
      middle_incomplete: 'Medium - Incomplete',
      min_amount_error: 'The value must be at least:',
      min_withdraw: 'Minimum Withdrawal Amount',
      missing: 'Missing',
      mission: 'Mission',
      mission_vision_values: 'Mission, vision and values',
      model: 'Model',
      monitors: 'Monitors',
      monthly: 'Monthly payment',
      motherName: "Mother's Name",
      movements: 'Movements',
      msg_renovacao: 'You have reached your earning limit, renew your package.',
      my_account: 'My account',
      my_wallet: 'My wallet',
      mystock: 'My Stock',
      name: 'Name',
      nascMaisVelhoRenda2: "What is the oldest's date of birth?",
      nationality: 'Nationality (Country of birth)',
      naturalness: 'Birthplace (City of birth)',
      nature: 'Nature',
      network: 'Community',
      new: 'New',
      new_call: 'New Calling',
      new_financing: 'New Financing',
      new_order: 'New order',
      new_password: 'New Password',
      new_password_security: 'New security password',
      new_seller: 'New Seller',
      new_wallet: 'New Wallet',
      news: 'News',
      next: 'Next',
      nick: 'Donor (login)',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS already registered',
      no: 'No',
      no_approve: 'Not yet approved',
      no_delete: 'Do not delete',
      no_literate: 'Not Literate',
      no_repeat: 'Data cannot be repeated',
      no_results_found: 'no results found',
      no_services_or_products_added: 'No services or products added',
      no_settings: 'Go to Settings and fill in your details.',
      none_friends_indicated: "I haven't referred any friends yet",
      not_authorized: 'Not authorized',
      not_found: 'Not found',
      not_login: 'Unable to log in, please check your details and try again!',
      not_orders: 'No Payment Orders',
      not_permission: 'You do not have permission to access this page',
      not_registered: 'Not Registered Yet? Click here and Register',
      not_results: 'No Records Found',
      not_self_register: 'Unable to register',
      not_today_entry: "We still haven't had tickets today",
      notfound_ad: 'No ads found',
      November: 'November',
      number: 'Number',
      obs: 'Comments',
      obs_pay:
        'If your broker debits fees from the amount sent, you must make the correction manually by adding the amounts of these fees.',
      October: 'October',
      of: 'in',
      off: 'Wiped out',
      on_total: 'Of the total',
      open: 'Open',
      opening: 'Opening',
      operation: 'Performance',
      ordenation: 'Ordering',
      order: 'Donation',
      order_book: 'Order book',
      order_not_alowed_to_pay: 'Unauthorized donation to Donate with balance',
      order_not_found: 'Donation not found',
      order_payed: 'Donation #{{id}} paid successfully',
      orders: 'Donations',
      other: 'Other',
      other_services_or_products: 'Other Services or Products',
      paid: 'Paid out',
      paid_date: 'Pay day',
      partial: 'Partially Paid',
      passport: 'Passport',
      password: 'Password',
      password_security: 'Security password',
      pay: 'Donate',
      pay_exact: 'Pay the amount in {{coin}}',
      pay_generate: 'Generate payment',
      pay_selected: 'Donate Selected',
      pay_value_in: 'Pay the amount in',
      pay_with_balance: 'Donate with Balance',
      payable: 'Donate',
      payer: 'Payer',
      payment: 'Payment',
      ped_retirada: 'Withdrawal Donation',
      ped_retirada_gan_diario: 'Daily Earnings Withdrawal Donation',
      pendent: 'Pending',
      people: 'People',
      person: 'Person',
      person_found_in_other_prefecture: 'Person already registered in another municipality',
      personal: 'Guys',
      personal_addresses: 'Adresses',
      personal_contacts: 'Contacts',
      personal_data: 'Personal data',
      personal_infos: 'Information',
      personal_pcva: 'Green and Yellow House Program',
      phone: 'Phone',
      photo: 'Photograph',
      pix: 'PIX',
      pix_payment: 'Payment in PIX',
      pix_payment_return: 'Return of payment in PIX',
      pix_receipt: 'Receiving in PIX',
      pl_carreira: 'Career path',
      plan: 'Flat',
      plates: 'Plates',
      please_wait: 'Please wait',
      plus: 'Plus',
      point: 'Point',
      points: 'Points',
      portfolio: 'Portfolio',
      pos_complete: 'Lato sensu - Complete',
      pos_incomplete: 'Lato sensu - Incomplete',
      prefecture: 'City hall',
      prefecture_addresses: 'Adresses',
      prefecture_contacts: 'Contacts',
      prefecture_data: 'City Hall Data',
      prefecture_infos: 'Information',
      prefectures: 'City Halls',
      prev: 'Previous',
      price: 'Price',
      print: 'Print out',
      privacy_policies: 'Privacy Policies',
      private_works: 'Private Works',
      profession: 'Profession',
      profile: 'Profile',
      program: 'Program',
      programs: 'Software',
      project: 'Project',
      projects: 'Projects',
      public_works: 'Public works',
      purchase: 'Purchase',
      qualify_binary: 'Binary Qualification',
      qualquer: 'Any',
      quantity: 'Amount',
      quotation: 'Price',
      read: 'To read',
      receivable: 'To receive',
      recipient: 'Receiver',
      recommendations: 'Recommendations',
      redefine_password: 'Redefine password',
      register: 'Record',
      register_people: 'Registration of Persons',
      register_wallet: 'Register a Wallet to make Withdrawals',
      registers: 'Records',
      registers_users: 'Donor Registrations',
      reject: 'Reject',
      remaining: 'Remaining',
      remove: 'To remove',
      rendaIndividual: 'Individual Gross Income',
      renew: 'Renew',
      reply: 'To respond',
      reports: 'Reports',
      required_data_not_sent: 'Required data not sent',
      resend_email: 'Resend the email',
      residential: 'Residential',
      responded: 'Answered',
      restricao: 'Do you have restrictions on your name? (SPC/Serasa)',
      result: 'Result',
      results: 'Results',
      resume_data: 'Data summary',
      revoked: 'Repealed',
      rg: 'General Registration (Identity)',
      rgAgency: 'RG issuer',
      rgDate: 'RG Issuance Date',
      rgUf: 'State of RG',
      right: 'Right',
      right_preferential: 'Preferring the right side',
      roi: 'Daily Earnings',
      sale: 'Sale',
      saq_duplicado: 'Duplicate Withdrawal',
      save: 'To save',
      schooling: 'Education',
      search: 'Search',
      secretary: 'Secretary',

      security_password: 'Security password',
      see: 'To see',
      see_advents: 'See the ads',
      see_call: 'View call',
      see_details: 'see details',
      see_details_wallet: 'View Wallet Details',
      see_financing: 'View financing',
      see_order: 'View Donation',
      see_user: 'View Donors',
      select_vehicle: 'Select vehicle',
      self_register: 'Register',
      sell_volume: 'Sales Volume',
      seller: 'Seller',
      send_call: 'Send Call',
      send_to: 'Send to',
      September: 'September',
      settings: 'settings',
      share_your_link: 'Share your Link',
      show: 'Show until',
      show_from: 'Displaying from',
      side: 'Side',
      sign_in_to_start_your_session: 'Log in to start your session',
      sign_up: 'Register',
      single: 'Single',
      sold: 'Sold',
      state: 'state',
      status: 'Status',
      strategies: 'Strategies',
      street: 'Road',
      subject: 'Subject',
      submit: 'To send',
      submit_placeholder: 'Please fill in your email',
      subscribe: 'Sign up to receive our news',
      subtitle: 'Caption',
      success_update_user: 'Donor updated successfully!',
      success_wallet: 'Successfully updated portfolio!',
      suggestions: 'Suggestions',
      superior_complete: 'Graduated',
      superior_incomplete: 'Incomplete higher',
      supervisor: 'Supervisor',
      support: 'Support',
      support_materials: 'Support Materials',
      suspended: 'Suspended',
      symbol: 'Symbol',
      symbols: 'Symbols',
      system: 'System',
      target: 'Goal',
      telegram_chat_id: 'Telegram Chat ID',
      tempoCarteira: 'How long have you registered in your portfolio?',
      terms: 'I accept the terms of use',
      text_hint_binance:
        'When creating the API key on Binance, enter the IP in the access restrictions:\n172.105.78.139\nThen select the options:\n• ENABLE EUROPEAN OPTIONS\n• ENABLE SPOT & MARGIN TRADING',
      this_value_in: 'This value in',
      ticker_health: 'TickerHealth',
      title: 'Title',
      to: 'Until',
      to_pay: 'To Donate',
      to_receive: 'Receivable',
      today_entry: "Today's Entry",
      token_not_found_or_already_used: 'Token not found or already used',
      total: 'Total',
      total_entry: 'Total Entry',
      traderbot: 'Bot Trader',
      transf_credito: 'Credit Transfer',
      transf_debito: 'Debt Transfer',
      transfer_payment: 'Payment by transfer',
      transfer_payment_return: 'Return of payment by transfer',
      transfer_receipt: 'Receipt in transfer',
      transparency: 'Transparency',
      tx_adm_cred: 'Administrate tax',
      tx_adm_deb: 'Administrate tax',
      tx_retirada: 'Withdrawal Fee',
      tx_retirada_gan_diario: 'Daily Earnings Withdrawal Fee',
      type: 'Type',
      update: 'To update',
      update_wallet: 'Update Wallet',
      upgrade: 'Improvement',
      used: 'Used',
      user: 'Donor',
      user_active: 'Active donor',
      user_already_exists: 'Donor already exists!',
      user_and_or_password_wrong: 'Wrong donor and/or password!',
      user_inactive: 'Inactive donor',
      user_not_found: 'Donor not found',
      user_not_logged: 'Donor not logged in',
      user_or_password_wrong: 'Incorrect donor or password',
      users: 'Donors',
      users_list: 'Donor List',
      valid_images: 'Valid images',
      valorRenda2: 'What is the value of second income',
      value: 'Value',
      value_donate_on: 'Donation Value in',
      value_financed: 'Amount financed',
      value_installment: 'Installment value',
      values: 'Values',
      vehicle: 'Vehicle',
      vehicles: 'Vehicles',
      view_order: 'View Order',
      vision: 'Vision',
      visitor: 'Visitor',
      voucher: 'Voucher',
      wait_confirm: 'Waiting confirmation',
      waiting: 'Waiting',
      waiting_email: 'Registration was successful, now all you need to do is confirm your email.',
      register_ok: 'Registration was completed successfully.',
      waiting_payment: 'Waiting to make a donation',
      wallet: 'Portfolio',
      wallet_generate: 'Generate Portfolio',
      wallets: 'Wallets',
      want_donate: 'I want to donate',
      want_now_more_about: 'I want to know more about',
      warning: 'ATTENTION',
      we_are: 'Are',
      webhooks: 'Webhooks',
      welcome: 'Welcome',
      who_we_are: 'Who we are',
      who_we_are_subtitle: 'A brief description of the',
      widower: 'Widower',
      will_expire_in: 'will expire in',
      with: 'with',
      withdraw: 'Withdrawal',
      withdraw_sended_email: 'Withdrawal requested, check your email and click the link to authorize!',
      withdrawal: 'Withdrawals',
      withdrawals: 'Withdrawals',
      year: 'Year',
      years: 'Years',
      yes: 'Yes',
      you_have: 'You have',
      your_country: 'Your country',
      your_dashboard: 'This is your Control Panel.',
      your_link: 'Your Link',
      zipcode: 'Zip code',
      withdrawal_sended: 'Withdrawal sent',
      withdrawal_pending: 'Withdrawal already requested! \nPlease wait for payment to make another withdrawal.',
      limite_80_top:
        'You have reached {{perc}} your limit.\n Anticipate your renewal and avoid suspending your income.',
      game: 'Game',
      games: 'Games',
      winning_numbers: 'Drawn Numbers',
      your_numbers: 'Your Numbers',
      add_numbers: 'Add Numbers',
      next_draw: 'Next Draw',
      can_bet_until: 'You can bet up to',
      your_bets: 'Your Bets',
      add_bet: 'Add Bet',
      bet: 'Bet',
      bets: 'Bets',
      numbers: 'Numbers',
      special_numbers: 'Special Numbers',
      resume: 'Summary',
      choosed_numbers: 'Chosen Numbers',
      choosed_special_numbers: 'Special Numbers Chosen',
      bets_finished: 'Settled Bets',
      prize_draw: 'Prize draw',
      balance_network: 'Community Balance',
      telegram: 'Telegram',
      logs: 'Logs',
      release_withdrawals: 'Release Withdrawals',
      bot_active: 'Release Bot',
      available: 'Available',
      sunday: 'Sunday',
      monday: 'Second',
      tuesday: 'Third',
      wednesday: 'Fourth',
      thursday: 'Fifth',
      friday: 'Friday',
      saturday: 'Saturday',
      title_binary: 'Set Binary percentages for each day',
      ok_perc_change: 'Percentages changed successfully',
      cred_deb_title: 'Credit or Debit balance',
      send: 'To send',
      voucher_pendent: 'Voucher Pending',
      leader_pendent: 'Pending Leader',
      voucher_approved: 'Voucher Approved',
      leader_approved: 'Approved Leader',
      no_binary_at_today: 'There is no Binary registered on that day',
      generate_file: 'Generate file',
      withdrawals_file_created: 'Withdrawal File created',
      withdrawals_sended: 'Withdrawal Requests Sent',
      see_generated_files: 'View generated files',
      files: 'Files',
      file: 'File',
      verify: 'To check',
      rescue: 'Rescue',
      awarded: 'Awarded',
      rescued: 'Rescued',
      repeat: 'Repeat',
      draw_date: 'Draw Date',
      bet_date: 'Bet Date',
      drawn_numbers: 'Drawn Numbers',
      awardeds_numbers: 'Prize Numbers',
      no_award: 'No Prize',
      you_hit_number: 'You got {{qty}} number right',
      you_hit_numbers: 'You got {{qty}} numbers right',
      lottery: 'Lottery',
      lotteries: 'Lotteries',
      balls: 'balls',
      special_balls: 'Specials',
      levy: 'collection',
      major_awarded: 'more balls',
      hits: 'hits',
      cred_mandala: 'Star Matrix credit',
      renew_mandala: 'Star Matrix Renewal',
      mandala: 'Star Matrix',
      mandala2: 'Star Matrix 2.0',
      mandalas: 'Star Matrix',
      mandala_in: 'Participate in the Star Matrix',
      rifas: 'Star Matrix',
      rifa: 'Star Matrix',
      lascado: "It's chipped",
      estribado: "It's stirred",
      none: 'None',
      directs: 'Friends',
      matuto: 'Matuto',
      matutos: 'Matutos',
      cupom: 'Coupon Number in Star Matrix',
      your_position: 'Your Position in the Star Matrix',
      closed: 'Closed',
      gang: 'Star Matrix',
      gangs: 'Star Matrix',
      influencer_required: 'You need to choose an Influencer',
      pendent_order: 'You have Pending Donations',
      influencer: 'Influencer',
      change_influencer: 'Swap Influencer',
      no_influencer: 'No Influencer',
      msg_no_influencer: "You haven't chosen any Influencers yet",
      you_are_influencer: 'Are you an Influencer? Click here and register',
      register_influencer: 'Influencer Registration',
      add_your_photo: 'Add your photo',

      hello: 'Hello',
      msg_add_order:
        "Thank you for registering with {{name}}.\nWe are happy to have you as part of our community.\n\nTo watch the stream and enjoy all the benefits, you need to pay the MEMBERSHIP.\nMEMBERSHIP is a one-time fee which gives you access to our exclusive content, technical support, updates and much more.\n\nTo pay for your membership, just pay via PIX using the QR Code below\nor by copying the address just below the QR Code and pasting it into the your bank app.\n\nConfirmation is automatic and immediate (usually within 10 seconds).\n\nAnd that's it! After that you will be able to enjoy {{name}}.\n\nIf you have any questions or problems, please contact us by email at {{email}}.\n\nBest regards",
      msg_mandala_order:
        'You are one step away from participating in our mutual aid, the Star Matrix.\n\nThe Star Matrix is a fun and safe way to earn good money.\n\nTo participate, you just need to make the Donation by accessing your broker and by reading the QR Code below \n\nConfirmation is automatic but may take a few minutes.\n\nAfter that you will be able to enjoy {{name}}.\n\nIf you have any questions or problems, please contact your nominator.\n\nSincerely',
      msg_cota_solidaria_order:
        "With Solidarity Quotas you contribute to our social work by helping families in need and even compete for a car.\n\nTo pay and acquire the Solidarity Quota, just make the payment via PIX using the QR Code below\nor by copying the address below of the QR Code and pasting it into your bank app.\n\nConfirmation is automatic and immediate (generally within 10 seconds).\n\nAnd that's it! After that you will already be participating in the Solidarity Quota.\n\nIf you have any questions or problems, please contact us via a Ticket.\n\nSincerely",
      followers: 'Followers',
      required_fields: 'Required fields',
      your_influencer: 'Your Influencer',
      cota_solidaria: 'Solidarity Quota',
      cotas_solidarias: 'Solidarity Quotas',
      add_cota_solidaria: 'Add Solidarity Quota',
      add_cotas_solidarias: 'Add Solidarity Quotas',
      coupon: 'Coupon',
      error_mandala_open: 'There is already an open Star Matrix Donation',
      error_cotaSolidaria_open: 'You already have an open Solidarity Quota Donation',
      cotaSolidaria_not_created: 'Solidarity Quota was not created',
      success_mandala: 'Successfully created Star Matrix donation',
      success_cotaSolidaria: 'Solidarity Quota successfully created',
      cotas_solidaria_desc:
        'Confirm that you wish to purchase {{qty}} Solidarity Quotas at the price of {{price}} totaling {{total}}',
      cota_solidaria_desc:
        'Confirm that you wish to purchase {{qty}} Solidarity Quota at the price of {{price}} totaling {{total}}',
      builder: 'Constructor',
			top: 'Top',
			day: 'day',
      days: 'days',
      hour: 'hour',
      hours: 'hours',
      minute: 'minutes',
      minutes: 'minutes',
      second: 'second',
      seconds: 'seconds',
      comming: 'Missing',
      comming_soon: 'Worldwide launch in',
			net: 'Network',
			raffels: 'raffles',
			new_raffle: 'New raffle',
    },
  },
}
export { messages }
