import axios from './BaseService'

const API_URL = `${process.env.REACT_APP_API_URL}/influencers`

export async function getInfluencers(token, search, status, limit, page) {
  const endPoint = `${API_URL}/list${page ? `?page=${page}` : ''}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { status, limit, search }, { headers })
  return response.data
}

export async function saveInfluencer(token, id, newInfluencer) {
  let response
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  if (id) response = await axios.patch(`${API_URL}/${id}`, newInfluencer, { headers })
  else response = await axios.post(`${API_URL}/add`, newInfluencer, { headers })
  return response.data
}

export const editImg = async (token, id, data) => {
  const endPoint = `${API_URL}/${id}/photo`
  const headers = { Authorization: `${token}` }
  const response = await axios.post(endPoint, data, { headers })
  return response.data
}
